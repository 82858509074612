import AlmaTunnusAnalytics from '@almamedia/tunnus-analytics'
import {
	ErrorObject,
	SuccessObject,
	UserObject,
} from '@almamedia/alma-tunnus-component'
import { NavigateFunction } from 'react-router-dom'
import { createSession } from '../api/login'
import almaLoginUtils from './loginUtils'
import config from 'config'
import sessionStorageUtils from './sessionStorageUtils'

const { getTunnusReturnUrl, removeTunnusReturnUrl } = sessionStorageUtils

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noop: Function = () => {} // nosonar

const openAlmaLogin = noop
const logout = noop
const isUserLoggedIn = false
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onLogin: ((...args: any[]) => any)[] = []
const isModalOpen = noop
const checkSession = noop
const tunnusAnalytics = new AlmaTunnusAnalytics({
	clientId: config.almaLogin.clientID,
})

export const getLocalRelativeUrl = (url: string) =>
	url.replace(/^(?:\/\/|[^/]+|\/oma-asiointi)*/, '')

const getRelativeUrl = (
	localReturnUrl?: string,
	isAuthcallbackPath?: boolean
) => {
	if (localReturnUrl) return getLocalRelativeUrl(localReturnUrl)
	if (isAuthcallbackPath) return '/'
	return getLocalRelativeUrl(location.pathname)
}

const isGroupUser = (user?: UserObject) => {
	return user?.['https://almamedia.net/user_type'] === 'group'
}

export const doRedirect = (navigate: NavigateFunction, url?: string) => {
	const isAuthcallbackPath = location.pathname === 'authcallback'
	const localReturnUrl = getTunnusReturnUrl() || url
	const relativeUrl = getRelativeUrl(localReturnUrl, isAuthcallbackPath)
	navigate(relativeUrl)
}

export const loginCallback =
	(navigate: NavigateFunction) =>
	(error: ErrorObject | null, success: SuccessObject | null) => {
		if (error !== null) {
			console.log(`callback error:${JSON.stringify(error)}`)
		}
		if (success) {
			almaLoginUtils.tunnusAnalytics.sendUserEvent({
				user: success.user,
			})
			createSession(success.accessToken, success.user.id)
				.then(async () => {
					try {
						const { almaIdHash } = window.ALMA.tunnus.getUser()
						window.ALMA.gravitoV1.gtoClient.appendProfile({
							e: {
								matchOnId: almaIdHash,
							},
						})
					} catch (e) {
						console.log(e)
					}
					/**
					 * If group user, then we redirect to front page, so that the user can be redirected to the Group user error page
					 */
					if (isGroupUser(success.user)) {
						removeTunnusReturnUrl()
					}

					for (const fn of almaLoginUtils.onLogin) {
						await fn(success)
					}
					doRedirect(navigate, success.originUrl)
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}

export default {
	openAlmaLogin,
	logout,
	isUserLoggedIn,
	onLogin,
	isModalOpen,
	checkSession,
	tunnusAnalytics,
}
