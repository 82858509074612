import { useState } from 'react'
import {
	useMotionValue,
	useMotionValueEvent,
	useScroll,
	useTransform,
} from 'framer-motion'

// Animation that hides element when scrolling down and displays it when scrolling up.
export const usePeekabooAnimation = (
	scrollableElementHeight: number,
	callback?: (latest: number) => void
) => {
	const [scrollDirection, setScrollDirection] = useState<
		'up' | 'down' | undefined
	>(undefined)
	const scrollChangePosition = useMotionValue(0)
	const animationChangePosition = useMotionValue(0)
	const { scrollY } = useScroll()

	// This maps pages absolute scrolling position from the point scrolling direction changed
	// to values that hide and display element when scrolling.
	// e.g.
	/*
        scrollChangePosition = 500
        scrollableElementHeight = 75.5
        animationChangePosition = -25
        [424.5, 500, 575.5] => [0, -25, -75.5]
    */
	const scrollAnimation = useTransform(
		scrollY,
		[
			Math.max(scrollChangePosition.get() - scrollableElementHeight, 0),
			scrollChangePosition.get(),
			scrollChangePosition.get() + scrollableElementHeight,
		],
		[0, animationChangePosition.get(), -scrollableElementHeight]
	)

	// Scrolling value change listener. When scrolling direction changes this updates scroll direction,
	// what point of animation the animated element was and the page absolute scroll value.
	useMotionValueEvent(scrollY, 'change', (latest) => {
		const scrollingValue = Math.max(latest, 0)
		if (
			scrollingValue !== 0 &&
			scrollingValue > scrollY.getPrevious() &&
			scrollDirection !== 'down'
		) {
			setScrollDirection('down')
			animationChangePosition.set(scrollAnimation.get())
			scrollChangePosition.set(scrollingValue)
			callback && callback(scrollingValue)
		} else if (
			scrollingValue !== 0 &&
			scrollingValue < scrollY.getPrevious() &&
			scrollDirection !== 'up'
		) {
			setScrollDirection('up')
			animationChangePosition.set(scrollAnimation.get())
			scrollChangePosition.set(scrollingValue)
		}
	})

	return scrollAnimation
}
