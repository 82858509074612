import { Invoice, Subscription } from '../../types/subscription'
import useCustomerSubscription from './useCustomerSubscription'

const getInvoices = (status: 'Open' | 'Paid', invoices?: Invoice[]) =>
	invoices
		? invoices
				.filter((p) => p.invoiceStatus === status)
				.sort(
					(a, b) =>
						new Date(b.billingDate).getTime() -
						new Date(a.billingDate).getTime()
				)
		: []

const getFirstPaidPaymentInInvoiceFormat = (
	id?: string,
	subscriptionData?: Subscription[]
) => {
	const firstPaidPayment = subscriptionData
		?.find((d) => d.subscription.id === id)
		?.payments?.find((p) => p.paymentStatus === 'Paid')
	return firstPaidPayment
		? [
				{
					id: firstPaidPayment?.id,
					invoiceNumber: firstPaidPayment?.invoiceId,
					billingDate: firstPaidPayment?.startDate,
					items: [
						{
							priceIncludingVat: firstPaidPayment?.amount,
						},
					],
				},
		  ]
		: []
}

export default (id?: string) => {
	const { data, isLoading, error } = useCustomerSubscription()
	const invoices = data?.find((d) => d.subscription.id === id)?.invoices
	const openInvoices = getInvoices('Open', invoices)
	const paidInvoices =
		invoices && invoices.length > 0
			? getInvoices('Paid', invoices)
			: getFirstPaidPaymentInInvoiceFormat(id, data)

	return {
		data: {
			open: openInvoices,
			paid: paidInvoices,
			totalInvoicesAmount: openInvoices.length + paidInvoices.length,
		},
		isLoading,
		error,
	}
}
