import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
	core,
	SecondaryButton,
} from '@almamedia/talent-media-component-library'
import useReconsiderPrice from '../../api/subscriptions/useReconsiderPrice'
import { useParams } from 'react-router-dom'
import {
	formatDate,
	formatPrice,
	getTranslatedSubscriptionType,
} from '../../utils/subscriptionUtils'
import useSubscription from '../../api/subscriptions/useSubscription'
import useReconsiderOrder from '../../api/subscriptions/useReconsiderOrder'
import CancellationCard from './CancellationCard'
import { ColumnData } from '../common/DataColumns'
import copyTexts from '../../assets/copyTexts'
import GALinkEvent from '../common/GALinkEvent'
import { StyledLink } from '../common'

const StyledButton = styled(SecondaryButton)`
	margin-top: ${core.spacing['x-large']};

	@media ${core.mediaquery.max['x-small']} {
		margin-top: ${core.spacing.large};
	}
`

const CancellationOffer = () => {
	const { id } = useParams()
	const { data } = useReconsiderPrice(id)
	const { data: subscriptionData } = useSubscription(id)
	const { trigger } = useReconsiderOrder(id)

	const dataColumns: (ColumnData | null)[] = useMemo(
		() => [
			{
				label: copyTexts.cancelFlow.offer.Tilaustyyppi,
				value: getTranslatedSubscriptionType(data?.type),
			},
			{
				label: copyTexts.cancelFlow.offer.Hinta,
				value: `${data?.price && formatPrice(data.price)}`,
			},
			{
				label: copyTexts.cancelFlow.offer['Tilaus alkaa'],
				value: formatDate(
					subscriptionData?.subscription.billingInformation.lastBilledDate,
					1
				),
			},
			{
				label: copyTexts.cancelFlow.offer['Tilauksen tiedot'],
				value: data?.priceInfo?.shortText,
			},
		],
		[data, subscriptionData]
	)

	return (
		<CancellationCard
			ribbonText={copyTexts.cancelFlow.offer['Etu sinulle']}
			titleText={data?.priceInfo.detail1}
			bodyText={data?.priceInfo.detail2}
			dataColumns={dataColumns}
			button={
				<GALinkEvent>
					<StyledLink
						to={`/tilaukset/${id}/peruutus-vahvistus?reconsider_accepted=true`}
						state={{ prevPath: location.pathname }}
						onClick={() => trigger()}
					>
						<StyledButton forwardedAs='div'>
							{copyTexts.cancelFlow.offer['Hyväksy tarjous']}
						</StyledButton>
					</StyledLink>
				</GALinkEvent>
			}
		/>
	)
}

export default CancellationOffer
