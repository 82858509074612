import React from 'react'
import styled from 'styled-components'
import { core /* , Heading */ } from '@almamedia/talent-media-component-library'
import useActiveSubscriptions from '../../api/subscriptions/useActiveSubscriptions'
import useEndedSubscriptions from '../../api/subscriptions/useEndedSubscriptions'
import SubscriptionCard from './SubscriptionCard'
import useUser from '../../api/user/useUser'
import copyTexts from '../../assets/copyTexts'
import Head from '../common/Head'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;

	@media ${core.mediaquery.max['x-small']} {
		padding: 0 ${core.spacing.medium};
	}
`

const Cards = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: ${core.spacing.large};

	@media ${core.mediaquery.range.medium} {
		grid-template-columns: repeat(2, 1fr);
	}

	@media ${core.mediaquery.max.small} {
		grid-template-columns: 1fr;
	}
`

const NoSubscriptionNotification = styled.div`
	padding: ${core.spacing['xx-large']} ${core.spacing.medium};
	background-color: ${core.color.brand.grey[95]};

	@media ${core.mediaquery.max['x-small']} {
		margin: 0 ${core.spacing.medium};
	}
`

// const OfferHeading = styled(Heading.Subtitle)`
// 	padding: ${core.spacing['xx-large']} 0;
// 	text-align: center;

// 	@media ${core.mediaquery.max['x-small']} {
// 		padding: ${core.spacing['x-large']} 0;
// 	}
// `

const NoSubscriptionText = styled.p`
	${core.type.alma.subtitle.medium};
	margin: 0 auto;
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[1]};
	line-height: ${core['line-height']['x-small']};
	max-width: 28rem;
	text-align: center;
	word-break: break-word;
`

type SubscriptionsProps = {
	hasEnded?: boolean
}

const Subscriptions = ({ hasEnded }: SubscriptionsProps) => {
	const { data: user } = useUser()
	const useIsomorphicSubscriptions = hasEnded
		? useEndedSubscriptions
		: useActiveSubscriptions
	const { data, isLoading, error } = useIsomorphicSubscriptions(user, true)

	const noSubscriptionsPresent =
		!isLoading && !error && (!data || data.length === 0)

	if (noSubscriptionsPresent) {
		const noSubscriptionText = hasEnded
			? copyTexts.subscription['ei ole päättyneitä tilauksia'](user?.email)
			: copyTexts.subscription['ei voimassa olevia tilauksia'](user?.email)
		return (
			<NoSubscriptionNotification>
				<Head title='Omat tilaukset - oma asiointi - Alma Media' />
				<NoSubscriptionText>{noSubscriptionText}</NoSubscriptionText>
			</NoSubscriptionNotification>
		)
	}
	return (
		<Wrapper>
			<Head title='Omat tilaukset - oma asiointi - Alma Media' />
			<Cards>
				{data?.map((d) => (
					<SubscriptionCard
						data={d}
						user={user}
						key={d.subscription.id}
						hasEnded={hasEnded}
					/>
				))}
			</Cards>
			{
				// FIXME: Enable these when we have data for offer cards.
				/*
			<Divider
				thickness='core.size.line.thin'
				color='core.color.primary.60'
				spacing={{ sides: ['top'], size: 'core.spacing.xx-large' }}
			/>
			<OfferHeading size='medium, @min.small=large'>
				Sinua voisi myös kiinnostaa
			</OfferHeading>
			<Cards>
				<OfferCard
					data={{
						organization: 'Tivi',
						productName: 'Tivi digitilaus',
						oldPrice: '17.900',
						newPrice: '13.900',
						description:
							'Tivi tarjoaa lukijoille tietoa uusista teknologioista, menestystarinoista Suomessa ja ulkomailla sekä tietotekniikkayritysten strategioista ja tulevaisuudesta.',
						features: [
							'Mobiililukuoikeus',
							'Uutisten kommentointi',
							'Uutisten tallentaminen',
						],
					}}
				/>
				<OfferCard
					data={{
						organization: 'Tivi',
						productName: 'Tivi digitilaus',
						oldPrice: '17.900',
						newPrice: '13.900',
						description:
							'Tivi tarjoaa lukijoille tietoa uusista teknologioista, menestystarinoista Suomessa ja ulkomailla sekä tietotekniikkayritysten strategioista ja tulevaisuudesta.',
						features: [
							'Mobiililukuoikeus',
							'Uutisten kommentointi',
							'Uutisten tallentaminen',
							'Lehden lukuoikeus',
						],
					}}
				/>
				<OfferCard
					data={{
						organization: 'Tivi',
						productName: 'Tivi digitilaus',
						oldPrice: '17.900',
						newPrice: '13.900',
						description:
							'Tivi tarjoaa lukijoille tietoa uusista teknologioista, menestystarinoista Suomessa ja ulkomailla sekä tietotekniikkayritysten strategioista ja tulevaisuudesta.',
						features: ['Mobiililukuoikeus', 'Uutisten kommentointi'],
					}}
				/>
			</Cards>
			*/
			}
		</Wrapper>
	)
}

export default Subscriptions
