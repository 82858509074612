export function waitForElmById(selector: string): Promise<HTMLElement | null> {
	return new Promise((resolve) => {
		if (document && document.getElementById(selector)) {
			return resolve(document.getElementById(selector))
		}

		const observer = new MutationObserver(() => {
			if (document && document.getElementById(selector)) {
				resolve(document.getElementById(selector))
				observer.disconnect()
			}
		})

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		})
	})
}

export function insertFetchedHtml(
	data: string | undefined,
	hasContentRendered: React.MutableRefObject<boolean>,
	divRef?: React.RefObject<HTMLDivElement>
) {
	if (data && !hasContentRendered.current) {
		const htmlFragment = document.createRange().createContextualFragment(data)
		if (divRef?.current) {
			divRef.current.innerHTML = ''
			divRef.current.appendChild(htmlFragment)
		} else {
			document.body.appendChild(htmlFragment)
		}
		hasContentRendered.current = true
	}
}
