export default function trapFocus(
	id: string,
	startFocusFrom?: number,
	skipInitialFocus?: boolean
) {
	if (typeof window !== 'undefined') {
		const focusableElements =
			'button:not(:disabled), [href]:not(:disabled), input:not(:disabled)'
		const modal = document.querySelector(id) as Element & {
			focusListener?: (e: KeyboardEvent) => void
		} // select the modal by it's id

		if (modal) {
			const firstFocusableElement =
				modal.querySelectorAll<HTMLElement>(focusableElements)[0] // get first element to be focused inside modal

			const focusableContent = Array.from(
				modal.querySelectorAll<HTMLElement>(focusableElements)
			).filter((element) => {
				const style = window.getComputedStyle(element)
				return style.visibility !== 'hidden'
			})

			const lastFocusableElement =
				focusableContent && focusableContent?.[focusableContent.length - 1] // get last element to be focused inside modal

			const focusListener = (e: KeyboardEvent) => {
				const isTabPressed = e.key === 'Tab'

				/* eslint-disable no-lonely-if */
				if (!isTabPressed) {
					return
				}

				if (e.shiftKey) {
					// if shift key pressed for shift + tab combination
					if (
						document.activeElement === firstFocusableElement &&
						lastFocusableElement
					) {
						lastFocusableElement.focus() // add focus for the last focusable element
						e.preventDefault()
					}
				} else {
					// if tab key is pressed
					if (
						document.activeElement === lastFocusableElement &&
						firstFocusableElement
					) {
						// if focused has reached to last focusable element then focus first focusable element after pressing tab
						firstFocusableElement.focus() // add focus for the first focusable element
						e.preventDefault()
					}
				}
			}

			const prevFocusListener = modal.focusListener
			if (prevFocusListener) {
				document.removeEventListener('keydown', prevFocusListener, false)
			}

			modal.focusListener = focusListener
			document.addEventListener('keydown', focusListener, false)

			if (!skipInitialFocus) {
				if (startFocusFrom) {
					const elementToFocusFirst =
						modal.querySelectorAll<HTMLElement>(focusableElements)[
							startFocusFrom
						]
					elementToFocusFirst?.focus()
				} else {
					firstFocusableElement?.focus()
				}
			}
		}
	}
}
