import config from 'config'

export default {
	'Kirjaudu ulos': 'Kirjaudu ulos',
	'Kirjaudu sisään': 'Kirjaudu sisään',
	'Omat tiedot': {
		text: 'Omat tiedot',
		url: '/omat-tiedot',
	},
	'Muokkaa käyttäjätunnusta': {
		text: 'Muokkaa käyttäjä\u00ADtunnusta',
		url: `${config.almaTunnusSelfService}/omat-tiedot`,
	},
	['Vaihda salasana']: {
		text: 'Vaihda sala\u00ADsana',
		url: `${config.almaTunnusSelfService}/vaihda-salasana`,
	},
	cmpSettings: 'Eväste\u00ADasetukset',
	Tietosuojapyynnöt: {
		text: 'Tieto\u00ADpyynnöt',
		url: `${config.almaTunnusSelfService}/tietopyynnot`,
	},
	'Käyttö- ja sopimusehdot': {
		text: 'Käyttö- ja sopimus\u00ADehdot',
		url: 'https://www.almatalent.fi/alma-talentin-digitaalisten-palveluiden-sopimusehdot/',
	},
	nav: {
		breadCrumb: 'Oma asiointi - etusivu',
		Etusivu: {
			text: 'Oma asiointi -etusivu',
			url: '/',
		},
		'Omat tilaukset': {
			text: 'Omat tilaukset',
			url: '/voimassa-olevat-tilaukset',
		},
		'Osoitteen muutos': {
			text: 'Osoitteenmuutos',
			url: 'https://kampanjat.almamedia.fi/osoitteenmuutos.html',
		},
		'Jakelun keskeytys': {
			text: 'Jakelun keskeytys',
			url: 'https://kampanjat.almamedia.fi/jakelunkeskeytys.html',
		},
		'Usein kysyttyä': {
			text: 'Usein kysyttyä',
			url: 'https://www.almamedia.fi/yhteystiedot/asiakaspalvelu/usein-kysytyt-kysymykset/',
		},
		'Ilmoita jakeluongelmasta': {
			text: 'Ilmoita jakelu\u00ADongelmasta',
			url: 'https://kampanjat.almamedia.fi/palautteenantoat.html',
		},
	},
	userProfile: {
		'Omat tiedot': 'Omat tiedot',
		'Tärkeitä linkkejä': 'Tärkeitä linkkejä',
		Käyttäjätunnus: 'Käyttäjätunnus',
	},
	subscription: {
		'Tilauksen tiedot': 'Tilauksen tiedot',
		'Voimassa olevat': 'Voimassa olevat',
		Päättyneet: 'Päättyneet',
		Maksutiedot: 'Maksu\u00ADtiedot',
		dataColumn: {
			Tilausmuoto: 'Tilaus\u00ADmuoto',
			Tilausnumero: 'Tilaus\u00ADnumero',
			Alkamispäivä: 'Alkamis\u00ADpäivä',
			Päättymispäivä: 'Päättymis\u00ADpäivä',
			Maksutapa: 'Maksu\u00ADtapa',
			'Seuraava veloitus': 'Seuraava veloitus',
			Veloituspäivä: 'Veloitus\u00ADpäivä',
			'Seuraava veloituskausi': 'Seuraava veloitus\u00ADkausi',
		},
		notifications: {
			'Maksukortti vanhenemassa': 'Maksu\u00ADkortti vanhenemassa',
			'Maksuton kokeilutilaus': 'Maksuton kokeilu\u00ADtilaus',
			'Tämä tilaus on liittosi kautta':
				'Tämä tilaus on liittosi kautta. Tehdäksesi muutoksia tilaukseen otathan yhteyttä liittoosi.',
			'Mikäli haluat lisätietoja':
				'Mikäli haluat lisätietoja tästä tilauksesta, ota yhteyttä asiakaspalveluun: kl.asiakaspalvelu@almamedia.fi',
		},
		buttons: {
			'Tilaa uudelleen': 'Tilaa uudelleen',
			'Näytä tilauksen tiedot': 'Näytä tilauksen tiedot',
			Tarkastele: 'Tarkastele',
		},
		'Peruuta tilaus': 'Peruuta tilaus',
		'Maksukaudet ja kuitit': 'Maksu\u00ADkaudet ja kuitit',
		'Päivitä korttitiedot': 'Päivitä korttitiedot',
		'ei voimassa olevia tilauksia': (email?: string) =>
			`Sinulla ei ole voimassa olevia tilauksia Alma-tunnuksella ${email}`,
		'Lukuoikeuksien määrä': 'Lukuoikeuksien määrä',
		'Odottaa aktivointia': 'Odottaa aktivointia',
		Perhetilaus: 'Perhetilaus',
		'Muokkaa perhetilausta': 'Muokkaa perhetilausta',
		'poista lukuoikeus käyttäjältä':
			'Poista lukuoikeus käyttäjältä jonka sähköpostiosoite on',
		Lukuoikeus: 'Lukuoikeus',
		Poista: 'Poista',
		Lisää: 'Lisää',
		Tallenna: 'Tallenna',
		'Lisää lukuoikeus': 'Lisää lukuoikeus',
		'Tilausta ei voi muokata': 'Tilausta ei voi muokata',
		errorMessages: {
			AlreadyAdded:
				'Tämä sähköpostisoite sisältyy jo perhetilauksesi lukuoikeuksiin.',
			UserHasSubscription:
				'Tälle sähköpostiosoitteelle löytyy jo voimassa oleva tilaus.',
			General:
				'Tapahtui virhe. Tarkista sähköpostiosoite, tai kokeile hetken päästä uudelleen.',
		},
		'ei ole päättyneitä tilauksia': (email?: string) =>
			`Tunnuksella ${email} ei ole päättyneitä tilauksia`,
	},
	subscriptionManagementPage: {
		'Tilauksen hallinta': 'Tilauksen hallinta',
		'Tilaa uudelleen': 'Tilaa uudelleen',
	},
	cancelFlow: {
		layout: {
			'Pysy nykyisessä tilauksessa': 'Pysy nykyisessä tilauksessa',
			'Valitse peruuttamisen syy': 'Valitse peruuttamisen syy',
		},
		familyCard: {
			'tilauksesi päättyy myös tältä henkilöltä:':
				'Jos perut, tilauksesi päättyy myös tältä tilaukseesi liitetyltä henkilöltä:',
			'tilauksesi päättyy myös näiltä henkilöiltä:':
				'Jos perut, tilauksesi päättyy myös näiltä tilaukseesi liitetyiltä henkilöiltä:',
			'valitse "Pysy nykyisessä tilauksessa':
				'Valitse "Pysy nykyisessä tilauksessa" ja pidät nykyisen tilauksesi edullisella hinnalla voimassa.',
		},
		offer: {
			Tilaustyyppi: 'Tilaustyyppi',
			Hinta: 'Hinta',
			'Tilaus alkaa': 'Tilaus alkaa',
			'Tilauksen tiedot': 'Tilauksen tiedot',
			'Etu sinulle': 'Etu sinulle',
			'Hyväksy tarjous': 'Hyväksy tarjous',
		},
		benefits: {
			'Tähtijutut vain tilaajille': 'Tähtijutut vain tilaajille',
			'Plus-jutut vain tilaajille': 'Plus-jutut vain tilaajille',
			'Nykyiseen tilaukseesi kuuluu': 'Nykyiseen tilaukseesi kuuluu',
		},
		confirmation: {
			'Tilauksesi on päättynyt': 'Tilauksesi on päättynyt',
			'Kiitos tilauksesta!': 'Kiitos tilauksesta!',
			'Siirry Omat tilaukset -sivulle': 'Siirry Omat tilaukset -sivulle',
		},
		survey: {
			'Jatkaaksesi valitse': 'Jatkaaksesi valitse peruuttamisen syy *',
		},
	},
	paymentCardUpdate: {
		success: {
			title: 'Maksukorttisi on päivitetty',
		},
		error: {
			title: 'Maksukortin päivitys epäonnistui',
			info: 'Yritä päivittää kortti hetken kuluttua uudelleen',
		},
	},
	ErrorPage: {
		groupAnonymousPrompt:
			'Kirjaudu sisään henkilö\u00ADkohtaisella\nAlma-tunnuksella',
		groupAnonymousText1:
			'Olet kirjautunut sisään työn\u00ADantajasi tarjoamalla ryhmä\u00ADtunnuksella. Oma asiointi on tarkoitettu vain henkilö\u00ADkohtaiseen Alma-tunnukseen liittyvään asiointiin.\n',
		groupAnonymousText2:
			'Kirjaudu sisään henkilökohtaisella Alma-tunnuksellasi tarkastellaksesi omia tietojasi tai tilauksiasi.',
		groupButton2Label:
			'Luo maksuton Alma-tunnus ja linkitä se ryhmätunnukseen siirtymällä median etusivulle',
	},
	toastNotification: {
		'Maksukortti vanhenemassa':
			'Maksukorttisi on vanhentumassa. Päivitä korttitietosi, jotta tilauksesi jatkuu.',
		'Vahvista sähköpostiosoitteesi': 'Vahvista sähköpostiosoitteesi',
	},
	emailVerificationNotification: {
		verificationMessageShort: 'Vahvista Alma-tunnuksesi sähköpostiosoite',
		verificationMessageLong:
			'Vahvista tunnuksesi klikkaamalla sähköpostiisi tulleen viestin vahvistuslinkkiä. Jos et löydä viestiä, voit etsiä sitä sähköpostistasi lähettäjän osoitteella alma-tunnus@email.tunnus.almamedia.fi Tarkistathan myös, ettei viesti ole mennyt roskapostikansioosi. Tarvittaessa voit tilata uuden vahvistusviestin alta.',
		buttonTextPrompt: 'Tilaa uusi vahvistussähköposti',
		buttonTextCompleted: 'Vahvistussähköposti lähetetty',
	},
}
