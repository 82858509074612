import { Consent } from '../types/user'

export const checkConsentProperties = (consent: Consent) => {
	return (
		Object.prototype.hasOwnProperty.call(consent, 'custom') &&
		Object.prototype.hasOwnProperty.call(consent, 'specialFeature') &&
		Object.prototype.hasOwnProperty.call(consent, 'standard') &&
		Object.prototype.hasOwnProperty.call(consent, 'nonTCFVendors') &&
		Object.prototype.hasOwnProperty.call(consent, 'vendors')
	)
}

export function isSelligentEnabled(consent?: Consent) {
	return (
		consent?.standard[3] &&
		consent?.standard[4] &&
		consent?.standard[5] &&
		consent?.standard[6] &&
		consent?.standard[7] &&
		consent?.standard[8] &&
		consent?.standard[9]
	)
}

export function isMatejEnabled(consent?: Consent) {
	return consent?.standard[1] && consent?.standard[5] && consent?.standard[6]
}

function isStandardMeasurementEnabled(consent?: Consent) {
	return consent?.standard[1] && consent?.standard[8]
}

function isGoogleEnabled(consent?: Consent) {
	return consent?.nonTCFVendors[3] !== false
}

export function isGAEnabled(consent?: Consent) {
	return isStandardMeasurementEnabled(consent) && isGoogleEnabled(consent)
}
