import React from 'react'
import styled from 'styled-components'
import { core } from '@almamedia/talent-media-component-library'

const Container = styled.div<{ $size: number }>`
	width: ${({ $size }) => $size}px;
	height: ${({ $size }) => $size}px;
	overflow: hidden;
	position: absolute;
	top: -10px;
	right: -10px;

	:before,
	:after {
		position: absolute;
		content: '';
		display: block;
		border: 10px solid ${core.color.brand.purple[30]};
		border-top-color: transparent;
		border-right-color: transparent;
		border-left-color: transparent;
		z-index: ${core['z-index'].elevate};
	}

	:before {
		top: -10px;
		left: 0px;
		border-left-color: ${core.color.primary[100]};
	}

	:after {
		bottom: 0px;
		right: -10px;
		transform: rotate(90deg);
		border-right-color: ${core.color.primary[100]};
	}

	@media ${core.mediaquery.max['x-small']} {
		width: calc(100% - ${core.spacing.large} * 2);
		position: relative;
		overflow: visible;
		display: flex;
		justify-content: center;
		height: auto;
		margin-bottom: ${core.spacing.large};
		top: 0;
		right: 0;

		:before,
		:after {
			border-width: 5px;
			border-color: ${core.color.brand.purple[60]};
			border-bottom-width: 38px;
			border-right-width: ${core.spacing.medium};
		}

		:before {
			left: -10px;
			top: 7.5px;
		}

		:after {
			transform: none;
			right: -10px;
			top: 7.5px;
			bottom: 0px;
		}
	}
`

const Text = styled.span<{ $width: number }>`
	${core.type.alma.ui.semi};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height'].medium};
	position: absolute;
	display: block;
	width: ${({ $width }) => $width}px;
	padding: ${core.spacing.small} 0;
	background-color: ${core.alma.color.brand.purple};
	color: ${core.color.primary[100]};
	box-shadow: 0 ${core.border.large} 10px rgba(0, 0, 0, ${core.opacity[20]});
	text-align: center;
	transform: translate(29.29%, -100%) rotate(45deg);
	transform-origin: bottom left;
	top: 0;
	left: calc(-43% + 10px);

	@media ${core.mediaquery.max['x-small']} {
		width: 100%;
		position: static;
		transform: none;
		left: -43%;
		z-index: ${core['z-index'].pagetop};

		:after {
			content: '';
			position: absolute;
			border-style: solid;
			top: 100%;
			right: 0;
			border-width: 4px ${core.size.small} 0 0;
			border-color: ${core.color.brand.purple[30]} transparent transparent
				transparent;
		}

		:before {
			content: '';
			position: absolute;
			border-style: solid;
			top: 100%;
			left: 0px;
			border-width: 4px ${core.size.small} 0 0;
			border-color: ${core.color.brand.purple[30]} transparent transparent
				transparent;
			transform: scaleX(-1);
		}
	}
`

const BASE_SIZE = 150

const Ribbon = ({
	text,
	additionalSize = 0,
}: {
	text: string
	additionalSize?: number
}) => {
	const size = BASE_SIZE + additionalSize
	const textWidth = size * 1.43

	return (
		<Container $size={size}>
			<Text $width={textWidth}>{text}</Text>
		</Container>
	)
}

export default Ribbon
