export default {
	apiRoot: '/api/',
	baseUrl:
		import.meta.env.PROD && import.meta.env.MODE !== 'preview'
			? '/oma-asiointi'
			: '',
	tempCookieKeys: {
		gravitoOPUid: 'gravitoOPUid',
	},
	sammioSiteId: 'tam',
	allowEmailVerification: true,
	assetApiUrl: 'https://assets.almatalent.fi/static/images/self-service',
	getTalentFeedsDomain: (mediaSlug: string) =>
		`https://feeds.kauppalehti.fi/teaserboxes/latest/${mediaSlug}/vain-tilaajille?includeLocked=true`,
}
