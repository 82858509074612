import useSWR from 'swr'
import { parseResponse } from '..'
import { UserState } from '../../types/user'
import URLS from '../urls'

const fetcher = (url: string) => fetch(url).then((res) => parseResponse(res))

export default () => {
	return useSWR<UserState>(URLS.USER, fetcher)
}
