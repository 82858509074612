import { useState, useEffect, useId } from 'react'
import useCustomerSubscription from '../../api/subscriptions/useCustomerSubscription'
import { getSubscriptionName } from '../../utils/subscriptionUtils'

const useReconsiderOrderedAnalytics = () => {
	const { data: subscriptions } = useCustomerSubscription()
	const [subscriptionsBeforeOrder, setSubscriptionsBeforeOrder] =
		useState(subscriptions)
	const transaction_id = useId()

	useEffect(() => {
		const newSubsription =
			subscriptions &&
			subscriptionsBeforeOrder &&
			subscriptions.find(
				(s) =>
					!subscriptionsBeforeOrder.some(
						(bs) => s.subscription.id === bs.subscription.id
					)
			)
		if (newSubsription) {
			setSubscriptionsBeforeOrder(subscriptions)
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'purchased reconsider offer',
				reconsider_transaction_id: transaction_id,
				reconsider_order_value: newSubsription.nextPrice.price,
				reconsider_order_items: [
					{
						item_id:
							newSubsription.subscription.billingInformation.priceCode?.slice(
								0,
								100
							),
						item_name: 'Reconsider Offer',
						item_brand: getSubscriptionName(newSubsription.products[0].name)
							.subscriptionMediaName,
						price: newSubsription.nextPrice.price,
					},
				],
			})
		}
	}, [
		setSubscriptionsBeforeOrder,
		subscriptions,
		subscriptionsBeforeOrder,
		transaction_id,
	])
}

export default useReconsiderOrderedAnalytics
