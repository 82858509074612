import config from 'config'
import {
	core,
	Icon,
	Link as DsLink,
	Heading,
	Divider,
	SecondaryButton,
} from '@almamedia/talent-media-component-library'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import useUser from '../../api/user/useUser'
import copyTexts from '../../assets/copyTexts'
import { getUserInitials } from '../../utils/userUtils'
import { Email } from '../common'
import DataColumns, { ColumnData } from '../common/DataColumns'
import TopBreadCrumbLink from '../common/TopBreadCrumbLink'
import GALinkEvent from '../common/GALinkEvent'
import Head from '../common/Head'
import EmailVerificationNotification from '../notification/EmailVerificationNotification'

const { allowEmailVerification } = config

const Wrapper = styled.div`
	max-width: ${core.size.template['page-wrapper']};
	padding: ${core.spacing['x-large']} ${core.spacing.large}
		${core.spacing['xxxx-large']};
	margin: 0 auto;

	@media ${core.mediaquery.max.small} {
		max-width: ${core.size.template['page-wrapper-mobile']};
	}

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing.large} 0 ${core.spacing['xx-large']};
	}
`

const Grey = styled.div`
	background-color: ${core.color.brand.grey[95]};
`

const Circle = styled.div`
	display: grid;
	justify-items: center;
	padding: ${core.spacing['xxx-large']} 0;
	margin: ${core.spacing['xx-large']} 0;

	> div::before {
		border: ${core.outline.medium} solid ${core.color.primary[10]};
		border-radius: 50%;
		color: ${core.color.primary[10]};
		content: attr(data-initials);
		display: block;
		height: ${core.size['xxxxx-large']};
		width: ${core.size['xxxxx-large']};
		line-height: ${core.font.scale[7]}
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		${core.type.alma.subtitle.medium};
		font-size: ${core.font.scale[4]};
	}

	@media ${core.mediaquery.max['x-small']} {
		margin: ${core.spacing['x-large']} 0;
		padding: ${core.spacing['xx-large']} 0;

		> div::before {
			height: ${core.size['xxx-large']};
			width: ${core.size['xxx-large']};
		}
	}
`

const UtilityLink = styled(Link)<{ $userUnverified?: boolean }>`
	display: block;
	text-decoration: none;
	padding: ${core.spacing.medium} ${core.spacing.medium} ${core.spacing.medium}
		0;

	${({ $userUnverified }) =>
		$userUnverified &&
		`pointer-events: none; 
			> div > svg > path {
				fill: ${core.color.primary[60]}; 
			}
			div > span { 
				color: ${core.color.primary[60]}; 
			}`};

	:focus-visible {
		outline: ${core.outline.medium} solid ${core.color.primary[10]};
		outline-offset: 0;
		border-radius: ${core.shape.rounded};
	}
`

const HeadingSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media ${core.mediaquery.max['x-small']} {
		padding: 0 ${core.spacing.medium};
	}
`

const StyledHeadline = styled(Heading.Subtitle)`
	margin-bottom: ${core.spacing.medium};
	text-align: center;

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing.small};
	}
`

const StyledSubHeadline = styled(Heading.Subtitle)`
	@media ${core.mediaquery.max['small']} {
		&:nth-of-type(1) {
			margin-top: ${core.spacing['xx-large']};
		}
	}
`

const SplitLayout = styled.div`
	margin: ${core.spacing.large};

	@media ${core.mediaquery.min['medium']} {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr;
		column-gap: ${core.spacing['xx-large']};
		margin: 0;
	}
`

const Flex = styled.div`
	display: flex;
	align-items: center;
`
const StyledDSLink = styled(DsLink)`
	display: flex;
	align-items: center;
	margin-left: ${core.spacing.medium};
`

const UtilityLinkWithGA = ({
	text,
	url,
	$userUnverified,
}: {
	text: string
	url: string
	$userUnverified?: boolean
}) => (
	<GALinkEvent>
		<UtilityLink
			to={url}
			$userUnverified={$userUnverified}
			tabIndex={$userUnverified ? -1 : 0}
		>
			<Flex>
				<Icon.CoreChevronRight
					size='core.size.icon.large'
					color='core.alma.color.brand.black'
				/>
				<StyledDSLink forwardedAs='div'>{text}</StyledDSLink>
			</Flex>
		</UtilityLink>
	</GALinkEvent>
)

const Profile = () => {
	const { data: user } = useUser()
	const showVerificationNotification =
		allowEmailVerification && user?.needsVerification
	const dataColumns: (ColumnData | null)[] = useMemo(
		() => [
			...[
				user
					? { label: 'Nimi', value: `${user.firstname} ${user.lastname}` }
					: null,
			],
			...[user ? { label: 'Sähköposti', value: user.email } : null],
		],
		[user]
	)

	function openCmpModal() {
		window.gravitocmp.openPreferences()
	}

	return (
		<Wrapper>
			<Head title='Omat tiedot - oma asiointi - Alma Media' />
			<TopBreadCrumbLink />
			<HeadingSection>
				<StyledHeadline level='h1' size='medium, @min.small=x-large'>
					{copyTexts.userProfile['Omat tiedot']}
				</StyledHeadline>
				<Email>{user && user.email}</Email>
			</HeadingSection>
			{user && (
				<Grey>
					<Circle>
						<div data-initials={getUserInitials(user)} aria-hidden='true'></div>
					</Circle>
				</Grey>
			)}
			<SplitLayout>
				<div>
					<StyledSubHeadline level='h2' size='small'>
						{copyTexts.userProfile['Käyttäjätunnus']}
					</StyledSubHeadline>
					<DataColumns
						data={dataColumns}
						withRowGap
						withRowDivider
						withoutTopDivider
						withoutBottomDivider
						textColumnCss={{
							marginTop: core.spacing.medium,
							marginBottom: core.spacing.medium,
						}}
						css={{
							marginTop: core.spacing.large,
							marginBottom: core.spacing.large,
						}}
					/>
					{showVerificationNotification && (
						<EmailVerificationNotification
							titleText={
								copyTexts.emailVerificationNotification.verificationMessageShort
							}
							infoText={
								copyTexts.emailVerificationNotification.verificationMessageLong
							}
						/>
					)}
					<Divider
						color={'core.alma.color.brand.black'}
						thickness={'core.size.line.thin'}
					/>
					<UtilityLinkWithGA
						text={copyTexts['Muokkaa käyttäjätunnusta'].text}
						url={copyTexts['Muokkaa käyttäjätunnusta'].url}
						$userUnverified={showVerificationNotification}
					/>
					<Divider
						color={'core.alma.color.brand.black'}
						thickness={'core.size.line.thin'}
					/>
					<UtilityLinkWithGA
						text={copyTexts['Vaihda salasana'].text}
						url={copyTexts['Vaihda salasana'].url}
						$userUnverified={showVerificationNotification}
					/>
					<Divider
						color={'core.alma.color.brand.black'}
						thickness={'core.size.line.thin'}
					/>
					<UtilityLinkWithGA
						text={copyTexts.nav['Osoitteen muutos'].text}
						url={copyTexts.nav['Osoitteen muutos'].url}
					/>
					<Divider
						color={'core.alma.color.brand.black'}
						thickness={'core.size.line.thin'}
					/>
				</div>
				<div>
					<StyledSubHeadline
						level='h2'
						size='small'
						css={{ marginBottom: core.spacing.large }}
					>
						{copyTexts.userProfile['Tärkeitä linkkejä']}
					</StyledSubHeadline>
					<Divider
						color={'core.alma.color.brand.black'}
						thickness={'core.size.line.thin'}
					/>
					<UtilityLinkWithGA
						text={copyTexts['Tietosuojapyynnöt'].text}
						url={copyTexts['Tietosuojapyynnöt'].url}
						$userUnverified={showVerificationNotification}
					/>
					<Divider
						color={'core.alma.color.brand.black'}
						thickness={'core.size.line.thin'}
					/>
					<UtilityLinkWithGA
						text={copyTexts['Käyttö- ja sopimusehdot'].text}
						url={copyTexts['Käyttö- ja sopimusehdot'].url}
					/>
					<Divider
						color={'core.alma.color.brand.black'}
						thickness={'core.size.line.thin'}
						spacing={{
							sides: ['bottom'],
							size: 'core.spacing.x-large',
						}}
					/>
					<SecondaryButton
						icon={<Icon.CoreSlidersSolid size='core.size.icon.medium' />}
						onClick={openCmpModal}
						className='alma-cmpv2-settings-link'
						aria-label='Avaa evästeasetukset - modaali'
						css={{ width: '100%' }}
					>
						{copyTexts.cmpSettings}
					</SecondaryButton>
				</div>
			</SplitLayout>
		</Wrapper>
	)
}

export default Profile
