import React from 'react'
import useReconsiderPrice from '../../api/subscriptions/useReconsiderPrice'
import { useParams } from 'react-router-dom'
import CancellationCard from './CancellationCard'

const CancellationAnchor = () => {
	const { id } = useParams()
	const { data } = useReconsiderPrice(id)

	return (
		<CancellationCard
			titleText={data?.priceInfo.detail1}
			bodyText={data?.priceInfo.detail2}
		/>
	)
}

export default CancellationAnchor
