import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import {
	Card,
	Badge,
	PrimaryButton,
	SecondaryButton,
	TertiaryButton,
	core,
	Link as DSLink,
	Icon,
	Heading,
} from '@almamedia/talent-media-component-library'
import { Subscription } from '../../types/subscription'
import OrganizationLogo from '../common/OrganizationLogo'
import { InfoBadge, InfoBadgeIcon, StyledLink } from '../common/index'
import DataColumns, { ColumnData } from '../common/DataColumns'
import {
	formatDate,
	formatPrice,
	getEndDate,
	getMediaDomainFromOrganization,
	getSubscriptionName,
	hasAccessToSubscriptionInformation,
	isCardExpiring,
	isFreeSubscription,
	isProductRetired,
	isSubscriptionEndingSoon,
	isSubscriptionRecurring,
	isSubscriptionStartingInFuture,
	isUnionSubscription,
	shouldShowReorderButton,
	nextBillingDate,
	isCirixTypeSubscription,
} from '../../utils/subscriptionUtils'
import copyTexts from '../../assets/copyTexts'
import { getMediaSlugs } from '../../utils/mediaUtils'
import { UserState } from '../../types/user'
import useActiveSubscriptions from '../../api/subscriptions/useActiveSubscriptions'
import { updatePaymentCard } from '../../utils/paymentUtils'
import useUpdatePaymentCard from '../../api/subscriptions/useUpdatePaymentCard'
import GALinkEvent from '../common/GALinkEvent'
import useProductDetails from '../../api/products/useProductDetails'
import { useApplicationContext } from '../../App'

const StyledCard = styled(Card)`
	padding: ${core.spacing['x-large']};
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing.large} ${core.spacing.medium};
	}
`
const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
`

const LogoContainer = styled.div`
	margin-bottom: ${core.spacing.medium};

	> svg {
		width: auto;
	}
`

const SubscriptionName = styled.div`
	margin-bottom: ${core.spacing['x-large']};
	color: ${core.color.primary[10]};

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing.large};
	}
`
const SubscriptionActions = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${core.spacing.small};
	margin-top: ${core.spacing['xx-large']};

	@media ${core.mediaquery.max['x-small']} {
		margin-top: ${core.spacing['x-large']};
	}
`

const MediaName = styled(Heading.Subtitle)`
	margin-bottom: ${core.spacing['xx-small']};
`
const SubscriptionType = styled.div`
	${core.type.alma.ui.normal};
	line-height: ${core['line-height'].small};
	font-size: ${core.font.scale[2]};

	@media ${core.mediaquery.max['x-small']} {
		font-size: ${core.font.scale[1]};
		line-height: ${core['line-height'].medium};
	}
`

const StyledDSLink = styled(DSLink)`
	> span {
		text-decoration: none;
	}
`

const MediaLink = styled(DSLink)`
	display: inline-block;
	width: 100%;
	text-align: center;

	:focus-visible {
		outline-width: ${core.outline.medium};
	}
`

const ActiveBadge = styled(Badge)`
	background-color: ${core.color.status.success[95]};
	color: ${core.color.status.success[20]};
`

const InactiveBadge = styled(Badge)`
	background-color: ${core.color.primary[85]};
	color: ${core.color.primary[10]};
`

const PausedBadge = styled(Badge)`
	background-color: ${core.color.primary[30]};
	color: ${core.color.primary[100]};
`

const FutureBadge = styled(Badge)`
	background-color: ${core.color.brand.grey[95]};
	color: ${core.color.primary[10]};
`

const EndingSoonBadge = styled(Badge)`
	background-color: ${core.color.primary[10]};
	color: ${core.color.primary[100]};
`

const StyledPrimaryButton = styled(PrimaryButton)`
	background-color: ${core.color.primary[10]};
	width: 100%;
`
const StyledSecondaryButton = styled(SecondaryButton)`
	outline-color: ${core.color.primary[10]};
	color: ${core.color.primary[10]};
	width: 100%;
`

const StyledTertiaryButton = styled(TertiaryButton)`
	width: 100%;
`

const ExpiredTextContainer = styled.div`
	display: flex;
	flex-direction: column;
`

const ExpiredLinkButton = styled(TertiaryButton)`
	padding: 0;
	justify-content: start;
	width: fit-content;
`

const HeavyText = styled.span`
	${core.type.alma.ui.heavy};
`

type SubscriptionCardProps = {
	data: Subscription
	user?: UserState
	hasEnded?: boolean
}

const SubscriptionCard = ({ data, user, hasEnded }: SubscriptionCardProps) => {
	const {
		subscription: {
			agreementNumber,
			startDate,
			endDate,
			organization,
			id,
			productInstances,
		},
		products,
		nextPrice,
	} = data
	const { isILAndroidApp } = useApplicationContext()
	const { data: activeSubscriptions } = useActiveSubscriptions()
	const { data: productDetails } = useProductDetails(productInstances?.[0].code)
	const { trigger } = useUpdatePaymentCard()
	const _isSubscriptionRecurring = isSubscriptionRecurring(data)
	const _isSubscriptionEndingSoon = isSubscriptionEndingSoon(data)
	const _isSubscriptionStartingInFuture = isSubscriptionStartingInFuture(data)
	const _isFreeSubscription = isFreeSubscription(data)
	const _isCardExpiring = isCardExpiring(data)
	const _hasAccessToSubscriptionInformation =
		hasAccessToSubscriptionInformation(data, user)
	const _isUnionSubscription = isUnionSubscription(data)
	const _isProductRetired = isProductRetired(productDetails)
	const _isCirixTypeSubscription = isCirixTypeSubscription(data)
	const mediaDomain = getMediaDomainFromOrganization(organization)

	const showDataColumns = !_isUnionSubscription
	const showStartDate = !hasEnded && !_isSubscriptionRecurring
	const showEndDate = !_isSubscriptionRecurring || hasEnded || endDate
	const hasPaused = data.subscription.status === 'Paused'
	const showNextPaymentAmount =
		!hasEnded && !endDate && _isSubscriptionRecurring
	const showNextPaymentDate = !hasEnded && !endDate && _isSubscriptionRecurring
	const showNotification =
		(!hasEnded && _isCardExpiring) ||
		_isFreeSubscription ||
		_isUnionSubscription ||
		_isCirixTypeSubscription
	const _shouldshowReorderButton =
		shouldShowReorderButton(hasEnded, data, activeSubscriptions) &&
		!isILAndroidApp
	const _nextBillingDate = nextBillingDate(data)

	const { subscriptionMediaName, subscriptionName } = getSubscriptionName(
		products[0].name
	)
	const subscriptionTypeName = isSubscriptionRecurring(data)
		? 'Jatkuva'
		: 'Määräaikainen'

	const mediaSlugs = getMediaSlugs(organization)

	const dataColumns: (ColumnData | null)[] = useMemo(
		() => [
			...[
				!hasEnded
					? {
							label: copyTexts.subscription.dataColumn['Tilausmuoto'],
							value: subscriptionTypeName,
					  }
					: null,
			],
			{ label: 'Tilausnumero', value: agreementNumber },
			...[
				showNextPaymentAmount
					? {
							label: copyTexts.subscription.dataColumn['Seuraava veloitus'],
							value: formatPrice(nextPrice?.price),
					  }
					: null,
			],
			...[
				showNextPaymentDate
					? {
							label: copyTexts.subscription.dataColumn['Veloituspäivä'],
							value: formatDate(_nextBillingDate),
					  }
					: null,
			],
			...[
				showStartDate
					? {
							label: copyTexts.subscription.dataColumn['Alkamispäivä'],
							value: formatDate(startDate),
					  }
					: null,
			],
			...[
				showEndDate && endDate
					? {
							label: copyTexts.subscription.dataColumn['Päättymispäivä'],
							value: getEndDate(endDate),
					  }
					: null,
			],
		],
		[
			showNextPaymentAmount,
			showNextPaymentDate,
			showStartDate,
			showEndDate,
			nextPrice?.price,
			_nextBillingDate,
			startDate,
			endDate,
			subscriptionTypeName,
			agreementNumber,
			hasEnded,
		]
	)

	const handleUpdateCard = useCallback(
		() => updatePaymentCard(trigger, data?.subscription.id),
		[data?.subscription.id, trigger]
	)

	return (
		<StyledCard
			borderColor='core.color.primary.60'
			borderWidth='x-small'
			rounded
		>
			<div>
				<Header>
					{!hasEnded &&
						!_isSubscriptionEndingSoon &&
						!_isSubscriptionStartingInFuture &&
						!hasPaused && (
							<ActiveBadge
								text='Voimassa'
								fontSize='core.font.scale.0'
								icon={
									<Icon.CoreCheckCircle
										color='core.color.status.success.20'
										size='core.size.icon.medium'
									/>
								}
							/>
						)}
					{!hasEnded && _isSubscriptionStartingInFuture && (
						<FutureBadge text='Tulossa' fontSize='core.font.scale.0' />
					)}
					{!hasEnded && _isSubscriptionEndingSoon && (
						<EndingSoonBadge text='Päättymässä' fontSize='core.font.scale.0' />
					)}
					{hasEnded && (
						<InactiveBadge text='Päättynyt' fontSize='core.font.scale.0' />
					)}
					{hasPaused && (
						<PausedBadge text='Tauolla' fontSize='core.font.scale.0' />
					)}
				</Header>
				<LogoContainer>
					<OrganizationLogo
						organization={organization}
						size='core.size.icon.x-large'
					/>
				</LogoContainer>
				<SubscriptionName>
					<MediaName size='medium, @min.small=large'>
						{subscriptionMediaName}
					</MediaName>
					{subscriptionName && (
						<SubscriptionType>{subscriptionName}</SubscriptionType>
					)}
				</SubscriptionName>
				{showDataColumns && <DataColumns data={dataColumns} withRowGap />}
				{showNotification && (
					<InfoBadge
						isCardExpiring={_isCardExpiring}
						css={_isCardExpiring ? { width: '100%' } : undefined}
					>
						{_isCardExpiring && (
							<>
								<InfoBadgeIcon>
									<Icon.CoreExclamationCircle size='core.size.icon.medium' />
								</InfoBadgeIcon>
								<ExpiredTextContainer>
									<HeavyText>
										{
											copyTexts.subscription.notifications[
												'Maksukortti vanhenemassa'
											]
										}
									</HeavyText>
									<ExpiredLinkButton onClick={handleUpdateCard}>
										<DSLink borderType='light' as='div'>
											{copyTexts.subscription['Päivitä korttitiedot']}
										</DSLink>
									</ExpiredLinkButton>
								</ExpiredTextContainer>
							</>
						)}
						{(_isFreeSubscription || _isCirixTypeSubscription) && (
							<>
								<InfoBadgeIcon>
									<Icon.CoreInfoCircle size='core.size.icon.medium' />
								</InfoBadgeIcon>
								<span>
									{_isFreeSubscription &&
										copyTexts.subscription.notifications[
											'Maksuton kokeilutilaus'
										]}
									{_isCirixTypeSubscription &&
										copyTexts.subscription.notifications[
											'Mikäli haluat lisätietoja'
										]}
								</span>
							</>
						)}
						{_isUnionSubscription && (
							<>
								<InfoBadgeIcon>
									<Icon.CoreInfoCircle size='core.size.icon.medium' />
								</InfoBadgeIcon>
								<span>
									{
										copyTexts.subscription.notifications[
											'Tämä tilaus on liittosi kautta'
										]
									}
								</span>
							</>
						)}
					</InfoBadge>
				)}
			</div>
			<div>
				<SubscriptionActions>
					{_shouldshowReorderButton && !_isProductRetired && (
						<GALinkEvent>
							<StyledDSLink href={mediaSlugs?.campaignPaywall}>
								<StyledPrimaryButton tabIndex={-1}>
									Tilaa {subscriptionMediaName}
								</StyledPrimaryButton>
							</StyledDSLink>
						</GALinkEvent>
					)}
					{_hasAccessToSubscriptionInformation ? (
						<GALinkEvent>
							<StyledLink to={`${id}`}>
								<StyledSecondaryButton tabIndex={-1} forwardedAs='div'>
									{copyTexts.subscription.buttons['Näytä tilauksen tiedot']}
								</StyledSecondaryButton>
							</StyledLink>
						</GALinkEvent>
					) : (
						<SecondaryButton
							forwardedAs='div'
							icon={<Icon.CoreInfoCircle size='core.size.icon.medium' />}
							css={{
								pointerEvents: 'none',
								borderColor: core.color.primary[60],
							}}
						>
							<span>{copyTexts.subscription['Tilausta ei voi muokata']}</span>
						</SecondaryButton>
					)}
					{!isILAndroidApp && (
						<GALinkEvent>
							<MediaLink href={`https://${mediaDomain}`}>
								<StyledTertiaryButton
									forwardedAs='div'
									icon={<Icon.CoreArrowRight size='core.size.icon.medium' />}
									tabIndex={-1}
								>
									Siirry {mediaDomain}
								</StyledTertiaryButton>
							</MediaLink>
						</GALinkEvent>
					)}
				</SubscriptionActions>
			</div>
		</StyledCard>
	)
}

export default SubscriptionCard
