import {
	ap,
	kl,
	mb,
	mu,
	te,
	tt,
	tv,
	us,
	alma,
} from '@almamedia/talent-media-component-library'
import config from 'config'

const { campaignUrl, getMediaDomain } = config

import { MediaSlugs, ShortSlug } from '../types/media'
import { OrganizationId } from '../types/organization'

export const getMediaSlugs = (media?: string): MediaSlugs => {
	const { origin } = window.location
	const mediaValue = media?.toLowerCase().replace(/\s/g, '') || origin
	const mode = import.meta.env.MODE

	if (/arvopaperi/.test(mediaValue))
		return {
			siteShortSlug: 'ap',
			siteName: 'Arvopaperi',
			siteNameSlug: 'arvopaperi',
			siteCase: 'Arvopaperin',
			campaignPaywall: campaignUrl('arvopaperi', 'ja57606ja13901oma'),
			mediaDomain: getMediaDomain('arvopaperi'),
		}
	if (/mediuutiset/.test(mediaValue))
		return {
			siteShortSlug: 'mu',
			siteName: 'Mediuutiset',
			siteNameSlug: 'mediuutiset',
			siteCase: 'Mediuutisten',
			campaignPaywall: campaignUrl('mediuutiset', 'ja446ja10901oma'),
			mediaDomain: getMediaDomain('mediuutiset'),
		}
	if (/mikrobitti/.test(mediaValue))
		return {
			siteShortSlug: 'mb',
			siteName: 'Mikrobitti',
			siteNameSlug: 'mikrobitti',
			siteCase: 'Mikrobitin',
			campaignPaywall: campaignUrl('mikrobitti', 'ja446ja10901oma'),
			mediaDomain: getMediaDomain('mikrobitti'),
		}
	if (/talouselama/.test(mediaValue))
		return {
			siteShortSlug: 'te',
			siteName: 'Talouselämä',
			siteNameSlug: 'talouselama',
			siteCase: 'Talouselämän',
			campaignPaywall: campaignUrl('talouselama', 'ja886ja18901oma'),
			mediaDomain: getMediaDomain('talouselama'),
		}
	if (/(tekniikkatalous|tektal)/.test(mediaValue))
		return {
			siteShortSlug: 'tt',
			siteName: 'Tekniikkatalous',
			siteNameSlug: 'tekniikkatalous',
			siteCase: 'Tekniikka&Talouden',
			campaignPaywall: campaignUrl('tekniikkatalous', 'ja77606ja19901oma'),
			mediaDomain: getMediaDomain('tekniikkatalous'),
		}
	if (/tietoviikko/.test(mediaValue) || /tivi/.test(mediaValue))
		return {
			siteShortSlug: 'tv',
			siteName: 'Tietoviikko',
			siteNameSlug: 'tietoviikko',
			siteCase: 'Tietoviikon',
			campaignPaywall: campaignUrl('tietoviikko', 'ja65606ja17901oma'),
			mediaDomain: getMediaDomain('tietoviikko'),
		}
	if (/uusisuomi/.test(mediaValue))
		return {
			siteShortSlug: 'us',
			siteName: 'Uusisuomi',
			siteNameSlug: 'uusisuomi',
			siteCase: 'Uuden Suomen',
			campaignPaywall: campaignUrl('uusisuomi', 'ja486ja11901oma'),
			mediaDomain: getMediaDomain('uusisuomi'),
		}
	if (/iltalehti/.test(mediaValue))
		return {
			siteShortSlug: 'il',
			siteName: 'Iltalehti',
			siteNameSlug: 'iltalehti',
			siteCase: 'Iltalehden',
			campaignPaywall:
				mode === 'qa'
					? 'https://www-dev.iltalehti.fi/tilaus/#/kampanja/ja599012ja6991oma'
					: 'https://www.iltalehti.fi/tilaus/#/kampanja/ja599012ja6991oma',
			mediaDomain: getMediaDomain('iltalehti'),
		}
	if (/almatalentkirjat/.test(mediaValue))
		return {
			siteShortSlug: 'atk',
			siteName: 'Alma Talent Kirjat',
			siteNameSlug: 'almatalentkirjat',
			siteCase: 'Alma Talent Kirjan',
			campaignPaywall: 'https://kirjat.almatalent.fi/tilaus/#/',
			mediaDomain: 'https://kirjat.almatalent.fi',
		}
	return {
		siteShortSlug: 'kl',
		siteName: 'Kauppalehti',
		siteNameSlug: 'kauppalehti',
		siteCase: 'Kauppalehden',
		campaignPaywall: campaignUrl('kauppalehti', 'ja81603ja35901oma'),
		mediaDomain: getMediaDomain('kauppalehti'),
	}
}

export const getMediaToken = (media: ShortSlug) => {
	if (media === 'ap') {
		return ap
	}
	if (media === 'kl') {
		return kl
	}
	if (media === 'mb') {
		return mb
	}
	if (media === 'mu') {
		return mu
	}
	if (media === 'te') {
		return te
	}
	if (media === 'tt') {
		return tt
	}
	if (media === 'tv') {
		return tv
	}
	if (media === 'us') {
		return us
	}
	if (media === 'il') {
		return alma
	}
}

export const getUrlToFrontpageBasedOnSubscription = (
	array: OrganizationId[] | undefined
) => {
	switch (array) {
		case array && array.includes('Klfi'):
			return 'https://www.kauppalehti.fi/'
		case array && array.includes('Talouselama'):
			return 'https://www.talouselama.fi/'
		case array && array.includes('Arvopaperi'):
			return 'https://www.arvopaperi.fi/'
		case array && array.includes('TekTal'):
			return 'https://www.tekniikkatalous.fi/'
		case array && array.includes('Mikrobitti'):
			return 'https://www.mikrobitti.fi/'
		case array && array.includes('Mediuutiset'):
			return 'https://www.mediuutiset.fi/'
		case array && array.includes('Tivi'):
			return 'https://www.tivi.fi/'
		case array && array.includes('UusiSuomi'):
			return 'https://www.uusisuomi.fi/'
		case array && array.includes('Iltalehti'):
			return 'https://www.iltalehti.fi/'
		default:
			return 'https://www.kauppalehti.fi/'
	}
}
