import config from 'config'
import sessionStorageUtils from './sessionStorageUtils'
import { ResponseBody } from '../types/subscription'
import { mutate } from 'swr'
import GLOBALSTORAGE from '../api/globalstorage'
import copyTexts from '../assets/copyTexts'

const { baseUrl } = config
const { setCardUpdateInfo } = sessionStorageUtils
const { paymentCardUpdate } = copyTexts

export const updatePaymentCard = async (trigger: any, id?: string) => {
	/**
	 * This function triggers the POST request to update payment card.
	 * The arguments are: url to which user is directed after operation is complete, and subscription id.
	 * The response body is the initialised order, with generated values for `orderId` and `randomToken`
	 * that are needed for validation.
	 * Additionally, the subscription id is needed for the information where to redirect the user afterwards.
	 *
	 * User is directed to an external service to complete the operation, so to persist the values
	 * they must be saved as cookies with a 30 minute expiration.
	 *
	 * Regardless of the API call's success, user is taken to a callback site which
	 * handles the redirection back to the relevant subscription's management page.
	 */
	try {
		const response: ResponseBody = await trigger({
			returnURL: `${window.location.origin}${baseUrl}/maksukortti-paivitetty`,
			id: id,
		})
		if (response) {
			setCardUpdateInfo(
				JSON.stringify({
					orderId: response.id,
					orderRandomToken: response.randomToken,
					subscriptionId: id,
					returnPath: baseUrl
						? location.pathname.replace(new RegExp(baseUrl, 'gi'), '')
						: location.pathname,
				})
			)
			window.location.href = response.redirectUrls.payment
		}
	} catch (e) {
		console.log(e)
		mutate(GLOBALSTORAGE.POPUP_NOTIFICATION, {
			title: paymentCardUpdate.error.title,
			info: paymentCardUpdate.error.info,
			isFailure: true,
		})
	}
}
