import config from 'config'
import React, { useEffect, useState, useCallback, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import {
	core,
	Icon,
	Logo,
	MenuItem as Item,
	IconButton,
	SecondaryButton,
	Heading,
	Types,
} from '@almamedia/talent-media-component-library'
import { LinkProps, useLocation } from 'react-router-dom'
import { LazyMotion, domAnimation, m } from 'framer-motion'

import almaLoginUtils from '../../utils/loginUtils'
import { logout } from '../../api/login'
import useUser from '../../api/user/useUser'
import {
	getUserInitials,
	isGroupAnonymous,
	isUserLoggedIn,
} from '../../utils/userUtils'
import copyTexts from '../../assets/copyTexts'
import { StyledLink } from '../common'
import { useWindowResizeListener } from '../../utils/windowUtils'
import { usePeekabooAnimation } from '../../utils/animationUtils'
import GALinkEvent from '../common/GALinkEvent'
import EmailVerificationNotification from '../notification/EmailVerificationNotification'

export const HEADER_TOP_HEIGHT = 4.887
const HEADER_TOP_HEIGHT_PX = HEADER_TOP_HEIGHT * 16
const MEDIAQUERY_SMALL = 36 * 16
const { allowEmailVerification } = config

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: sticky;
	top: 0;
	background: ${core.color.primary[10]};
	z-index: ${core['z-index'].sitetop};
	height: ${HEADER_TOP_HEIGHT}rem;
`

const Content = styled.div`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	max-width: 75rem;
	width: 100%;
	padding: ${core.spacing.medium} ${core.spacing.large};

	@media ${core.mediaquery.max['x-small']} {
		padding: 0;
	}
`

const Top = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing.medium};
	}
`

const LogoContainer = styled(StyledLink)`
	display: flex;

	:focus-visible {
		outline-color: ${core.color.primary[100]};
		outline-offset: ${core['outline-offset'].medium};
	}

	> svg {
		width: auto;
	}
`

const StyledMenuButton = styled(Item)`
	> div {
		border: ${core.border.small} solid ${core.color.primary[100]};
		border-radius: ${core.shape.circle};
		${({ isOpen }) => isOpen && `background-color: ${core.color.primary[100]}`}
	}

	.menu-item-user-badge-active > path {
		${({ isOpen }) => isOpen && `fill: ${core.color.primary[10]}`}
	}
`

const BaseMenuItem = styled(Item)<{ $noBorder?: boolean }>`
	border-bottom: ${core.border['x-small']} solid ${core.color.primary[85]};
	justify-content: start;
	${({ $noBorder }) => $noBorder && 'border-bottom: none'};

	:focus-visible {
		z-index: ${core['z-index'].elevate};
	}
`

const MenuItems = styled.div<{ $innerHeight: number }>`
	background: ${core.color.primary[100]};
	display: flex;
	border: ${core.border['x-small']} solid ${core.color.primary[10]};
	border-top: none;
	border-bottom-left-radius: ${core.shape.rounded};
	border-bottom-right-radius: ${core.shape.rounded};
	position: absolute;
	right: 0;
	top: calc(${core.size.icon['xx-large']} + ${core.spacing.medium} * 2);
	max-width: 45rem;
	width: 100%;
	gap: ${core.spacing.medium};
	padding: ${core.spacing.large};
	overscroll-behavior: contain;
	overflow-y: scroll;
	max-height: ${({ $innerHeight }) =>
		`calc(${$innerHeight}px - ${HEADER_TOP_HEIGHT}rem)`};

	@media ${core.mediaquery.max.small} {
		max-width: none;
		width: calc(100vw - ${core.border['x-small']} * 2);
		right: 1px;
	}

	@media ${core.mediaquery.max['x-small']} {
		width: 100%;
		flex-direction: column;
		padding: 0;
		gap: ${core.spacing['xxx-small']};
		position: static;
	}
`

const LeftSide = styled.div`
	display: flex;
	flex-direction: column;
	flex: 50%;
	height: 100%;
`

const RightSide = styled.div`
	display: flex;
	flex-direction: column;
	flex: 50%;
	height: 100%;
`

const MenuItem = styled(BaseMenuItem)`
	padding-left: ${core.spacing.medium};

	svg {
		width: ${core.size.large};
	}
`

const MenuItemWithLogo = styled(MenuItem)`
	column-gap: ${core.spacing.small};
`

const Logout = styled(MenuItem)<{ $side: 'left' | 'right' }>`
	${({ $side }) =>
		$side === 'right'
			? css`
					display: none;

					@media ${core.mediaquery.max['x-small']} {
						display: grid;
						margin-bottom: ${core.spacing['xxx-small']};
					}
			  `
			: css`
					@media ${core.mediaquery.max['x-small']} {
						display: none;
					}
			  `}
`

const Subtitle = styled(Heading.Subtitle)`
	padding: ${core.spacing.small} ${core.spacing.medium} ${core.spacing.small}
		${core.spacing.medium};
`

const MenuItemWithSubItems = styled(BaseMenuItem)`
	z-index: ${core['z-index'].elevate};

	> a > div {
		padding-left: ${core.spacing.medium};
	}

	@media ${core.mediaquery.min.small} {
		padding-left: ${core.spacing.medium};
	}
`

const MenuSubItem = styled(BaseMenuItem)<{
	disable?: boolean
	notificationStyles?: boolean
}>`
	padding-left: ${core.spacing['x-large']};
	${({ notificationStyles }) =>
		notificationStyles &&
		`border-top: ${core.border['x-small']} solid ${core.color.primary[85]};
		margin-top: ${core.spacing.medium};`};
	${({ disable }) =>
		disable &&
		`pointer-events: none; 
		 > span { 
				color: ${core.color.primary[60]}; 
			}`};
	@media ${core.mediaquery.max['x-small']} {
		background: ${core.color.primary[95]};
	}
`

const CmpButtonContainer = styled.div`
	padding: ${core.spacing.small} ${core.spacing.medium} ${core.spacing.small}
		${core.spacing['x-large']};
	border-bottom: ${core.border['x-small']} solid ${core.color.primary[85]};
	display: grid;

	@media ${core.mediaquery.max['x-small']} {
		background: ${core.color.primary[95]};
	}

	@media ${core.mediaquery.min.medium} {
		width: 100%;
	}
`

const RouterLink = styled(StyledLink)`
	width: 100%;
	:focus-visible {
		outline-offset: 0;
	}

	> div {
		width: 100%;
	}
`

const CustomLinkWrapper = ({ children, ...rest }: LinkProps) => (
	<GALinkEvent>
		<RouterLink {...rest}>{children}</RouterLink>
	</GALinkEvent>
)

const MenuItemWithGA = ({
	label,
	href,
	icon,
	noBorder,
}: {
	label: string
	href: string
	icon?: ReactNode
	noBorder?: boolean
}) => {
	if (icon) {
		return (
			<GALinkEvent>
				<MenuItemWithLogo
					label={label}
					href={href}
					icon={icon}
					$noBorder={noBorder}
				/>
			</GALinkEvent>
		)
	}

	return (
		<GALinkEvent>
			<MenuItem label={label} href={href} />
		</GALinkEvent>
	)
}

const MenuSubItemWithGA = ({
	label,
	href,
	disable,
	notificationStyles,
}: {
	label: string
	href: string
	disable?: boolean
	notificationStyles?: boolean
}) => (
	<GALinkEvent>
		<MenuSubItem
			label={label}
			href={href}
			disable={disable}
			notificationStyles={notificationStyles}
			tabIndex={disable ? -1 : 0}
		/>
	</GALinkEvent>
)

const Header = () => {
	const { data: user } = useUser()
	const [menuIsOpen, setMenuIsOpen] = useState(false)
	const [displayPersonalToggle, setDisplayPersonalToggle] = useState(
		window.innerWidth < MEDIAQUERY_SMALL
	)
	const [personalIsOpen, setPersonalIsOpen] = useState(!displayPersonalToggle)
	const [innerHeight, setInnerHeight] = useState(window.innerHeight)

	const { pathname } = useLocation()

	const setPersonalMenu = useCallback(() => {
		const isXSmall = window.innerWidth < MEDIAQUERY_SMALL
		setDisplayPersonalToggle(isXSmall)
		setPersonalIsOpen(isXSmall ? false : true)
		setInnerHeight(window.innerHeight)
	}, [])
	useWindowResizeListener(setPersonalMenu)

	const scrollAnimation = usePeekabooAnimation(HEADER_TOP_HEIGHT_PX, () =>
		setMenuIsOpen(false)
	)
	const loggedIn = isUserLoggedIn(user)
	const showVerificationNotification =
		allowEmailVerification && user?.needsVerification

	const checkLabel = () => {
		if (loggedIn && !isGroupAnonymous(user)) {
			return getUserInitials(user)
		} else if (loggedIn && isGroupAnonymous(user)) {
			return 'Ryhmätunnus'
		} else {
			return 'Kirjaudu'
		}
	}

	useEffect(() => {
		setMenuIsOpen(false)
		scrollAnimation.set(0)
	}, [pathname, scrollAnimation])

	return (
		<LazyMotion features={domAnimation}>
			<Container as={m.div} style={{ translateY: scrollAnimation }}>
				<Content>
					<Top>
						<GALinkEvent>
							<LogoContainer
								to={copyTexts.nav['Etusivu'].url}
								aria-label='Etusivu'
							>
								<Logo.CoreAlmaMain
									size='core.size.icon.xx-large'
									color='core.color.primary.100'
								/>
							</LogoContainer>
						</GALinkEvent>
						<StyledMenuButton
							forwardedAs='button'
							isOpen={menuIsOpen}
							isActive={loggedIn}
							showUserBadge
							backgroundColor='core.color.primary.10'
							color='core.color.primary.100'
							activeColor='core.color.primary.10'
							label={checkLabel()}
							onClick={() =>
								loggedIn
									? setMenuIsOpen(!menuIsOpen)
									: almaLoginUtils.openAlmaLogin({
											source: 'oma-asiointi',
									  })
							}
							showCaret={loggedIn}
						/>
					</Top>
					{menuIsOpen && (
						<MenuItems $innerHeight={innerHeight}>
							<LeftSide>
								<MenuItem
									forwardedAs='div'
									customMenuItemWrapper={
										<CustomLinkWrapper to={copyTexts.nav['Etusivu'].url} />
									}
									label={copyTexts.nav['Etusivu'].text}
								/>
								<MenuItemWithSubItems
									forwardedAs='div'
									customMenuItemWrapper={
										<CustomLinkWrapper
											to={copyTexts['Omat tiedot'].url}
											css={{
												zIndex: showVerificationNotification
													? `${core['z-index'].elevate}`
													: undefined,
											}}
										/>
									}
									label={copyTexts['Omat tiedot'].text}
									dualButton={
										displayPersonalToggle ? (
											<IconButton
												icon={<Icon.CoreChevronDown />}
												onClick={() => setPersonalIsOpen(!personalIsOpen)}
											/>
										) : undefined
									}
									backgroundColor='core.color.primary.100'
									$noBorder={showVerificationNotification}
								/>
								{showVerificationNotification && (
									<RouterLink to={copyTexts['Omat tiedot'].url}>
										<EmailVerificationNotification
											hideButton
											infoText={
												copyTexts.emailVerificationNotification
													.verificationMessageShort
											}
											infoTextWithIcon
											css={{
												padding: `${core.spacing.small} 0 0 ${core.spacing.small}`,
												marginBottom: 0,
											}}
										/>
									</RouterLink>
								)}
								{personalIsOpen && (
									<>
										<MenuSubItemWithGA
											label={copyTexts['Muokkaa käyttäjätunnusta'].text}
											href={copyTexts['Muokkaa käyttäjätunnusta'].url}
											disable={showVerificationNotification}
											notificationStyles={showVerificationNotification}
										/>
										<MenuSubItemWithGA
											label={copyTexts['Vaihda salasana'].text}
											href={copyTexts['Vaihda salasana'].url}
											disable={showVerificationNotification}
										/>
										<MenuSubItemWithGA
											label={copyTexts['Tietosuojapyynnöt'].text}
											href='.'
											disable={showVerificationNotification}
										/>
										<MenuSubItemWithGA
											label={copyTexts['Käyttö- ja sopimusehdot'].text}
											href={copyTexts['Käyttö- ja sopimusehdot'].url}
										/>
										<CmpButtonContainer>
											<SecondaryButton
												icon={
													<Icon.CoreSlidersSolid size='core.size.icon.medium' />
												}
												onClick={() => window.gravitocmp.openPreferences()}
												className='alma-cmpv2-settings-link'
												aria-label='Avaa evästeasetukset - modaali'
												css={{ alignSelf: 'center' }}
											>
												{copyTexts.cmpSettings}
											</SecondaryButton>
										</CmpButtonContainer>
									</>
								)}
								<MenuItem
									forwardedAs='div'
									customMenuItemWrapper={
										<CustomLinkWrapper
											to={copyTexts.nav['Omat tilaukset'].url}
										/>
									}
									label={copyTexts.nav['Omat tilaukset'].text}
								/>
								<MenuItemWithGA
									label={copyTexts.nav['Osoitteen muutos'].text}
									href={copyTexts.nav['Osoitteen muutos'].url}
								/>
								<MenuItemWithGA
									label={copyTexts.nav['Jakelun keskeytys'].text}
									href={copyTexts.nav['Jakelun keskeytys'].url}
								/>
								<MenuItemWithGA
									label={copyTexts.nav['Usein kysyttyä'].text}
									href={copyTexts.nav['Usein kysyttyä'].url}
								/>
								<MenuItemWithGA
									label={copyTexts.nav['Ilmoita jakeluongelmasta'].text}
									href={copyTexts.nav['Ilmoita jakeluongelmasta'].url}
								/>
								<Logout
									label={copyTexts['Kirjaudu ulos']}
									forwardedAs='button'
									onClick={() => logout()}
									icon={<Icon.CoreLogOut size='core.size.icon.medium' />}
									$side='left'
									$noBorder
								/>
							</LeftSide>
							<RightSide>
								<Subtitle size='x-small'>Palvelut</Subtitle>
								<MenuItemWithGA
									label='Kauppalehti'
									href='https://www.kauppalehti.fi/'
									icon={
										<Logo.KlMobile
											size='core.size.icon.large'
											color='kl.color.brand.primary'
										/>
									}
								/>
								<MenuItemWithGA
									label='Arvopaperi'
									href='https://www.arvopaperi.fi/'
									icon={
										<Logo.ApMobile
											size='core.size.icon.large'
											color='ap.color.brand.primary'
										/>
									}
								/>
								<MenuItemWithGA
									label='Talouselämä'
									href='https://www.talouselama.fi/'
									icon={
										<Logo.TeMobile
											size='core.size.icon.large'
											color='te.color.brand.primary'
										/>
									}
								/>
								<MenuItemWithGA
									label='Tivi'
									href='https://www.tietoviikko.fi/'
									icon={
										<Logo.TvMobile
											size='core.size.icon.large'
											color='tv.color.brand.primary'
										/>
									}
								/>
								<MenuItemWithGA
									label='Mikrobitti'
									href='https://www.mikrobitti.fi/'
									icon={
										<Logo.MbMobile
											size='core.size.icon.large'
											color='mb.color.brand.primary'
										/>
									}
								/>
								<MenuItemWithGA
									label='Tekniikka & Talous'
									href='https://www.tekniikkatalous.fi/'
									icon={
										<Logo.TtMobile
											size='core.size.icon.large'
											color='tt.color.brand.primary'
										/>
									}
								/>
								<MenuItemWithGA
									label='Uusi Suomi'
									href='https://www.uusisuomi.fi/'
									icon={
										<Logo.UsMobile
											size='core.size.icon.large'
											color='us.color.brand.primary'
										/>
									}
								/>
								<MenuItemWithGA
									label='Iltalehti'
									href='https://www.iltalehti.fi/'
									icon={
										<Logo.IlMobile
											size='core.size.icon.large'
											color={'#FF0000' as Types.Color}
										/>
									}
								/>
								<MenuItemWithGA
									label='AT Kirjat'
									href='https://www.almatalent.fi/kaikki-tuotteet-ja-palvelut/kirjat-sovellus/'
									icon={<Logo.ATKirjatMobile size='core.size.icon.large' />}
									noBorder
								/>
								<Logout
									label={copyTexts['Kirjaudu ulos']}
									forwardedAs='button'
									onClick={() => logout()}
									icon={<Icon.CoreLogOut size='core.size.icon.medium' />}
									$side='right'
									$noBorder
								/>
							</RightSide>
						</MenuItems>
					)}
				</Content>
			</Container>
		</LazyMotion>
	)
}

export default Header
