import { UserState } from '../types/user'

export const isUserLoggedIn = (user?: UserState) => {
	return !!user?.username
}

export const isGroupAnonymous = (user?: UserState) => {
	return user?.accountType === 'group_anonymous'
}

export const isKLWGroupUser = (user?: UserState) => {
	return isGroupAnonymous(user) && user?.username?.startsWith('klw')
}

export const getUserInitials = (user?: UserState) =>
	user?.firstname && user?.lastname
		? `${user?.firstname?.charAt(0)}${user?.lastname?.charAt(0)}`.toUpperCase()
		: user?.firstname || ''
