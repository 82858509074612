import common from './config.common'

export default {
	...common,
	get almaLogin() {
		const origin = window.location.origin
		const clientID = ((origin) => {
			if (/kauppalehti/.test(origin)) {
				return '9mXy89cpfAmmA52e8RUIFXB9DiN6xfIE'
			} else if (/talouselama/.test(origin)) {
				return 'eNEuKORVMwzRElot88W51KORQr5QwmEh'
			} else if (/arvopaperi/.test(origin)) {
				return 'vMXSJcJN6J6NWg8j18PChMX5yVN3JWWH'
			} else if (/mediuutiset/.test(origin)) {
				return 'atcA9NjzFfwZUIRdAHLb3BrTQOTTAHFW'
			} else if (/tietoviikko/.test(origin)) {
				return 'titD4NZ2HD0l3CNFj2NDpPoYqDVbBrgY'
			} else if (/tekniikkatalous/.test(origin)) {
				return '9RCBzH9qdb9j6T9Ia4gOz8Apu2sCvIP5'
			} else if (/mikrobitti/.test(origin)) {
				return 'kgxi66OQ5hc4nn7VIR5drFP8MbQk6ZdX'
			} else if (/uusisuomi/.test(origin)) {
				return '0gy656nDDp6HGRVECflRlbLOo07sklZK'
			} else if (/iltalehti/.test(origin)) {
				return 'uVZAH05J7SBxbSDcQbqckGUgWYsvwtih'
			} else {
				return ''
			}
		})(origin)
		return {
			domain: 'tunnus-dev.almamedia.net',
			clientID: clientID,
			audience: 'https://appqa.almatalent.fi/api/',
			productionGa: false,
		}
	},
	ilApiDomain: 'https://api.dev.il.fi',
	almaTunnusSelfService: 'https://itsepalvelu.tunnus-dev.almamedia.net',
	campaignUrl: (media: string, campaignCode: string) =>
		`https://qa.${media}.media/tilaus/#/kampanja/${campaignCode}`,
	getMediaDomain: (media: string) => {
		if (media === 'iltalehti') {
			return `https://www-dev.iltalehti.fi`
		}
		return `https://qa.${media}.media`
	},
}
