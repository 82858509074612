import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import {
	core,
	Heading,
	Icon,
	Spacer,
	Link as DSLink,
	TertiaryButton,
} from '@almamedia/talent-media-component-library'
import useSubscription from '../../api/subscriptions/useSubscription'
import DataColumns, { ColumnData } from '../common/DataColumns'
import copyTexts from '../../assets/copyTexts'
import { InfoBadge, InfoBadgeIcon, StyledLink } from '../common'
import useUpdatePaymentCard from '../../api/subscriptions/useUpdatePaymentCard'
import {
	isCardExpiring,
	getPaymentType,
	hasSubscriptionEnded,
	isSubscriptionRecurring,
	formatDate,
	nextBillingDate,
	formatPrice,
} from '../../utils/subscriptionUtils'
import useInvoices from '../../api/subscriptions/useInvoices'
import { updatePaymentCard } from '../../utils/paymentUtils'
import GALinkEvent from '../common/GALinkEvent'

const Wrapper = styled.div`
	width: 100%;
	text-align: start;

	@media ${core.mediaquery.min.medium} {
		max-width: 48%;
	}
`

const CTAWrapper = styled.div<{ $noBottomBorder?: boolean }>`
	display: flex;
	align-items: center;
	padding: ${core.spacing.medium} 0;
	border-top: ${core.border['x-small']} solid black;
	border-bottom: ${core.border['x-small']} solid black;
	${({ $noBottomBorder }) => $noBottomBorder && 'border-bottom: 0'};
`

const CTAButton = styled(CTAWrapper).attrs({ as: TertiaryButton })`
	width: 100%;
	justify-content: start;

	:focus-visible {
		outline-offset: 0;
	}
`

const ButtonDSLink = styled(DSLink)`
	display: flex;
	align-items: center;
	margin-left: ${core.spacing.medium};

	span {
		display: flex;
		align-items: center;
	}
`

const StyledDSLink = styled(DSLink)`
	display: flex;
	align-items: center;
	margin-left: ${core.spacing.medium};
`

const SubscriptionAndBillingInfoRight = () => {
	const { id } = useParams()
	const {
		data: { totalInvoicesAmount },
	} = useInvoices(id)
	const { data } = useSubscription(id)
	const { trigger } = useUpdatePaymentCard()
	const subscriptionTypeName =
		data && isSubscriptionRecurring(data) ? 'Jatkuva' : 'Määräaikainen'
	const nextPrice = formatPrice(data?.nextPrice?.price)
	const _isCardExpiring = data && isCardExpiring(data)
	const subscriptionHasEndDate = data && data.subscription.endDate
	const _hasEnded = data && hasSubscriptionEnded(data)
	const isPaymentTypeMobilePay = data && getPaymentType(data) === 'MobilePay'
	const showCardUpdateCTA =
		subscriptionTypeName === 'Jatkuva' &&
		!subscriptionHasEndDate &&
		!_hasEnded &&
		!isPaymentTypeMobilePay
	const _nextBillingDate = nextBillingDate(data)
	const hasEndDate = !!data?.subscription.endDate

	const dataColumn: (ColumnData | null)[] = useMemo(
		() => [
			...[
				data
					? {
							label: copyTexts.subscription.dataColumn['Maksutapa'],
							value: getPaymentType(data),
							badge:
								!_hasEnded && _isCardExpiring ? (
									<InfoBadge
										isCardExpiring={_isCardExpiring}
										css={{ marginBottom: core.spacing.medium }}
									>
										<InfoBadgeIcon>
											<Icon.CoreExclamationCircle size='core.size.icon.medium' />
										</InfoBadgeIcon>
										<span>
											{
												copyTexts.subscription.notifications[
													'Maksukortti vanhenemassa'
												]
											}
										</span>
									</InfoBadge>
								) : undefined,
					  }
					: null,
			],
			...[
				data && nextPrice && !hasEndDate
					? {
							label: copyTexts.subscription.dataColumn['Seuraava veloitus'],
							value: nextPrice,
					  }
					: null,
			],
			...[
				data && _nextBillingDate && !hasEndDate
					? {
							label: copyTexts.subscription.dataColumn['Veloituspäivä'],
							value: formatDate(_nextBillingDate),
					  }
					: null,
			],
		],
		[data, _isCardExpiring, _hasEnded, nextPrice, _nextBillingDate, hasEndDate]
	)

	const handleClick = useCallback(
		() => updatePaymentCard(trigger, data?.subscription.id),
		[data?.subscription.id, trigger]
	)

	return (
		<Wrapper>
			<Heading.Subtitle level='h2' css={{ fontSize: core.font.scale[2] }}>
				{copyTexts.subscription.Maksutiedot}
			</Heading.Subtitle>
			<DataColumns
				data={dataColumn}
				withRowDivider
				withoutTopDivider
				withoutBottomDivider
				textColumnCss={{
					marginTop: core.spacing.medium,
					marginBottom: core.spacing.medium,
				}}
				css={{
					marginTop: core.spacing.large,
					marginBottom: core.spacing.large,
				}}
			/>
			{/* Spacer to offset the height difference between the two DataColumns so that the row dividers align properly */}
			{subscriptionTypeName !== 'Jatkuva' && (
				<Spacer orientation='horizontal' size='core.spacing.x-small' />
			)}
			{showCardUpdateCTA && (
				<CTAButton
					$noBottomBorder={totalInvoicesAmount > 0}
					onClick={handleClick}
				>
					<Icon.CoreChevronRight
						size='core.size.icon.large'
						color='core.alma.color.brand.black'
					/>
					<ButtonDSLink forwardedAs='div'>
						{copyTexts.subscription['Päivitä korttitiedot']}
					</ButtonDSLink>
				</CTAButton>
			)}
			{totalInvoicesAmount > 0 && (
				<GALinkEvent>
					<StyledLink to={`../tilaukset/${id}/maksut`} $noOutlineOffset>
						<CTAWrapper>
							<Icon.CoreChevronRight
								size='core.size.icon.large'
								color='core.alma.color.brand.black'
							/>
							<StyledDSLink forwardedAs='div'>
								{copyTexts.subscription['Maksukaudet ja kuitit']}
							</StyledDSLink>
						</CTAWrapper>
					</StyledLink>
				</GALinkEvent>
			)}
		</Wrapper>
	)
}

export default SubscriptionAndBillingInfoRight
