import { parseResponse } from '..'
import URLS from '../urls'
import useSWRMutation from 'swr/mutation'
import { getMediaSlugs } from '../../utils/mediaUtils'

const mediaDomain = getMediaSlugs()?.mediaDomain
const endpointUrl = `${mediaDomain}/att-public-api`

async function updatePaymentCard(
	/**
	 *
	 * @param id subsctiption id
	 * @param returnURL url where user is redirected to after paytrail is finished and card updated
	 *
	 * @remarks
	 * POST will return the initialized order. You should redirect the user to redirectUrls.payment.
	 * The urls provided with the POST request will be complemented with query params `orderId` and `randomToken`
	 * Customer fills their credit card details and will be then redirected from paytrail site to the `api` url,
	 * where TUJU checks that everything went as should and update relevant documents
	 * If successful TUJU redirects to `paid` url
	 * If orderStatus is pending TUJU redirects to `pending` url (this should never™ happen)
	 * else TUJU redirects to `error` url
	 *
	 */
	url: string,
	{
		arg,
	}: {
		arg: {
			returnURL: string
			id?: string
		}
	}
) {
	const reqBody = {
		subscriptionId: arg.id,
		redirectUrls: {
			paid: arg.returnURL,
			payLater: `${endpointUrl}/orders`,
			error: `${arg.returnURL}?error`,
			payment: `${endpointUrl}/orders`,
			api: `${endpointUrl}`,
			successfulOrder: arg.returnURL,
			seamlessAuthorization: `${endpointUrl}`,
			pendingPayment: arg.returnURL,
			delayedPayment: `${endpointUrl}`,
		},
	}
	return await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(reqBody),
	}).then((response) => parseResponse(response))
}

export default () => {
	return useSWRMutation(`${URLS.UPDATE_CREDIT_CARD}`, updatePaymentCard)
}
