import { SWRHook, Key } from 'swr'
import URLS from './urls'

/**
 * Silent reload prevents showing the loading spinner if it's a request for the `USER` API.
 * We need this when the SWR needs to poll the USER API frequently. At this moment,
 * the only use cases for this is when we need to get the info whether the
 * user has completed their email verification.
 */
const needsSilentReload = (key: Key, cache?: any) => {
	const userNeedsValidation = !!cache?.get(URLS.USER)?.data?.needsVerification
	const isUserKey = key === URLS.USER
	return isUserKey && userNeedsValidation
}

// Middleware which increases request count when swr is used with fetcher.
// When fetcher call is settled the request count is decreased.
export const requestCounter =
	(setRequestCount: React.Dispatch<React.SetStateAction<number>>) =>
	(useSWRNext: SWRHook) =>
	(key: Key, fetcher: any, config: any) => {
		if (needsSilentReload(key, config?.cache)) {
			/**
			 * If silent reload is needed, then enable revalidateOnFocus, and auto polling every 5 seconds
			 */
			return useSWRNext(key, fetcher, {
				...config,
				revalidateOnFocus: true,
				refreshInterval: 10000,
			})
		}

		const extendedFetcher = (...args: unknown[]) => {
			setRequestCount((prev) => prev + 1)
			return Promise.resolve(fetcher(...args)).finally(() =>
				setRequestCount((prev) => prev - 1)
			)
		}
		if (fetcher) {
			return useSWRNext(key, extendedFetcher, config)
		}

		return useSWRNext(key, fetcher, config)
	}
