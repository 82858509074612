import React from 'react'
import styled, { css } from 'styled-components'
import { Card, Heading, core } from '@almamedia/talent-media-component-library'
import { useParams } from 'react-router-dom'
import { getSubscriptionName } from '../../utils/subscriptionUtils'
import useSubscription from '../../api/subscriptions/useSubscription'
import DataColumns, { ColumnData } from '../common/DataColumns'
import Ribbon from '../common/Ribbon'
import { SubscriptionMediaName } from '../../types/subscription'
import { getCancellationOfferImage } from '../../utils/cancellationUtils'

export const Email = styled.span`
	${core.type.alma.ui.heavy};
	margin-bottom: ${core.spacing.medium};
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[1]};
	line-height: ${core['line-height'].medium};
`

const Wrapper = styled(Card)`
	max-width: ${core.size.template['page-wrapper']};
	max-width: 28rem;
	margin: 0 auto;
`

const Top = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	background: ${core.color.primary[100]};
	padding: ${core.spacing.large} ${core.spacing.large}
		${core.spacing['x-small']};

	@media ${core.mediaquery.max['x-small']} {
		align-items: center;
	}
`

const Media = styled(Heading.Subtitle)`
	margin-bottom: ${core.spacing['xx-small']};
`

const Type = styled.span`
	${core.type.alma.ui.normal};
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[2]};
	line-height: ${core['line-height'].small};
	text-transform: capitalize;

	@media ${core.mediaquery.max['x-small']} {
		font-size: ${core.font.scale[1]};
		line-height: ${core['line-height'].medium};
	}
`

const Image = styled.img`
	align-self: center;

	@media ${core.mediaquery.max['x-small']} {
		width: 236px;
		height: auto;
	}
`

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	background: ${core.color.primary[100]};
	margin: ${core.spacing['x-small']} ${core.spacing['x-large']}
		${core.spacing['x-large']};

	@media ${core.mediaquery.max['x-small']} {
		margin: ${core.spacing['x-small']} ${core.spacing.medium}
			${core.spacing.medium};
	}
`

const OfferTitle = styled(Heading.Subtitle)`
	margin-bottom: ${core.spacing['x-small']};
`

const Body = styled.span`
	${core.type.alma.ui.normal};
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height'].medium};
	margin-bottom: ${core.spacing.large};

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing.medium};
	}
`

type CancellationCardProps = {
	ribbonText?: string
	titleText?: string
	bulletSection?: JSX.Element
	bodyText?: string
	dataColumns?: (ColumnData | null)[]
	button?: JSX.Element
}

const CancellationCard = ({
	ribbonText,
	titleText,
	bulletSection,
	bodyText,
	dataColumns,
	button,
}: CancellationCardProps) => {
	const { id } = useParams()
	const { data: subscriptionData } = useSubscription(id)

	const { subscriptionMediaName, subscriptionName } = getSubscriptionName(
		subscriptionData?.products[0].name || ''
	)

	return (
		<div css={{ margin: `0 ${core.spacing.medium}` }}>
			<Wrapper borderColor='core.color.primary.85' borderWidth='x-small'>
				<Top>
					{ribbonText && <Ribbon text={ribbonText} />}
					<Media size='medium, @min.small=large'>{subscriptionMediaName}</Media>
					<Type>{subscriptionName}</Type>
					<Image
						src={getCancellationOfferImage(
							subscriptionMediaName as SubscriptionMediaName
						)}
						width='280'
						height='210'
					/>
				</Top>
				<Bottom>
					{titleText && <OfferTitle size='small'>{titleText}</OfferTitle>}
					{bulletSection}
					{bodyText && <Body>{bodyText}</Body>}
					{dataColumns && (
						<DataColumns
							data={dataColumns}
							withRowGap
							withoutTopDivider
							withoutBottomDivider
							contentColumnCss={{
								width: '100%',
							}}
							textColumnCss={css`
								@media ${core.mediaquery.min.small} {
									span {
										width: 50%;
									}
								}
							`}
						/>
					)}
					{button}
				</Bottom>
			</Wrapper>
		</div>
	)
}

export default CancellationCard
