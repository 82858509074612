import { core, Message } from '@almamedia/talent-media-component-library'
import React, { useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import usePopupNotifications from '../../api/popupnotifications/usePopupNotifications'
import { HEADER_TOP_HEIGHT } from '../header/Header'

type StyleProps = {
	close: boolean
}

const cycle = keyframes`
    0% {transform: translateX(100%)}
    100% {transform: translateX(0%)}
`
const reverseCycle = keyframes`
    0% {transform: translateX(0%)}
    100% {transform: translateX(100%)}
`

const AnimatedMessage = styled(Message)<StyleProps>`
	animation: ${(p) => (p.close ? reverseCycle : cycle)} 0.2s;
	z-index: ${core['z-index'].overlay};
	top: calc(${HEADER_TOP_HEIGHT}rem + ${core.spacing.large});

	@media ${core.mediaquery.max['x-small']} {
		top: ${HEADER_TOP_HEIGHT}rem;
	}
`

const PopupNotification = () => {
	const [closeNotification, setCloseNotification] = useState(false)
	const { data, mutate } = usePopupNotifications()
	const timeout = useRef<number>()
	const messageType = data?.isFailure ? 'failure' : 'confirmation'

	const scheduleNotificationClose = () => {
		setCloseNotification(true)
	}

	const animationEndHandler = () => {
		if (closeNotification) {
			setCloseNotification(false)
			mutate(undefined)
		}
	}

	if (!data) {
		return null
	}

	if (data) {
		clearTimeout(timeout.current)
		timeout.current = setTimeout(() => {
			if (closeNotification === false) {
				setCloseNotification(true)
			}
			timeout.current = undefined
		}, 3000)
	}

	return (
		<AnimatedMessage
			close={closeNotification}
			onClose={scheduleNotificationClose}
			type={messageType}
			onAnimationEnd={animationEndHandler}
		>
			<Message.Message type={messageType}>{data?.title}</Message.Message>
			{data?.info && <Message.Info>{data.info}</Message.Info>}
		</AnimatedMessage>
	)
}

export default React.memo(PopupNotification)
