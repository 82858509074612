import * as React from 'react'
import AlmaTunnusAuth, { UserObject } from '@almamedia/alma-tunnus-component'
import almaLoginUtils, { loginCallback } from '../../utils/loginUtils'
import config from 'config'
import { useNavigate } from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary'
import { mutate } from 'swr'
import URLS from '../../api/urls'

const isProd = import.meta.env.PROD && import.meta.env.MODE !== 'preview'
almaLoginUtils.onLogin.unshift(() => mutate(URLS.USER))

const AlmaLogin = () => {
	const navigate = useNavigate()
	const redirectUrl = `${window.location.origin}${
		isProd ? '/oma-asiointi' : ''
	}/authcallback`
	const logoutUrl = `${window.location.origin}${isProd ? '/oma-asiointi' : ''}`

	return (
		<ErrorBoundary>
			<AlmaTunnusAuth
				debugMode={import.meta.env.DEV}
				redirectUrl={redirectUrl}
				returnTo={logoutUrl}
				{...config.almaLogin}
				scope='openid profile email create:session'
				realm='Almatunnus'
				loginNotification={false}
				loginCallback={loginCallback(navigate)}
				checkSessionCallback={(result: UserObject) => {
					almaLoginUtils.tunnusAnalytics.sendUserEvent({
						user: result,
					})
					almaLoginUtils.isUserLoggedIn = !!result
				}}
				actions={({
					openAlmaLogin,
					logout,
					checkSession,
					modalOpen,
				}: {
					openAlmaLogin: Function
					logout: (rTo: string) => void
					checkSession: Function
					modalOpen: Function
				}) => {
					almaLoginUtils.openAlmaLogin = openAlmaLogin
					almaLoginUtils.logout = logout
					almaLoginUtils.checkSession = checkSession
					almaLoginUtils.isModalOpen = modalOpen
					if (window.location.hash === '#login') {
						// wait for alma tunnus to initialize (does redirect if called right away)
						setTimeout(openAlmaLogin, 100)
					}
				}}
			/>
		</ErrorBoundary>
	)
}

export default React.memo(AlmaLogin)
