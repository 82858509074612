import React, { useState } from 'react'
import styled from 'styled-components'
import {
	Icon,
	core,
	SecondaryButton,
	Heading,
	Paragraph,
} from '@almamedia/talent-media-component-library'
import copyTexts from '../../assets/copyTexts'

const NotificationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${core.color.status.warning[95]};
	padding: ${core.spacing.large} ${core.spacing.large} 0 ${core.spacing.large};
	margin-bottom: calc(${core.spacing.medium} + ${core.spacing.large});

	@media ${core.mediaquery.max.small} {
		padding: ${core.spacing.medium} ${core.spacing.medium} ${core.spacing.small}
			${core.spacing.medium};
	}
`

const TextWrapper = styled.div`
	display: flex;
	gap: ${core.spacing['x-small']};
	margin-bottom: ${core.spacing['x-small']};
`

const Title = styled(Heading.Subtitle)`
	${core.type.alma.ui.heavy};
	font-size: ${core.font.scale[1]};
	line-height: ${core['line-height'].medium};
	color: ${core.color.primary[10]};
`

const Info = styled(Paragraph)`
	${core.type.alma.ui.normal};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height'].medium};
	color: ${core.color.primary[10]};
	margin: 0;
`

const Button = styled(SecondaryButton)`
	margin: ${core.spacing.medium} 0 ${core.spacing.large};

	@media ${core.mediaquery.max.small} {
		padding: ${core.spacing.medium} ${core.spacing['x-small']};
	}
`

type Props = {
	titleText?: string
	infoText?: string
	hideButton?: boolean
	infoTextWithIcon?: boolean
}

const EmailVerificationNotification = ({
	hideButton,
	titleText,
	infoText,
	infoTextWithIcon,
	...rest
}: Props) => {
	const [buttonState, setButtonState] = useState<
		'loading' | 'clicked' | undefined
	>(undefined)

	async function callEmailVerificationApi() {
		await window.ALMA.tunnus.sendVerificationEmail({
			id: window.ALMA.tunnus.getUser()?.id,
		})
	}

	const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
		setButtonState('loading')
		callEmailVerificationApi()
		setTimeout(() => {
			setButtonState('clicked')
		}, 600)
	}

	return (
		<NotificationWrapper {...rest}>
			{titleText && (
				<TextWrapper>
					<Icon.CoreExclamationCircle
						size='core.size.icon.large'
						color='core.color.primary.10'
					/>
					<Title level='h3'>{titleText}</Title>
				</TextWrapper>
			)}
			{infoTextWithIcon ? (
				<TextWrapper>
					<Icon.CoreExclamationCircle
						size='core.size.icon.large'
						color='core.color.primary.10'
					/>
					<Info>{infoText}</Info>
				</TextWrapper>
			) : (
				<Info>{infoText}</Info>
			)}
			{!hideButton && (
				<Button
					disabled={buttonState === 'loading'}
					loading={buttonState === 'loading'}
					loadingUseMediaColors={buttonState === 'loading'}
					onClick={handleClick}
					icon={buttonState && <Icon.CoreCheck size='core.size.icon.medium' />}
					iconPositionRight
				>
					{buttonState === 'clicked'
						? copyTexts.emailVerificationNotification.buttonTextCompleted
						: copyTexts.emailVerificationNotification.buttonTextPrompt}
				</Button>
			)}
		</NotificationWrapper>
	)
}

export default EmailVerificationNotification
