import {
	core,
	Divider,
	Paragraph,
	PrimaryButton,
	SecondaryButton,
} from '@almamedia/talent-media-component-library'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import useUser from '../../api/user/useUser'
import copyTexts from '../../assets/copyTexts'
import { isKLWGroupUser } from '../../utils/userUtils'
import { logout } from '../../api/login'
import { getUrlToFrontpageBasedOnSubscription } from '../../utils/mediaUtils'
import sessionStorageUtils from '../../utils/sessionStorageUtils'
import { waitForElmById } from '../../utils/DomUtils'
import almaLoginUtils from '../../utils/loginUtils'
import { GreyBackground, StyledHeadline, Wrapper } from './common'
import GALinkEvent from '../common/GALinkEvent'
import { StyledLink } from '../common'
import useActiveSubscriptions from '../../api/subscriptions/useActiveSubscriptions'

const {
	setShowTunnusLoginPrompt,
	setPrepareToRemoveLoginPromptSS,
	removePrepareToRemoveLoginPromptSS,
	setTunnusReturnUrl,
} = sessionStorageUtils

export const ButtonWrapper = styled.div`
	display: grid;
	align-items: center;
	justify-content: center;
	grid-row-gap: ${core.spacing.large};
	margin-top: ${core.spacing['xx-large']};
	margin-bottom: ${core.spacing['xxxx-large']};
	padding: 0 ${core.spacing.medium};

	@media ${core.mediaquery.max['small']} {
		padding: 0 ${core.spacing.large};
	}

	@media ${core.mediaquery.max['x-small']} {
		justify-content: stretch;
	}
`

const GroupUserErrorPage = () => {
	const [showSpinner, setShowSpinner] = useState<boolean>(false)
	const { data: activeSubscriptions } = useActiveSubscriptions()
	const { data: user } = useUser()

	const mediaSubscribedToLink = getUrlToFrontpageBasedOnSubscription(
		activeSubscriptions &&
			activeSubscriptions.map((s) => s.subscription.organization)
	)

	const urlLabel = mediaSubscribedToLink.split('www.')[1].split('/')[0]

	const handleLogout = useCallback(() => {
		if (isKLWGroupUser(user)) {
			/**
			 * Logout request fails if user is logged in with a `klw-` account. So, the login modal is shown in the
			 * group user error page itself without logging the user out. After the user logs in, the user is
			 * redirected back to the frontpage
			 */
			window.ALMA.tunnus.reset()
			setTunnusReturnUrl('/')
			setPrepareToRemoveLoginPromptSS('true')
			almaLoginUtils.openAlmaLogin({ source: 'oma-asiointi' })
			/**
			 * This takes care of clearing the session storage variables if the Alma login opened as a modal
			 */
			if (document) {
				waitForElmById('alma-tunnus-button-login').then(() => {
					removePrepareToRemoveLoginPromptSS()
				})
			}
		} else {
			setShowSpinner(true)
			setShowTunnusLoginPrompt('true')
			logout()
		}
	}, [user])

	return (
		<>
			<GreyBackground>
				<Wrapper>
					<StyledHeadline level='h1'>
						{copyTexts.ErrorPage.groupAnonymousPrompt}
					</StyledHeadline>
					<Paragraph
						css={{
							textAlign: 'center',
							maxWidth: '35rem',
							padding: 0,
							margin: 0,
						}}
					>
						{copyTexts.ErrorPage.groupAnonymousText1}
					</Paragraph>
					<Paragraph
						css={{
							textAlign: 'center',
							maxWidth: '35rem',
							padding: 0,
							marginTop: `${core.spacing.medium}`,
						}}
					>
						{copyTexts.ErrorPage.groupAnonymousText2}
					</Paragraph>
				</Wrapper>
			</GreyBackground>

			<ButtonWrapper>
				<PrimaryButton
					css={`
						@media ${core.mediaquery.min.small} {
							min-width: 35rem;
						}
					`}
					onClick={() => {
						handleLogout()
					}}
					loading={showSpinner}
					disabled={showSpinner}
				>
					{copyTexts['Kirjaudu sisään']}
				</PrimaryButton>

				<Divider
					thickness='core.size.line.thin'
					color='core.color.primary.30'
					label='tai'
					labelColor='core.color.primary.30'
				/>
				<Paragraph
					css={{
						textAlign: 'center',
						maxWidth: '35rem',
						padding: 0,
						margin: 0,
					}}
				>
					{copyTexts.ErrorPage.groupButton2Label}
				</Paragraph>
				<GALinkEvent>
					<StyledLink to={mediaSubscribedToLink}>
						<SecondaryButton
							forwardedAs='div'
							css={`
								@media ${core.mediaquery.min.small} {
									min-width: 35rem;
								}
							`}
						>
							{'Siirry ' + urlLabel}
						</SecondaryButton>
					</StyledLink>
				</GALinkEvent>
			</ButtonWrapper>
		</>
	)
}

export default React.memo(GroupUserErrorPage)
