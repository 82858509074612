import React from 'react'
import styled from 'styled-components'
import {
	core,
	Icon,
	Badge,
	SecondaryButton,
	Heading,
} from '@almamedia/talent-media-component-library'
import { useParams } from 'react-router-dom'
import getInvoiceReceipt from '../../api/invoice/getInvoiceReceipt'
import { Invoice as InvoiceType } from '../../types/subscription'
import useInvoices from '../../api/subscriptions/useInvoices'
import useUser from '../../api/user/useUser'
import useSubscription from '../../api/subscriptions/useSubscription'
import { getMediaSlugs } from '../../utils/mediaUtils'
import {
	formatPrice,
	hasSubscriptionEnded,
} from '../../utils/subscriptionUtils'
import TopBreadCrumbLink from '../common/TopBreadCrumbLink'
import copyTexts from '../../assets/copyTexts'
import { Email } from '../common'
import Head from '../common/Head'

const MAIN_CONTENT_DESKTOP_MAX_WIDTH = '62rem'
const PAGE_MOBILE_MAX_WIDTH = '36rem'

const Wrapper = styled.main`
	margin: 0 auto;
	padding: ${core.spacing['x-large']} ${core.spacing.large}
		${core.spacing['xxxx-large']};
	max-width: ${core.size.template['page-wrapper']};

	@media ${core.mediaquery.max.small} {
		max-width: ${PAGE_MOBILE_MAX_WIDTH};
	}

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing.large} ${core.spacing.medium}
			${core.spacing['xx-large']} ${core.spacing.medium};
	}
`

const HeadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${core.spacing['xx-large']} 0;
	gap: ${core.spacing.medium};
	text-align: center;

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing['x-large']} 0;
		gap: ${core.spacing.small};
	}
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: ${MAIN_CONTENT_DESKTOP_MAX_WIDTH};
	gap: ${core.spacing['xx-large']};
	margin: 0 auto;

	@media ${core.mediaquery.max.small} {
		gap: ${core.spacing['x-large']};
	}
`

const PageHeading = styled.h2`
	${core.type.alma.ui.heavy};
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[3]};
	line-height: ${core['line-height']['x-small']};
	margin: 0;
	padding-bottom: ${core.spacing.small};
	width: 100%;
	border-bottom: ${core.border['x-small']} solid ${core.color.primary[60]};

	@media ${core.mediaquery.max.small} {
		font-size: ${core.font.scale[2]};
		line-height: ${core['line-height'].small};
		align-self: center;
		text-align: center;
	}
`

const OpenInvoices = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${core.spacing.large};
`

const ClosedInvoices = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${core.spacing.large};
`

const InvoiceHeading = styled.h3`
	${core.type.alma.ui.heavy};
	margin: 0;
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[2]};
	line-height: ${core['line-height'].small};
`

const InvoiceCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: ${core.spacing.large};
	border: ${core.border['x-small']} solid ${core.color.primary[10]};
	border-radius: ${core.shape.rounded};
	padding: calc(${core.spacing.large} + ${core.spacing.small})
		${core.spacing.large} calc(${core.spacing.large} + ${core.spacing.small})
		${core.spacing.large};

	@media ${core.mediaquery.max.small} {
		flex-direction: column;
		align-items: start;
		gap: 0;
		padding: ${core.spacing.large} ${core.spacing.medium};
	}
`

const Status = styled.div`
	display: flex;
	gap: ${core.spacing.small};

	@media ${core.mediaquery.max.small} {
		flex-direction: column-reverse;
		align-items: start;
		gap: ${core.spacing.medium};
		margin-bottom: ${core.spacing.large};
	}
`

const Item = styled.div<{ $useSpacing?: boolean }>`
	display: flex;
	gap: ${core.spacing['x-small']};

	@media ${core.mediaquery.max.small} {
		${({ $useSpacing }) =>
			$useSpacing &&
			`
        margin-bottom: ${core.spacing['xx-small']};
      `}
	}
`

const Label = styled.span`
	${core.type.alma.ui.heavy};
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height'].medium};
`

const Value = styled.span`
	${core.type.alma.ui.normal};
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height'].medium};
`

const Button = styled(SecondaryButton)<{ $isHidden: boolean }>`
	${({ $isHidden }) =>
		$isHidden &&
		`
      visibility: hidden;
    `}

	@media ${core.mediaquery.max.small} {
		width: 100%;
		margin-top: ${core.spacing.large};
		${({ $isHidden }) =>
			$isHidden &&
			`
        display: none;
      `}
	}
`

const handleClick = (id: string, invoiceNumber: string) => {
	getInvoiceReceipt(id, invoiceNumber)
}

const getInvoiceCard = (invoice: InvoiceType, isPaid: boolean) => (
	<InvoiceCard key={invoice.id}>
		<Status>
			<Heading.Subtitle size='medium' level='span'>
				{invoice.invoiceNumber}
			</Heading.Subtitle>
			<Badge
				backgroundColor={
					isPaid ? 'core.color.status.success.95' : 'core.color.primary.10'
				}
				textColor={
					isPaid ? 'core.color.status.success.20' : 'core.color.primary.100'
				}
				borderWidth={isPaid ? 'x-small' : undefined}
				icon={
					isPaid ? (
						<Icon.CoreCheckCircle
							size='core.size.icon.medium'
							color='core.color.status.success.20'
						/>
					) : undefined
				}
				text={isPaid ? 'Maksettu' : 'Avoin'}
				fontSize='core.font.scale.0'
			/>
		</Status>
		<Item $useSpacing>
			<Label>{isPaid ? 'Veloitettu summa' : 'Seuraava veloitus'}</Label>
			<Value>{formatPrice(invoice.items[0].priceIncludingVat)}</Value>
		</Item>
		<Item>
			<Label>Veloituspäivä</Label>
			<Value>{new Date(invoice.billingDate).toLocaleDateString('fi-fi')}</Value>
		</Item>
		{invoice.invoiceNumber ? (
			<Button
				$isHidden={!isPaid}
				onClick={() => handleClick(invoice.id, invoice.invoiceNumber)}
			>
				Lataa kuitti
			</Button>
		) : (
			<Item />
		)}
	</InvoiceCard>
)

const Invoice = () => {
	const { id } = useParams()
	const { data: user } = useUser()
	const { data: subscriptionData } = useSubscription(id)
	const {
		data: { open, paid },
	} = useInvoices(id)
	const mediaSlugs = getMediaSlugs(subscriptionData?.subscription.organization)
	const subscriptionIsActive = subscriptionData
		? !hasSubscriptionEnded(subscriptionData)
		: false
	const subscriptionLink = subscriptionIsActive
		? `../voimassa-olevat-tilaukset/${id}`
		: `../paattyneet-tilaukset/${id}`

	return (
		<Wrapper>
			<Head title='Maksukaudet ja kuitit - oma asiointi - Alma Media' />
			<TopBreadCrumbLink
				to={subscriptionLink}
				text={copyTexts.subscriptionManagementPage['Tilauksen hallinta']}
				noMargin
			/>
			<HeadingContainer>
				<Heading.Subtitle size='medium, @min.small=x-large'>
					{mediaSlugs?.siteCase} digitilaus
				</Heading.Subtitle>
				{user && <Email>{user.email}</Email>}
			</HeadingContainer>
			<ContentWrapper>
				<PageHeading>Maksukaudet ja kuitit</PageHeading>
				{open && open?.length !== 0 && (
					<OpenInvoices>
						<>
							<InvoiceHeading>Avoimet laskut</InvoiceHeading>
							{open?.map((p) => getInvoiceCard(p, false))}
						</>
					</OpenInvoices>
				)}
				{paid && paid?.length !== 0 && (
					<ClosedInvoices>
						<>
							<InvoiceHeading>Maksetut laskut</InvoiceHeading>
							{paid.map((p) => getInvoiceCard(p, true))}
						</>
					</ClosedInvoices>
				)}
			</ContentWrapper>
		</Wrapper>
	)
}

export default Invoice
