import useSWR from 'swr'
import { parseResponse } from '..'
import { Subscription } from '../../types/subscription'
import { isGroupAnonymous, isUserLoggedIn } from '../../utils/userUtils'
import URLS from '../urls'
import useUser from '../user/useUser'

const fetcher = (url: string) => fetch(url).then((res) => parseResponse(res))

export default () => {
	const { data, isLoading, error } = useUser()
	/**
	 * Fetch customer subscriptions data only when user is logged in.
	 */
	const shouldFetch =
		!isLoading &&
		!error &&
		data &&
		isUserLoggedIn(data) &&
		!isGroupAnonymous(data)
	return useSWR<Subscription[]>(
		shouldFetch ? URLS.CUSTOMER_SUBSCRIPTIONS : null,
		fetcher
	)
}
