import styled, { css } from 'styled-components'
import { core } from '@almamedia/talent-media-component-library'

type StyleProps = {
	invertedFocus?: boolean
}

const highLight = css<StyleProps>`
	background: ${({ invertedFocus }) =>
		invertedFocus ? core.color.primary[100] : core.color.primary[10]};
	color: ${({ invertedFocus }) =>
		invertedFocus ? core.color.primary[10] : core.color.primary[100]};
`

const TeaserLink = styled.a<StyleProps>`
	position: static;
	&:before {
		position: absolute;
		display: block;
		content: '';
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
	}
	&:focus-visible {
		outline: none;
		> * {
			display: inline;
			${highLight}
			> span {
				color: ${({ invertedFocus }) =>
					invertedFocus ? core.color.primary[10] : core.color.primary[85]};
			}
		}
	}
`

export default TeaserLink
