import { CustomWindow } from '../types'
import { mutate } from 'swr'
import GLOBALSTORAGE from '../api/globalstorage'
import { ShortSlug } from '../types/media'

declare let window: CustomWindow

export type OfferField = {
	date: string
	inOffer: boolean
	name: string
}

export type ExposedField = {
	field: string
	value: string[]
}

export type UserSelligentData = {
	CRMFields: any[]
	exposedFields: ExposedField[]
	isCRMIdentified: boolean
	isCustomIdentified: boolean
	offers: OfferField[]
	profileFields: any[]
	tags: any[]
}

// Get all segments that are active or enabled through URL parameter
export const getCurrentActiveExposedFields = (): ExposedField[] => {
	if (typeof window !== 'undefined') {
		const urlParams = new URLSearchParams(window?.location?.search)
		const exposedFields = window?.BT?.getExposedFields()?.reduce(
			(prev: ExposedField[], cur: ExposedField) => {
				if (urlParams.get(cur.field) !== null) {
					return [...prev, { ...cur, value: ['true'] }]
				}
				if (cur.value[0] === 'true') {
					return [...prev, cur]
				}
				return prev
			},
			[]
		)

		return exposedFields || []
	}

	return []
}

// Get all offers that are active or enabled through URL parameter
export const getCurrentActiveOffers = (): OfferField[] => {
	if (typeof window !== 'undefined') {
		const urlParams = new URLSearchParams(window?.location?.search)
		const offers = window?.BT?.getOffers()?.reduce(
			(prev: OfferField[], cur: OfferField) => {
				if (urlParams.get(cur.name) !== null) {
					return [...prev, { ...cur, inOffer: true }]
				}
				if (cur.inOffer) {
					return [...prev, cur]
				}
				return prev
			},
			[]
		)

		return offers || []
	}

	return []
}

export const sendChangeEventSelligent = () => {
	try {
		const customIdentifier =
			window.digitalData?.user[0].profile[0].profileInfo.almaIDHash ||
			window.ALMA.tunnus.getUser()?.almaIdHash

		if (window.wa && customIdentifier) {
			window.wa.bt_queue.push({
				customIdentifier,
				isEvent: false,
				isTargeting: true,
				async: true,
				exposedFields: [],
				finishedCallback: (data: UserSelligentData) =>
					mutate(GLOBALSTORAGE.SELLIGENT, {
						...data,
						exposedFields: getCurrentActiveExposedFields(),
						offers: getCurrentActiveOffers(),
					}),
			})
		}
	} catch (error) {
		console.log(error)
	}
}

type CancellationTag =
	| 'AP_NOC'
	| 'KL_NOC'
	| 'TE_NOC'
	| 'TT_NOC'
	| 'TV_NOC'
	| 'MB_NOC'
	| 'US_NOC'
export const getCancellationTag = (
	shortSlug: ShortSlug
): CancellationTag | undefined => {
	switch (shortSlug) {
		case 'ap':
			return 'AP_NOC'
		case 'kl':
			return 'KL_NOC'
		case 'te':
			return 'TE_NOC'
		case 'tt':
			return 'TT_NOC'
		case 'tv':
			return 'TV_NOC'
		case 'mb':
			return 'MB_NOC'
		case 'us':
			return 'US_NOC'
		default:
			return undefined
	}
}
