import * as React from 'react'
import styled from 'styled-components'
import { Heading } from '@almamedia/talent-media-component-library'
import useUser from '../../api/user/useUser'
import loginUtils from '../../utils/loginUtils'
import { isUserLoggedIn } from '../../utils/userUtils'
import { useNavigate } from 'react-router-dom'
import sessionStorageUtils from '../../utils/sessionStorageUtils'
const { getTunnusReturnUrl } = sessionStorageUtils

const Section = styled.section`
	margin: 0 auto;
	padding: 1.875rem;
	max-width: 600px;
	line-height: 1.4;
`

const AlmaHostedLoginLander = () => {
	const { data, isLoading } = useUser()
	const hasTujuLogin = isUserLoggedIn(data)
	const navigate = useNavigate()

	/**
	 * This performs additional checking and redirect if the `doRedirect` fails to run
	 * or if the user goes to the `/authCallback` route directly. It is inside a use
	 * effect because we want to run the operation as a side-effect of certain
	 * parameters.
	 *
	 * Often this component will render after a valid alma session is created, but before
	 * a valid tuju session is created. So, additional checks are performed to check
	 * that a valid session exists in both tuju and alma or not, before navigating the
	 * user.
	 */
	React.useEffect(() => {
		if (!isLoading) {
			loginUtils.checkSession()?.then((res: false | Object) => {
				if (res && hasTujuLogin) {
					const returnUrlFromSession = getTunnusReturnUrl()
					navigate(returnUrlFromSession || '/')
				}
				if (!res && !hasTujuLogin) {
					navigate('/')
				}
			})
		}
	}, [isLoading, hasTujuLogin])

	return (
		<Section>
			<Heading.Headline size='medium'>
				Kirjaudutaan Alma Tunnuksella...
			</Heading.Headline>
		</Section>
	)
}

export default AlmaHostedLoginLander
