import React from 'react'
import styled from 'styled-components'
import {
	core,
	Icon,
	Heading,
	Link,
} from '@almamedia/talent-media-component-library'
import GALinkEvent from '../common/GALinkEvent'

const Wrapper = styled.div`
	background: ${core.color.primary[10]};
`

const Content = styled.div`
	max-width: 75rem;
	width: 100%;
	margin: 0 auto;
	padding: ${core.spacing['xx-large']} ${core.spacing.large}
		${core.spacing['xxx-large']} ${core.spacing.large};

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing['x-large']} ${core.spacing.medium}
			${core.spacing['xx-large']} ${core.spacing.medium};
	}
`

const Subtitle = styled(Heading.Subtitle)`
	color: ${core.color.primary[100]};
	margin-bottom: ${core.spacing['x-large']};
`

const Medias = styled.div`
	display: flex;
	gap: ${core.spacing.large};

	@media ${core.mediaquery.max.small} {
		flex-direction: column;
		gap: ${core.spacing['x-large']};
	}

	@media ${core.mediaquery.max['x-small']} {
		gap: ${core.spacing.large};
	}
`

const Media = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: ${core.spacing.small};
`

const MediaTitle = styled.h5`
	${core.type.alma.ui.heavy};
	font-size: ${core.font.scale[1]};
	line-height: ${core['line-height'].medium};
	color: ${core.color.primary[100]};
	margin: 0;
`

const StyledLink = styled(Link)`
	color: ${core.color.primary[100]};
	word-break: break-word;

	> span:first-of-type {
		display: inline-flex;
		align-self: center;
	}

	:hover {
		outline: none;

		path {
			fill: ${core.color.primary[100]};
		}
	}

	:visited {
		color: ${core.color.primary[100]};
	}

	:focus-visible {
		outline-color: ${core.color.primary[100]};
	}
`

const SelfServiceFooter = () => {
	return (
		<Wrapper>
			<Content>
				<Subtitle size='medium'>Asiakaspalvelu</Subtitle>
				<Medias>
					<Media>
						<MediaTitle>Alma Talent Lehdet</MediaTitle>
						<GALinkEvent>
							<StyledLink
								href='mailto:kl.asiakaspalvelu@almamedia.fi'
								icon={
									<Icon.CoreEnvelope
										color='alma.color.primary.60'
										size='core.size.icon.medium'
									/>
								}
								iconPosition='left'
							>
								kl.asiakaspalvelu@almamedia.fi
							</StyledLink>
						</GALinkEvent>
						<GALinkEvent>
							<StyledLink
								href='https://www.almamedia.fi/yhteystiedot/asiakaspalvelu/'
								icon={
									<Icon.CoreComment
										color='alma.color.primary.60'
										size='core.size.icon.medium'
									/>
								}
								iconPosition='left'
							>
								Chat-palvelu (arkisin klo 9:00 - 15:30)
							</StyledLink>
						</GALinkEvent>
					</Media>
					<Media>
						<MediaTitle>Iltalehti</MediaTitle>
						<GALinkEvent>
							<StyledLink
								href='mailto:digitilaus@iltalehti.fi'
								icon={
									<Icon.CoreEnvelope
										color='alma.color.primary.60'
										size='core.size.icon.medium'
									/>
								}
								iconPosition='left'
							>
								digitilaus@iltalehti.fi
							</StyledLink>
						</GALinkEvent>
						<GALinkEvent>
							<StyledLink
								href='https://www.almamedia.fi/yhteystiedot/iltalehti-plus/'
								icon={
									<Icon.CoreComment
										color='alma.color.primary.60'
										size='core.size.icon.medium'
									/>
								}
								iconPosition='left'
							>
								Chat-palvelu (arkisin klo 9:30 - 15:30)
							</StyledLink>
						</GALinkEvent>
					</Media>
					<Media>
						<MediaTitle>Alma Talent Kirjat</MediaTitle>
						<GALinkEvent>
							<StyledLink
								href='mailto:sisaltopalvelut@almatalent.fi'
								icon={
									<Icon.CoreEnvelope
										color='alma.color.primary.60'
										size='core.size.icon.medium'
									/>
								}
								iconPosition='left'
							>
								sisaltopalvelut@almatalent.fi
							</StyledLink>
						</GALinkEvent>
					</Media>
				</Medias>
			</Content>
		</Wrapper>
	)
}

export default SelfServiceFooter
