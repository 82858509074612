import useGetSubscribersOnlyArticles from './useGetSubscribersOnlyArticles'
import useGetIltalehtiPlusArticles from './useGetIltalehtiPlusArticles'
import getMatejRecommendations from './getMatejRecommendations'
import { isMatejEnabled } from '../../utils/consentUtils'
import useConsent from '../consent/useConsent'
import {
	ApiListData,
	ArticleTeaser,
	FeedListData,
	FeedListTeaserData,
} from '../../types/teaser'

/* First priority is to show Matej results. Fallback to showing "Star articles" ie. 
  Subscribers only for Talent medias and Iltalehti Plus for Iltalehti if Matej returns < 3 items. */

export default (mediaSlug: string) => {
	const {
		data: matejData,
		isLoading: isMatejDataLoading,
		error: matejDataError,
	} = getMatejRecommendations(4, mediaSlug) || {}

	const {
		data: subscribersOnlyData,
		isLoading: subscribersOnlyDataLoading,
		error: subscribersOnlyDataError,
	} = useGetSubscribersOnlyArticles(mediaSlug) || {}

	const {
		data: ilPlusData,
		isLoading: ilPlusDataLoading,
		error: ilPlusDataError,
	} = useGetIltalehtiPlusArticles() || {}

	const { data: consent } = useConsent()
	const matejIsEnabled = isMatejEnabled(consent)
	const hasEnoughMatejArticles =
		matejIsEnabled && matejData !== undefined && matejData?.items?.length >= 3

	const isLoading =
		isMatejDataLoading ||
		(mediaSlug === 'il' ? ilPlusDataLoading : subscribersOnlyDataLoading)
	const error =
		matejDataError ||
		(mediaSlug === 'il' ? ilPlusDataError : subscribersOnlyDataError)

	let data: ArticleTeaser[] | FeedListTeaserData[] | undefined

	if (mediaSlug === 'il' && !hasEnoughMatejArticles && !ilPlusDataError) {
		data = ilPlusData
	} else if (hasEnoughMatejArticles && !matejDataError) {
		data = matejData?.items
	} else {
		data = (subscribersOnlyData as FeedListData)?.['Article']?.slice(0, 4)
	}

	return {
		data,
		isLoading,
		error,
	}
}
