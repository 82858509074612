import { ArticleImageNode, Crop, Crops } from '../types/teaser'

/**
 * Round and convert to string
 */
const fix = (input: number | string): string =>
	Math.max(0, Math.round(parseInt(input as string, 10))).toString()

const adjustRatios: { [key: string]: number | undefined } = {
	default: 4 / 3,
	'4:3': 4 / 3,
	'16:9': 16 / 9,
}

const getScaleString = (original: string, cropName: string) => {
	const [w] = original.split('x')
	const cropRatio = adjustRatios[cropName] || 4 / 3
	return `${w}x${Math.round(parseInt(w) / cropRatio)}`
}

/**
 * Adjusts default crop if the requested crop is not available
 */
function adjustCrop(
	defaultCrop: Crop | undefined,
	cropName = '16:9'
): Crop | undefined {
	if (defaultCrop === undefined) {
		return undefined
	}

	const ratio = adjustRatios[cropName]

	if (ratio === undefined) {
		return undefined
	}

	const x = parseInt(defaultCrop.x, 10)
	const y = parseInt(defaultCrop.y, 10)
	const width = parseInt(defaultCrop.width, 10)
	const height = parseInt(defaultCrop.height, 10)

	if (width / height < ratio) {
		const newHeight = width / ratio
		return {
			x: fix(x),
			y: fix(y),
			width: fix(width),
			height: fix(newHeight),
		}
	}
	const newWidth = ratio / height
	const newX = x + width / 2 - newWidth / 2
	return {
		x: fix(newX),
		y: fix(y),
		width: fix(newWidth),
		height: fix(height),
	}
}

/**
 * Convert crop to a string to be passed to the image scaler service
 * @see https://github.com/willnorris/imageproxy for scaling options
 */
export function scaleFromCrops(
	/** Crops object from the article/teaser */
	crops: Crops | undefined,
	/** Scale of the wanted image */
	scale: string,
	/** Crop key name from the `crops` object. Default = 4:3 */
	cropName = 'default',
	attributes?: ArticleImageNode['attributes']
) {
	const isPortrait =
		attributes && parseInt(attributes.height) > parseInt(attributes.width)

	if (!crops) {
		return isPortrait ? getScaleString(scale, cropName) : scale
	}

	const crop = crops[cropName] || adjustCrop(crops.default, cropName)
	if (!crop) {
		return isPortrait ? getScaleString(scale, cropName) : scale
	}

	const { x, y, width, height } = crop

	if (x === undefined || y === undefined || !width || !height) {
		return scale
	}

	return `cx${fix(x)},cy${fix(y)},cw${fix(width)},ch${fix(height)},${scale}`
}
