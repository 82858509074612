import React from 'react'
import styled from 'styled-components'
import { core } from '@almamedia/talent-media-component-library'
import FamilySubscriptionSection from './FamilySubscriptionSection'
import SubscriptionAndBillingInfoLeft from './SubscriptionAndBillingInfoLeft'
import SubscriptionAndBillingInfoRight from './SubscriptionAndBillingInfoRight'
import { useParams } from 'react-router-dom'
import useSubscription from '../../api/subscriptions/useSubscription'
import { hasFamilySubscription } from '../../utils/subscriptionUtils'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: ${core.spacing['xx-large']};
	margin-bottom: ${core.spacing['xx-large']};

	@media ${core.mediaquery.max.small} {
		flex-direction: column;
	}

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing['x-large']};
		padding: 0 ${core.spacing.medium};
	}
`

const SectionWrapper = styled.div`
	width: 100%;
	text-align: start;

	@media ${core.mediaquery.min.medium} {
		max-width: 48%;
	}
`

const SubscriptionDataTable = () => {
	const { id } = useParams()
	const { data } = useSubscription(id)

	return (
		<>
			<Wrapper>
				{data?.subscription && (
					<>
						<SubscriptionAndBillingInfoLeft />
						<SubscriptionAndBillingInfoRight />
					</>
				)}
			</Wrapper>
			<Wrapper>
				{hasFamilySubscription(data) && (
					<SectionWrapper>
						<FamilySubscriptionSection />
					</SectionWrapper>
				)}
			</Wrapper>
		</>
	)
}

export default SubscriptionDataTable
