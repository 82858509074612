import React from 'react'
import { Navigate } from 'react-router-dom'
import useUser from '../../api/user/useUser'
import sessionStorageUtils from '../../utils/sessionStorageUtils'
import { isGroupAnonymous } from '../../utils/userUtils'

const { getTunnusReturnUrl, removeTunnusReturnUrl, setRedirectUrl } =
	sessionStorageUtils

const ProtectedRouteGroup = ({
	protectGroupUserErrorPage,
	children,
}: {
	protectGroupUserErrorPage?: boolean
	children: JSX.Element
}) => {
	const { data: user } = useUser()
	const userIsGroupAnonymous = isGroupAnonymous(user)

	if (!protectGroupUserErrorPage && userIsGroupAnonymous) {
		setRedirectUrl('/ryhmakayttaja')
		return <Navigate to='/ryhmakayttaja' />
	}

	if (protectGroupUserErrorPage && !userIsGroupAnonymous) {
		const returnUrl = getTunnusReturnUrl() || '/'
		removeTunnusReturnUrl()
		setRedirectUrl(returnUrl)
		return <Navigate to={returnUrl} />
	}

	return children
}

export default ProtectedRouteGroup
