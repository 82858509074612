import { useNavigate, useSearchParams } from 'react-router-dom'
import copyTexts from '../../assets/copyTexts'
import sessionStorageUtils from '../../utils/sessionStorageUtils'
import usePopupNotifications from '../../api/popupnotifications/usePopupNotifications'
import { useEffect } from 'react'
const { getCardUpdateInfo, removeCardUpdateInfo } = sessionStorageUtils

const { paymentCardUpdate } = copyTexts

function PaymentCardRedirect() {
	const navigate = useNavigate()
	const { mutate } = usePopupNotifications()
	const [searchParams] = useSearchParams()
	const hasErrorFromServer = searchParams.has('error')
	const orderIdFromParam = searchParams.get('orderId')
	const randomTokenFromParam = searchParams.get('orderRandomToken')
	const cardInfoFromSessionStorage = getCardUpdateInfo()
	const parsedCardInfoFromSessionStorage =
		cardInfoFromSessionStorage && JSON.parse(cardInfoFromSessionStorage)
	const { orderId, orderRandomToken, subscriptionId, returnPath } =
		parsedCardInfoFromSessionStorage || {}
	const doParamsMatch =
		orderIdFromParam === orderId && randomTokenFromParam === orderRandomToken
	const isFailure = !doParamsMatch || hasErrorFromServer

	const notificationTitle = !isFailure
		? paymentCardUpdate.success.title
		: paymentCardUpdate.error.title
	const notificationInfo = !isFailure ? undefined : paymentCardUpdate.error.info

	/**
	 * We intend to navigate to the url after the mutation is made. So, we use `navigate` hook instead of `Navigate` component.
	 * And `navigate` call is suggested to be made inside a useEffect, as it might lead to unnecessary memory leaks if a
	 * navigation is made while the component is rendered.
	 */
	useEffect(() => {
		mutate({
			title: notificationTitle,
			info: notificationInfo,
			isFailure: isFailure,
		})
		removeCardUpdateInfo()
		navigate(returnPath)
	}, [
		isFailure,
		mutate,
		navigate,
		notificationInfo,
		notificationTitle,
		subscriptionId,
		returnPath,
	])

	return null
}

export default PaymentCardRedirect
