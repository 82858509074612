import styled from 'styled-components'
import { core, Types } from '@almamedia/talent-media-component-library'
import { getCssVar } from '@almamedia/talent-media-component-library/dist/utils/objectUtils'
import { Link } from 'react-router-dom'

type PaddingProps = {
	top?: Types.Spacing
	right?: Types.Spacing
	bottom?: Types.Spacing
	left?: Types.Spacing
}

type ResponsiveProps = {
	paddingMaxLarge?: PaddingProps
	paddingMaxMedium?: PaddingProps
	paddingMaxSmall?: PaddingProps
	paddingMaxXSmall?: PaddingProps
}

export const Email = styled.p<ResponsiveProps>`
	${core.type.alma.ui.medium};
	color: ${core.color.primary[10]};
	line-height: ${core['line-height'].medium};
	font-size: ${core.font.scale[2]};
	margin: 0;
	text-align: center;
	padding: ${(p) => `
    ${getCssVar(p.paddingMaxMedium?.top) || 0} ${
		getCssVar(p.paddingMaxMedium?.right) || 0
	} 
    ${getCssVar(p.paddingMaxMedium?.bottom) || 0} ${
		getCssVar(p.paddingMaxMedium?.left) || 0
	}
  `};

	@media ${core.mediaquery.max.small} {
		padding: ${(p) => `
      ${getCssVar(p.paddingMaxSmall?.top) || 0} ${
			getCssVar(p.paddingMaxSmall?.right) || 0
		} 
      ${getCssVar(p.paddingMaxSmall?.bottom) || 0} ${
			getCssVar(p.paddingMaxSmall?.left) || 0
		}
    `};
	}

	@media ${core.mediaquery.max['x-small']} {
		${core.type.alma.ui.normal};
		font-size: ${core.font.scale[-1]};
		padding: ${(p) => `
      ${getCssVar(p.paddingMaxXSmall?.top) || 0} ${
			getCssVar(p.paddingMaxXSmall?.right) || 0
		} 
      ${getCssVar(p.paddingMaxXSmall?.bottom) || 0} ${
			getCssVar(p.paddingMaxXSmall?.left) || 0
		}
    `};
	}
`

/**
 * To be used as a wrapper for buttons for handling (innner) navigation.
 *
 * @remarks
 * Note: when used as a wrapper, set tabIndex={-1} to the child element.
 */
export const StyledLink = styled(Link)<{ $noOutlineOffset?: boolean }>`
	display: block;
	text-decoration: none;

	:focus-visible {
		outline: ${core.outline.medium} solid ${core.color.primary[10]};
		outline-offset: 0;
		outline-offset: ${({ $noOutlineOffset }) =>
			$noOutlineOffset ? 0 : core['outline-offset'].small};
		border-radius: ${core.shape.rounded};
	}

	:disabled {
		cursor: default;
		color: ${core.color.primary[60]};
	}

	button,
	div {
		width: 100%;
	}
`

/**
 * To be used as a router link ie. "Go Back"
 *
 * @remarks
 * Note: takes Design System Link with icon as a child:
 *
 * <StyledRouterLink to='/'>
 *		<DSLink
 *			as='span'
 *			icon={<Icon.CoreArrowLeft size='core.size.icon.small' />}
 *			iconPosition='left'
 *				>{content}
 *		</DSLink>
 * </StyledRouterLink>
 */
export const StyledRouterLink = styled(Link)`
	display: flex;
	text-decoration: none;
`

export const InfoBadge = styled.div<{
	isCardExpiring?: boolean
}>`
	${core.type.alma.ui.normal};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height'].medium};
	background-color: ${({ isCardExpiring }) =>
		isCardExpiring ? core.color.status.warning[95] : core.color.primary[95]};
	display: flex;
	align-items: flex-start;
	gap: ${core.spacing['xx-small']};
	padding: ${core.spacing['x-small']} ${core.spacing['x-small']}
		${core.spacing.small} ${core.spacing['x-small']};
	margin-top: ${core.spacing.small};
	width: fit-content;
`

export const InfoBadgeIcon = styled.i`
	display: flex;
	height: calc(${core['line-height'].medium} * ${core.font.scale[0]});
	align-items: center;
`
