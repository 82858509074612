import useCustomerSubscription from './useCustomerSubscription'

export default (id?: string) => {
	const { data, isLoading, error } = useCustomerSubscription()
	const reconsiderPrice = data?.find(
		(d) => d.subscription.id === id
	)?.reconsiderPrice

	return {
		data: reconsiderPrice,
		isLoading,
		error,
	}
}
