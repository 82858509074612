import { core, Loader } from '@almamedia/talent-media-component-library'
import * as React from 'react'
import styled from 'styled-components'

const Background = styled.div`
	background: ${core.color.primary[100]};
	opacity: ${core.opacity[80]};
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: ${core['z-index'].pagetop};
`

const StyledLoader = styled(Loader)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: ${core['z-index'].overlay};
`

const LoadingOverlay = ({ loading }: { loading: boolean }) => {
	if (!loading) return null

	return (
		<>
			<Background />
			<StyledLoader text='' size='core.size.target.xx-large' />
		</>
	)
}

export default LoadingOverlay
