import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
	title: string
	description?: string
}

export default function Head({ title, description }: Props) {
	return (
		<Helmet>
			<title>{title}</title>
			{description && <meta name='description' content={description} />}
		</Helmet>
	)
}
