import React from 'react'
import config from 'config'
import { createBrowserRouter } from 'react-router-dom'
import App from '../App'
import FrontPage from '../components/frontpage/FrontPage'
import ErrorPage from '../components/errorpage/ErrorPage'
import GroupUserErrorPage from '../components/errorpage/GroupUserErrorPage'
import SubscriptionsLayout from '../components/subscriptions/SubscriptionsLayout'
import Subscriptions from '../components/subscriptions/Subscriptions'
import SubscriptionManagementPage from '../components/subscriptionmanagementpage/SubscriptionManagementPage'
import PaymentCardRedirect from '../components/subscriptionmanagementpage/PaymentCardRedirect'
import Invoice from '../components/invoice/Invoice'
import AlmaHostedLoginLander from '../components/login/AlmaHostedLoginLander'
import Profile from '../components/profile/Profile'
import ProtectedRoute from '../components/common/ProtectedRoute'
import CancellationLayout from '../components/cancellation/CancellationLayout'
import CancellationBenefits from '../components/cancellation/CancellationBenefits'
import CancellationSurvey from '../components/cancellation/CancellationSurvey'
import CancellationOffer from '../components/cancellation/CancellationOffer'
import CancellationConfirmation from '../components/cancellation/CancellationConfirmation'
import ProtectedRouteGroup from '../components/common/ProtectedRouteGroup'
import CancellationFamilyReminder from '../components/cancellation/CancellationFamilyReminder'
import sessionStorageUtils from '../utils/sessionStorageUtils'
import CancellationAnchor from '../components/cancellation/CancellationAnchor'

const {
	removeShowTunnusLoginPrompt,
	getCardUpdateInfo,
	getPrepareToRemoveLoginPromptSS,
	removePrepareToRemoveLoginPromptSS,
} = sessionStorageUtils

export const router = createBrowserRouter(
	[
		{
			element: <App />,
			children: [
				{
					path: '/',
					element: (
						<ProtectedRouteGroup>
							<FrontPage />
						</ProtectedRouteGroup>
					),
					loader: () => {
						if (getPrepareToRemoveLoginPromptSS() === 'true') {
							removeShowTunnusLoginPrompt()
							removePrepareToRemoveLoginPromptSS()
						}
						return null
					},
				},
				{
					element: (
						<ProtectedRoute>
							<SubscriptionsLayout />
						</ProtectedRoute>
					),
					children: ['voimassa-olevat-tilaukset', 'paattyneet-tilaukset'].map(
						(p) => ({
							path: p,
							element: (
								<Subscriptions hasEnded={p === 'paattyneet-tilaukset'} />
							),
						})
					),
				},
				...['voimassa-olevat-tilaukset', 'paattyneet-tilaukset'].map((p) => ({
					path: `${p}/:id`,
					element: (
						<ProtectedRoute>
							<SubscriptionManagementPage parentUrl={p} />
						</ProtectedRoute>
					),
				})),
				{
					path: 'tilaukset/:id/maksut',
					element: (
						<ProtectedRoute>
							<Invoice />
						</ProtectedRoute>
					),
				},
				{
					element: <CancellationLayout />,
					children: [
						{
							path: 'tilaukset/:id/peruutus',
							element: (
								<ProtectedRoute>
									<CancellationBenefits />
								</ProtectedRoute>
							),
						},
						{
							path: 'tilaukset/:id/peruutus-kysely',
							element: (
								<ProtectedRoute
									customProtection={(state) =>
										!state?.prevPath?.endsWith('/peruutus') &&
										state?.prevPath?.indexOf('/voimassa-olevat-tilaukset/') ===
											-1
									}
								>
									<CancellationSurvey />
								</ProtectedRoute>
							),
						},
						{
							path: 'tilaukset/:id/peruutus-tarjous',
							element: (
								<ProtectedRoute
									customProtection={(state) =>
										!state?.prevPath?.endsWith('/peruutus-kysely')
									}
								>
									<CancellationOffer />
								</ProtectedRoute>
							),
						},
						{
							path: 'tilaukset/:id/peruutus-ankkuri',
							element: (
								<ProtectedRoute
									customProtection={(state) =>
										!state?.prevPath?.endsWith('/peruutus-kysely')
									}
								>
									<CancellationAnchor />
								</ProtectedRoute>
							),
						},
						{
							path: 'tilaukset/:id/peruutus-perhe',
							element: (
								<ProtectedRoute
									customProtection={(state) =>
										!state?.prevPath?.endsWith('/peruutus-kysely')
									}
								>
									<CancellationFamilyReminder />
								</ProtectedRoute>
							),
						},
						{
							path: 'tilaukset/:id/peruutus-vahvistus',
							element: (
								<ProtectedRoute
									customProtection={(state) =>
										!state?.prevPath?.endsWith('/peruutus-tarjous') &&
										!state?.prevPath?.endsWith('/peruutus-perhe') &&
										!state?.prevPath?.endsWith('/peruutus-kysely')
									}
								>
									<CancellationConfirmation />
								</ProtectedRoute>
							),
						},
					],
				},
				{
					path: 'authcallback',
					element: <AlmaHostedLoginLander />,
				},
				{
					path: 'maksukortti-paivitetty',
					element: (
						<ProtectedRoute customProtection={() => !getCardUpdateInfo()}>
							<PaymentCardRedirect />
						</ProtectedRoute>
					),
				},
				{
					path: 'omat-tiedot',
					element: (
						<ProtectedRoute>
							<Profile />
						</ProtectedRoute>
					),
				},
				{
					path: 'ryhmakayttaja',
					element: (
						<ProtectedRouteGroup protectGroupUserErrorPage>
							<GroupUserErrorPage />
						</ProtectedRouteGroup>
					),
				},
				{
					path: '*',
					element: (
						<ProtectedRouteGroup>
							<ErrorPage />
						</ProtectedRouteGroup>
					),
				},
			],
		},
	],
	{
		basename: config.baseUrl,
	}
)
