import URLS from './urls'

export function parseResponse<T = any>(response: Response): Promise<T> {
	const parser = (response.headers.get('Content-Type') || '').includes(
		'application/json'
	)
		? 'json'
		: 'text'
	const parsedResponse = parser === 'json' ? response.json() : response.text()

	/**
	 * If user is unauthorized, then they are not logged in. In that case, we don't want to
	 * throw an error as it is a valid case that the user may land on the site without
	 * logging in.
	 */
	if (
		!response.ok &&
		!(response.status === 401 && response.url.includes(URLS.USER))
	) {
		throw new Error('An error occurred while fetching the data.')
	}
	return parsedResponse
}

const FETCH_TIMEOUT = 20000

const setFetchAbortTimer = () => {
	const controller = new AbortController()
	const timer = setTimeout(() => {
		controller.abort()
	}, FETCH_TIMEOUT)
	return { signal: controller.signal, timer }
}

export async function post(
	input: RequestInfo,
	body: string | object = '',
	headers?: Record<string, string>
) {
	const { signal, timer } = setFetchAbortTimer()
	return fetch(input, {
		signal,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...headers,
		},
		mode: 'cors',
		credentials: 'include',
		method: 'POST',
		body: typeof body === 'string' ? body : JSON.stringify(body),
	}).catch((e) => {
		if (e.name === 'AbortError') {
			console.log(`[timeout] GET ${input} (${FETCH_TIMEOUT}ms)`)
			clearTimeout(timer)
		}
		return Promise.reject(e)
	})
}
