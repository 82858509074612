import React from 'react'
import styled from 'styled-components'
import {
	core,
	spacer,
	MetaRow,
	Teaser,
} from '@almamedia/talent-media-component-library'
import { ArticleTeaser, FeedListTeaserData } from '../../../types/teaser'
import TeaserLink from './TeaserLink'
import ILPlusLogo from '../ILPlusLogo'
import GALinkEvent from '../GALinkEvent'
import { scaleFromCrops } from '../../../utils/imageUtils'

const Image = styled.img`
	width: 100%;
	height: auto;
`

const VisualOrder = styled.div<{ orderNum: number }>`
	order: ${({ orderNum }) => orderNum};
`

type Props = {
	teaser: ArticleTeaser | FeedListTeaserData
	siteName: string
}

const SmallTeaser = ({ teaser, siteName }: Props) => (
	<Teaser>
		<Teaser.Section
			css={`
				@media ${core.mediaquery.max.medium} {
					column-gap: ${core.spacing.medium};
				}
				@media ${core.mediaquery.max['x-small']} {
					column-gap: ${core.grid.margin.none};
				}
			`}
		>
			<Teaser.SectionItem
				alignContent='start'
				colSpanBreakpoint={[
					{
						breakpoint: 'core.mediaquery.range.small',
						colSpan: 3,
					},
					{
						breakpoint: 'core.mediaquery.range.x-small',
						colSpan: 4,
					},
				]}
				stackBreakpoint='core.mediaquery.min.medium'
				orderBreakpoint={[
					{
						breakpoint: 'core.mediaquery.max.small',
						order: 1,
					},
				]}
				css={`
					@media ${core.mediaquery.min.medium} {
						margin-bottom: ${core.spacing.medium};
					}
				`}
			>
				<Image
					src={
						(teaser as FeedListTeaserData).keyImage ||
						(siteName !== 'iltalehti'
							? `https://images.almatalent.fi/${scaleFromCrops(
									(teaser as ArticleTeaser).leadImageCrops,
									'320x240',
									'default'
							  )}/https://assets.almatalent.fi/image/${
									(teaser as ArticleTeaser).leadImageUuid
							  }`
							: (teaser as ArticleTeaser).leadImageUuid)
					}
					alt={teaser.title}
				/>
			</Teaser.SectionItem>

			<Teaser.SectionItem
				alignContent='start'
				colSpanBreakpoint={[
					{
						breakpoint: 'core.mediaquery.range.medium',
						colSpan: 9,
					},
					{
						breakpoint: 'core.mediaquery.range.small',
						colSpan: 9,
					},
					{
						breakpoint: 'core.mediaquery.range.x-small',
						colSpan: 8,
					},
				]}
				stackBreakpoint='core.mediaquery.min.medium'
				css={`
					@media ${core.mediaquery.max['x-small']} {
						margin-right: ${core.spacing.medium};
					}
				`}
			>
				<GALinkEvent>
					<TeaserLink
						href={
							teaser.url ||
							`https://www.${siteName}.fi/uutiset/${
								(teaser as ArticleTeaser).titleSlug
							}/${(teaser as ArticleTeaser).uuid}`
						}
					>
						<Teaser.Text size='small' level='h3'>
							{(teaser as ArticleTeaser).teaserTitle || teaser.title}
						</Teaser.Text>
					</TeaserLink>
				</GALinkEvent>
				<VisualOrder orderNum={-1}>
					{siteName !== 'iltalehti' ? (
						<MetaRow
							displayBreakpoint='core.mediaquery.min.medium'
							css={`
								@media ${core.mediaquery.min.medium} {
									margin-bottom: ${core.spacing.medium};
								}
							`}
						>
							{teaser.lockedArticle && <MetaRow.Subscriber noDivider />}
							{teaser.lockedArticle && (
								<MetaRow.Label text='Tilaajille' noDivider />
							)}
						</MetaRow>
					) : (
						<MetaRow
							displayBreakpoint='core.mediaquery.min.medium'
							css={`
								@media ${core.mediaquery.min.medium} {
									margin-bottom: ${core.spacing.medium};
								}
							`}
						>
							<ILPlusLogo />
							{teaser.primaryCategory && (
								<MetaRow.Label text={teaser.primaryCategory.name} noDivider />
							)}
						</MetaRow>
					)}
				</VisualOrder>
			</Teaser.SectionItem>
			<Teaser.Section
				displayBreakpoint='core.mediaquery.max.small'
				css={{ order: -1, gridColumn: 'span 12' }}
			>
				<Teaser.SectionItem className={spacer('bottom', 'medium')}>
					{siteName !== 'iltalehti' ? (
						<MetaRow>
							{teaser.lockedArticle && <MetaRow.Subscriber noDivider />}
							{teaser.lockedArticle && (
								<MetaRow.Label text='Tilaajille' noDivider />
							)}
						</MetaRow>
					) : (
						<MetaRow>
							<ILPlusLogo />
							{teaser.primaryCategory && (
								<MetaRow.Label text={teaser.primaryCategory.name} noDivider />
							)}
						</MetaRow>
					)}
				</Teaser.SectionItem>
			</Teaser.Section>
		</Teaser.Section>
	</Teaser>
)

export default SmallTeaser
