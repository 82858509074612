import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { parseResponse } from '..'
import { ErrorMessage } from '../../types/subscription'
import URLS from '../urls'

async function addFamilyUser(
	url: string,
	{ arg }: { arg: { subscriptionId?: string; emailAddress: string } }
) {
	return await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(arg),
	})
		.then((response) => parseResponse(response))
		.then((parsedResponse) => {
			if (parsedResponse.errorCode) {
				return Promise.reject(parsedResponse)
			}
			return parsedResponse
		})
}

/**
 * Adds a family user and returns the response
 *
 * @param {() => void} onSuccessCallback Calls this callback function if the API request was successful
 * @param {() => void} onErrorCallback Calls this callback function if the API request resulted in an error
 */
export default (
	onSuccessCallback?: () => void,
	onErrorCallback?: (err: ErrorMessage) => void
) => {
	return useSWRMutation(URLS.ADD_USER, addFamilyUser, {
		onSuccess: () => {
			mutate(URLS.CUSTOMER_SUBSCRIPTIONS)
			if (onSuccessCallback) {
				onSuccessCallback()
			}
		},
		onError: (err: ErrorMessage) => {
			if (onErrorCallback) {
				onErrorCallback(err)
			}
		},
	})
}
