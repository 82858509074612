import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
	core,
	Heading,
	Icon,
	SecondaryButton,
} from '@almamedia/talent-media-component-library'
import useSubscription from '../../api/subscriptions/useSubscription'
import DataColumns, { ColumnData } from '../common/DataColumns'
import FamilySubscriptionModal from './FamilySubscriptionModal'
import copyTexts from '../../assets/copyTexts'
import { sortFamilyUsersMainToFirst } from '../../utils/subscriptionUtils'
import { InfoBadge, InfoBadgeIcon } from '../common'

const FamilySubscriptionSection = () => {
	const { id } = useParams()
	const { data } = useSubscription(id)
	const [familyModalDisplayed, setFamilyModalDisplayed] = useState(false)

	const dataColumnFamily: (ColumnData | null)[] = useMemo(() => {
		const familyOrderUsers = sortFamilyUsersMainToFirst(data)
		return [
			data?.familyOrderDetails
				? {
						label: copyTexts.subscription['Lukuoikeuksien määrä'],
						value: `${
							data.familyOrderDetails.maxNumberOfUsers -
							data.familyOrderDetails.users.length
						}/${data.familyOrderDetails.maxNumberOfUsers}`,
				  }
				: null,
			...[...Array(data?.familyOrderDetails?.maxNumberOfUsers)].map(
				(_, i): ColumnData => ({
					label: `${copyTexts.subscription.Lukuoikeus} ${i + 1}`,
					value: familyOrderUsers[i] ? familyOrderUsers[i].emailAddress : '-',
					badge:
						familyOrderUsers[i]?.active === false ? (
							<InfoBadge css={{ marginBottom: core.spacing.medium }}>
								<InfoBadgeIcon>
									<Icon.CoreExclamationCircle size='core.size.icon.medium' />
								</InfoBadgeIcon>
								<span>{copyTexts.subscription['Odottaa aktivointia']}</span>
							</InfoBadge>
						) : undefined,
				})
			),
		]
	}, [data])

	return (
		<>
			<Heading.Subtitle level='h2' css={{ fontSize: core.font.scale[2] }}>
				{copyTexts.subscription.Perhetilaus}
			</Heading.Subtitle>
			<DataColumns
				data={dataColumnFamily}
				withRowGap
				withRowDivider
				withoutTopDivider
				withoutBottomDivider
				textColumnCss={{
					marginTop: core.spacing.medium,
					marginBottom: core.spacing.medium,
				}}
				css={{
					marginTop: core.spacing.medium,
					marginBottom: core.spacing.medium,
				}}
			/>
			<SecondaryButton
				onClick={() => setFamilyModalDisplayed(true)}
				color={core.color.primary[10]}
				css={{
					width: '100%',
				}}
				icon={<Icon.CoreEdit size='core.size.icon.large' />}
			>
				{copyTexts.subscription['Muokkaa perhetilausta']}
			</SecondaryButton>
			<FamilySubscriptionModal
				familyModalDisplayed={familyModalDisplayed}
				setFamilyModalDisplayed={setFamilyModalDisplayed}
			/>
		</>
	)
}

export default FamilySubscriptionSection
