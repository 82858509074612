import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import URLS from '../urls'

async function useDeleteFamilyUser(
	url: string,
	{ arg }: { arg: { subscriptionId?: string; accountId: string } }
) {
	return await fetch(
		`${url}/${arg.subscriptionId}/remove-user/${arg.accountId}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		}
	)
}

/**
 * Deletes a family user and returns the response
 *
 * @param {() => void} onSuccessCallback Calls this callback function if the API request was successful
 */
export default (onSuccessCallback?: () => void) => {
	return useSWRMutation(`${URLS.CUSTOMER_SUBSCRIPTIONS}`, useDeleteFamilyUser, {
		onSuccess: () => {
			mutate(URLS.CUSTOMER_SUBSCRIPTIONS)
			if (onSuccessCallback) {
				onSuccessCallback()
			}
		},
		onError: (err) => console.error(err),
	})
}
