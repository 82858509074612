import config from 'config'
import almaLoginUtils from '../../utils/loginUtils'
import { post } from '..'

const { apiRoot } = config

export async function createSession(accessToken: string, accountId?: string) {
	return post(
		`${apiRoot}create-session`,
		{ accountId },
		{ Authorization: `Bearer ${accessToken}` }
	)
}

export async function logout(source?: string) {
	return post(`${apiRoot}logout/`).then(() => {
		try {
			window.ALMA.gravitoV1.gtoClient.appendProfile({
				e: { matchOnId: '' },
			})
			if (window.prelogin) {
				window.prelogin.setLoggedOut()
			}
		} catch (e) {
			console.error(e)
		}
		almaLoginUtils.tunnusAnalytics.sendLogout({ source })
		almaLoginUtils.logout()
	})
}
