import React from 'react'
import { useLocation, Navigate, useParams } from 'react-router-dom'
import useSubscription from '../../api/subscriptions/useSubscription'
import useUser from '../../api/user/useUser'
import { hasAccessToSubscriptionInformation } from '../../utils/subscriptionUtils'
import { isGroupAnonymous, isUserLoggedIn } from '../../utils/userUtils'
import sessionStorageUtils from '../../utils/sessionStorageUtils'

const {
	setShowTunnusLoginPrompt,
	setTunnusReturnUrl,
	getTunnusReturnUrl,
	removeTunnusReturnUrl,
	setRedirectUrl,
} = sessionStorageUtils

const ProtectedRoute = ({
	children,
	customProtection,
}: {
	children: JSX.Element
	customProtection?: (state?: { prevPath?: string }) => boolean
}) => {
	const { pathname, state: routerState } = useLocation()
	const {
		data: user,
		isLoading: isUserDataLoading,
		error: isUserError,
	} = useUser()
	const { id } = useParams()
	const {
		data: subscription,
		isLoading: isSubscriptionDataLoading,
		error: isSubscriptionError,
	} = useSubscription(id)

	const userDataIsStillLoading =
		pathname !== '/' && isUserDataLoading && !isUserError
	const subscriptionDataIsStillLoading =
		pathname !== '/' && isSubscriptionDataLoading && !isSubscriptionError

	const userIsGroupAnonymous = isGroupAnonymous(user)

	/**
	 * If subscription is defined, then check if user has access to subscription info.
	 * If subscription is undefined, check if id is present.
	 */
	const allowSubscriptionBasedRoutes = subscription
		? hasAccessToSubscriptionInformation(subscription, user)
		: !id

	const userHasNoAccess =
		pathname !== '/' &&
		(isUserError || (!isUserDataLoading && !isUserLoggedIn(user)))
	const customProtectionActivated =
		customProtection && customProtection(routerState) && isUserLoggedIn(user)
	const navigateToRoot =
		userHasNoAccess ||
		!allowSubscriptionBasedRoutes ||
		customProtectionActivated

	/**
	 * Always reset the tunnus return url if a custom pathname other than the root was set.
	 */
	if (pathname === getTunnusReturnUrl()) {
		removeTunnusReturnUrl()
	}

	if (userHasNoAccess) {
		setTunnusReturnUrl(pathname)
		setShowTunnusLoginPrompt('true')
	}

	if (userDataIsStillLoading || subscriptionDataIsStillLoading) {
		return null
	}

	if (userIsGroupAnonymous) {
		setRedirectUrl('/ryhmakayttaja')
		return <Navigate to='/ryhmakayttaja' />
	}

	if (navigateToRoot) {
		setRedirectUrl('/')
		return <Navigate to='/' />
	}

	return children
}

export default ProtectedRoute
