const enum URLS {
	CUSTOMER_SUBSCRIPTIONS = '/att-public-api/customer-subscriptions',
	ADD_USER = `/att-public-api/customer-subscriptions/add-user`,
	CANCEL_ADD_USER = `/att-public-api/customer-subscriptions/cancel-add-user-order/`,
	USER = '/api/user/information',
	RECEIPT = '/att-public-api/customer-subscriptions/receipt-pdf',
	MATEJ_RECOMMENDATIONS = 'https://content-api.almamedia.io/v2/graphql',
	UPDATE_CREDIT_CARD = '/att-public-api/orders/update-credit-card',
	PRODUCT_INFO = '/att-public-api/products',
	IL_PLUS_MOST_READ_ARTICLES = '/v1/articles/iltalehti/lists/popular/weekly',
}

export default URLS
