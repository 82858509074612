import URLS from '../urls'

export default (id: string, invoiceNumber: string) => {
	return fetch(`${URLS.RECEIPT}/${id}`).then((response) => {
		response.blob().then((blob) => {
			const fileUrl = window.URL.createObjectURL(blob)
			const link = document.createElement('a')

			link.href = fileUrl
			link.download = `kuitti-${invoiceNumber}.pdf`
			link.click()
		})
	})
}
