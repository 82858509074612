import React, { useCallback, useEffect, useRef } from 'react'
import { Location, useLocation, useMatches, useParams } from 'react-router-dom'
import config from 'config'
import useDataLayerSender from '../api/commoncontent/useDataLayerSender'
import { insertFetchedHtml } from '../utils/DomUtils'
import { DigitalData, Environment, LoginStatus } from '../types'
import useSubscription from '../api/subscriptions/useSubscription'
import { getSubscriptionName } from '../utils/subscriptionUtils'
import { Subscription } from '../types/subscription'
import useUser from '../api/user/useUser'
import { isUserLoggedIn } from '../utils/userUtils'
import { UserState } from '../types/user'
import sessionStorageUtils from '../utils/sessionStorageUtils'
import useConsent from '../api/consent/useConsent'

const { getRedirectUrl, removeRedirectUrl } = sessionStorageUtils

const { sammioSiteId } = config

const MOBILE_MAX_BREAKPOINT = 768
const PORTABLE_MAX_BREAKPOINT = 1199

export const getEnvironment = () => {
	const env = import.meta.env
	if (env.DEV) return 'dev'
	if (env.MODE === 'preview') return 'qa'
	return env.MODE
}

const getSubCategories = (name?: string) => {
	if (!name) return undefined
	const { subscriptionMediaName, subscriptionName } = getSubscriptionName(name)
	return {
		subCategory1: subscriptionMediaName,
		subCategory2: subscriptionName,
	}
}

const pathname = (location: Location) =>
	location.pathname === '/' ? 'etusivu' : location.pathname.replace('/', '')

const getPageID = (
	location: Location,
	subscriptionData?: Subscription,
	isInvalidRoute?: boolean
) => {
	if (isInvalidRoute) return 'virhesivu'
	return subscriptionData?.subscription.billingInformation.priceCode
		? subscriptionData.subscription.billingInformation.priceCode
		: pathname(location)
}

const getPrimaryCategory = (
	location: Location,
	subscriptionData?: Subscription,
	id?: string,
	isInvalidRoute?: boolean
) => {
	if (isInvalidRoute) {
		return 'virhesivu'
	}
	if (subscriptionData && id) {
		if (location.pathname.endsWith(id)) {
			return 'tilauksen-hallinta'
		}
		const splitPathname = location.pathname.split('/')
		const primaryCategory = splitPathname[splitPathname.indexOf(id) + 1]
		if (primaryCategory === 'peruutus') {
			return 'fomo'
		} else if (
			primaryCategory === 'peruutus-vahvistus' &&
			location.search.includes('reconsider_accepted=true')
		) {
			return 'peruutus-tilaus-vahvistus'
		}
		return primaryCategory
	}
	return pathname(location)
}

const getPageType = (
	location: Location,
	id?: string,
	isInvalidRoute?: boolean
) => {
	if (isInvalidRoute) {
		return 'errorpage'
	} else if (location.pathname === '/') {
		return 'frontpage'
	} else if (id) {
		if (location.pathname.endsWith(id)) return 'itempage'
		else if (!location.pathname.endsWith('maksut'))
			// Cancellation flow
			return 'processpage'
	} else if (location.state?.pageType) {
		return location.state.pageType
	}
	return 'otherpage'
}

const getProfileInfo = (user?: UserState) => {
	if (user) {
		const { accountHashId, accountType } = user
		return {
			accountType,
			accountHashId,
			almaId: window.ALMA.tunnus.getUser()?.id,
			almaIDHash: window.ALMA.tunnus.getUser()?.almaIdHash,
			cdime: window.ALMA.tunnus.getUser()?.cdime,
		}
	}
	return {}
}

const getViewport = (mobile?: boolean, portable?: boolean) => {
	if (mobile) return 'mobile'
	if (portable) return 'portable'
	return 'desktop'
}

const sendDigitalData = (digitalData: DigitalData) => {
	if (window.ALMA && window.ALMA.sammioDatalayerSenderReady) {
		document.dispatchEvent(
			new window.CustomEvent('sammio-datalayer-send', {
				detail: {
					site: sammioSiteId,
					subsite: 'www',
					dataLayer: digitalData,
				},
			})
		)
	} else {
		window.setTimeout(() => {
			sendDigitalData(digitalData)
		}, 200)
	}
}

function DigitalDataSender() {
	const { data: dataLayerSender } = useDataLayerSender()
	const { id } = useParams()
	const { data: subscriptionData, isLoading: subscriptionDataLoading } =
		useSubscription(id)
	const { data: user, isLoading: userDataLoading } = useUser()
	const { data: consentsData } = useConsent()
	const divRef = useRef<HTMLDivElement>(null)
	const hasContentRendered = useRef(false)
	const location = useLocation()
	const matches = useMatches()
	const isInvalidRoute = matches.some((match) =>
		Object.keys(match.params).includes('*')
	)
	const sentLocationKey = useRef('')

	const windowWidth =
		window.screen.width < window.outerWidth
			? window.screen.width
			: window.outerWidth
	const mobile = windowWidth < MOBILE_MAX_BREAKPOINT
	const portable = windowWidth < PORTABLE_MAX_BREAKPOINT

	const setDigitalData = useCallback(() => {
		const digitalData = {
			page: {
				pageInfo: {
					pageID: getPageID(location, subscriptionData, isInvalidRoute),
					sysEnv: mobile ? 'mobile' : 'desktop',
					pageTitle: document.title,
					language: 'fi-FI',
					publisher: subscriptionData
						? subscriptionData.subscription.organization
						: 'Tilaukset - Alma Media',
					locationProtocol:
						(document &&
							document.location &&
							document.location.protocol.slice(0, -1)) ||
						'',
					locationHost:
						(document && document.location && document.location.host) || '',
					locationPathName:
						(document && document.location && document.location.pathname) || '',
					locationHref:
						(document && document.location && document.location.href) || '',
				},
				attributes: {
					environment: getEnvironment() as Environment,
					viewport: getViewport(mobile, portable),
					width: window.innerWidth,
					contentCharge: 'free',
					userAgentHeader: navigator.userAgent,
				},
				category: {
					primaryCategory: getPrimaryCategory(
						location,
						subscriptionData,
						id,
						isInvalidRoute
					),
					pageType: getPageType(location, id, isInvalidRoute),
					...getSubCategories(subscriptionData?.products[0].name),
				},
			},
			pageInstanceID: `${getPageID(
				location,
				subscriptionData,
				isInvalidRoute
			)}_${sammioSiteId}_${getEnvironment()}`,
			user: [
				{
					profile: [
						{
							profileInfo: getProfileInfo(user),
						},
					],
					segment: {
						loginstatus: (isUserLoggedIn(user)
							? 'loggedin'
							: 'loggedout') as LoginStatus,
					},
				},
			],
			version: {
				specificationVersion: '1.0',
				almaVersion: '2.3',
			},
		}

		window.digitalData = digitalData
		return digitalData
	}, [location, subscriptionData, isInvalidRoute, mobile, portable, id, user])

	// Insert dataLayer sender script into the page after it is fetched from the content router
	useEffect(() => {
		insertFetchedHtml(dataLayerSender, hasContentRendered, divRef)
	}, [dataLayerSender])

	// Set & send digitalData on location changes when all the needed data is loaded
	useEffect(() => {
		if (
			consentsData?.consentsLoaded &&
			sentLocationKey.current !== location.pathname &&
			!subscriptionDataLoading &&
			!userDataLoading &&
			((getRedirectUrl() && getRedirectUrl() === location.pathname) ||
				!getRedirectUrl())
		) {
			if (getRedirectUrl()) {
				removeRedirectUrl()
			}
			sentLocationKey.current = location.pathname
			sendDigitalData(setDigitalData())

			/**
			 * Send `digitaldata updated` GA event. This event is used to trigger page view tag
			 */
			window.dataLayer = window.dataLayer || []
			/*fix related to send correct page title with react-helmet*/
			setTimeout(() => {
				window.dataLayer.push({ event: 'digitaldata updated' })
			})
		}
	}, [
		location.pathname,
		setDigitalData,
		subscriptionDataLoading,
		userDataLoading,
		consentsData?.consentsLoaded,
	])

	return <div ref={divRef} />
}

export default React.memo(DigitalDataSender)
