import config from 'config'
import React from 'react'
import styled from 'styled-components'
import {
	ToastNotification as Notification,
	core,
} from '@almamedia/talent-media-component-library'
import { Link, LinkProps } from 'react-router-dom'
import useActiveSubscriptions from '../../api/subscriptions/useActiveSubscriptions'
import { isCardExpiring } from '../../utils/subscriptionUtils'
import GALinkEvent from '../common/GALinkEvent'
import copyTexts from '../../assets/copyTexts'
import useUser from '../../api/user/useUser'
import { isGroupAnonymous } from '../../utils/userUtils'

const { allowEmailVerification } = config

const ToastWrapper = styled.div`
	padding: ${core.spacing.large};
	margin: 0 auto;
	max-width: 75rem;
	width: 100%;
	padding: ${core.spacing.large};

	@media ${core.mediaquery.max['x-small']} {
		margin: 0;
		padding: 0;
	}
`

const LinkWithGA = ({ children, ...rest }: LinkProps) => (
	<GALinkEvent>
		<Link {...rest}>{children}</Link>
	</GALinkEvent>
)

const ToastNotification = () => {
	const { data, isLoading: subscriptionDataIsLoading } =
		useActiveSubscriptions()
	const subscriptionsWithExpiringCards = data?.filter((d) => isCardExpiring(d))
	const { data: userData, isLoading: userDataIsLoading } = useUser()
	const emailUnverified = userData?.needsVerification
	const shouldNotShowEmailVerification =
		userDataIsLoading || !allowEmailVerification || !emailUnverified
	const shouldNotShowCardUpdateVerification =
		subscriptionDataIsLoading ||
		subscriptionsWithExpiringCards?.length === 0 ||
		isGroupAnonymous(userData)

	if (shouldNotShowCardUpdateVerification && shouldNotShowEmailVerification)
		return null

	const message = emailUnverified
		? copyTexts.toastNotification['Vahvista sähköpostiosoitteesi']
		: copyTexts.toastNotification['Maksukortti vanhenemassa']

	const multipleSubscriptionsWithExpiringCards =
		subscriptionsWithExpiringCards && subscriptionsWithExpiringCards.length > 1

	const getLinkPath = () => {
		if (emailUnverified) {
			return '/omat-tiedot'
		} else if (multipleSubscriptionsWithExpiringCards) {
			return '/voimassa-olevat-tilaukset'
		}
		return (
			subscriptionsWithExpiringCards &&
			`/voimassa-olevat-tilaukset/${subscriptionsWithExpiringCards[0].subscription.id}`
		)
	}

	return (
		<ToastWrapper>
			<Notification
				type='announcement'
				title=''
				message={message}
				linkProps={{
					as: LinkWithGA,
					label: message,
					to: getLinkPath(),
				}}
			/>
		</ToastWrapper>
	)
}

export default ToastNotification
