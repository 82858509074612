import config from 'config'
import offerImagePlaceholder from '../assets/images/offer_image.png'
import { SubscriptionMediaName } from '../types/subscription'

const { assetApiUrl } = config
const baseUrl = `${assetApiUrl}/ATM-Omat-Tilaukset`

const imagesByMedia = {
	Arvopaperi: `${baseUrl}-AP.png`,
	Iltalehti: `${baseUrl}-IL.png`,
	Kauppalehti: `${baseUrl}-KLRegular.png`,
	Mikrobitti: `${baseUrl}-MB.png`,
	Mediuutiset: `${baseUrl}-MU.png`,
	Talouselämä: `${baseUrl}-TE.png`,
	'Tekniikka&Talous': `${baseUrl}-TT.png`,
	Tivi: `${baseUrl}-TV.png`,
	'Uusi Suomi': `${baseUrl}-US.png`,
}

export const getCancellationOfferImage = (
	subscriptionMediaName: SubscriptionMediaName
) => {
	return imagesByMedia[subscriptionMediaName] ?? offerImagePlaceholder
}
