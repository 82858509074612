import { core } from '@almamedia/talent-media-component-library'
import React, { useEffect, useRef } from 'react'
import useAlmaFooter from '../../api/commoncontent/useAlmaFooter'
import { insertFetchedHtml } from '../../utils/DomUtils'

const AlmaFooter = () => {
	const divRef = useRef<HTMLDivElement>(null)
	const hasContentRendered = useRef(false)
	const { data } = useAlmaFooter()

	useEffect(() => {
		insertFetchedHtml(data, hasContentRendered, divRef)
	}, [data])

	return (
		<div
			ref={divRef}
			style={{
				background: core.color.primary[20],
			}}
		/>
	)
}

export default AlmaFooter
