import { Subscription } from '../../types/subscription'
import { hasSubscriptionEnded } from '../../utils/subscriptionUtils'
import useCustomerSubscription from './useCustomerSubscription'

export default () => {
	const { data, isLoading, error } = useCustomerSubscription()
	const inactiveSubscriptions = data?.filter((d: Subscription) =>
		hasSubscriptionEnded(d)
	)
	return { data: inactiveSubscriptions, isLoading, error }
}
