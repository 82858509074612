import Cookies from 'js-cookie'
import useSWR from 'swr'
import config from 'config'
import { ApiListData } from '../../types/teaser'
import URLS from '../urls'
import { parseResponse } from '..'

const {
	tempCookieKeys: { gravitoOPUid },
} = config

const query = (limit: number, slug: string) => {
	if (slug === 'il') {
		const sammiobsid = Cookies.get('sammio-bsid') || null
		const query = `query GetRecommendations {
			recommendations(
				cookies: [{name: SAMMIO_BSID, value: "${sammiobsid}"}]
				engine: [{name: MATEJ, instance: IL, parameters: { modelName: "default", rotationRate: 1}}]
				limit: 4
				dateLimit: "P14D"
				chargeStatuses: ["paid"]
				noSponsoredContent: true
			) {
				content {
					cid
				}
			}

		}`
		return encodeURIComponent(query)
	}

	const gravitoOPUidCookie = Cookies.get(gravitoOPUid) || null
	const query = `query GetRecommendations {
        crossrecommendations(
            cookies: [{name: GRAVITO_OPUID, value: "${gravitoOPUidCookie}"}]
            engine: [{name: MATEJ, instance: CROSSSITE}]
            limit: ${limit}
            dateLimit: "P14D"
			chargeStatuses: ["starred"]
			sites: ["${slug}"]
        ) {
            content {
                cid
            }
        }
    }`

	return encodeURIComponent(query)
}

const fetcher = async (url: string, slug: string) =>
	fetch(url)
		.then((res) => parseResponse(res))
		.then((articleIds) => {
			if (
				articleIds &&
				articleIds.data &&
				(articleIds.data.crossrecommendations ||
					articleIds.data.recommendations)
			) {
				if (slug !== 'il') {
					const uuids = articleIds.data.crossrecommendations
						.map((rec: any) => rec.content.cid)
						.join(',')
					return fetch(
						`${config.apiRoot}news/v2/dynamic/teaser/${slug}/${uuids}`
					).then((res) => parseResponse(res))
				}

				// Fetch IL articles
				return Promise.all(
					articleIds.data.recommendations.map((article: any) => {
						const url = `https://api.il.fi/v1/articles/iltalehti/lists/latest?article_id[]=${article.content.cid}`
						return fetch(url)
							.then((response) => response.json())
							.then((json) =>
								json.response && json.response.length > 0
									? {
											uuid: json.response[0].article_id,
											leadImageUuid: json.response[0].main_image_urls.size612,
											title: json.response[0].title,
											titleSlug: 'a',
											primaryCategory: {
												name: json.response[0].category.description,
											},
									  }
									: {}
							)
							.catch((error) => {
								console.error(error)
								return {}
							})
					})
				).then((items) => {
					return {
						items: items,
					}
				})
			}
		})

export default (limit: number, mediaSlug: string) => {
	return useSWR<ApiListData>(
		`${URLS.MATEJ_RECOMMENDATIONS}?query=${query(limit, mediaSlug)}`,
		(url) => fetcher(url, mediaSlug),
		{
			shouldRetryOnError: false,
			errorRetryCount: 0,
			errorRetryInterval: 0,
		}
	)
}
