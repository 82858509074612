import React from 'react'
import {
	core,
	Icon,
	Link as DsLink,
} from '@almamedia/talent-media-component-library'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import copyTexts from '../../assets/copyTexts'
import GALinkEvent from './GALinkEvent'

const StyledRouterLink = styled(Link)<{
	$noMargin?: boolean
}>`
	display: inline-block;
	text-decoration: none;

	:focus-visible {
		outline-offset: ${core['outline-offset'].small};
		outline: ${core['outline-offset'].small} solid ${core.color.primary[10]};
	}

	${({ $noMargin }) =>
		!$noMargin &&
		css`
			margin-bottom: ${core.spacing['xx-large']};

			@media ${core.mediaquery.max['x-small']} {
				margin-bottom: ${core.spacing['x-large']};
				margin-left: ${core.spacing.medium};
			}
		`}
`
const TopBreadCrumbLink = ({
	to,
	text,
	noMargin,
	customGAObject,
	...rest
}: {
	to?: string
	text?: string
	noMargin?: boolean
	customGAObject?: object
}) => {
	return (
		<GALinkEvent customData={customGAObject}>
			<StyledRouterLink to={to || '/'} $noMargin={noMargin} {...rest}>
				<DsLink
					as='span'
					icon={<Icon.CoreArrowLeft size='core.size.icon.small' />}
					iconPosition='left'
				>
					{text || copyTexts.nav.breadCrumb}
				</DsLink>
			</StyledRouterLink>
		</GALinkEvent>
	)
}

export default TopBreadCrumbLink
