import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import URLS from '../urls'

async function useCancelAddFamilyUser(
	url: string,
	{ arg }: { arg: { orderId?: string } }
) {
	return await fetch(`${url}${arg.orderId}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	})
}

/**
 * Cancels the addition of family user and returns the response
 *
 * @param {() => void} onSuccessCallback Calls this callback function if the API request was successful
 */
export default (onSuccessCallback?: () => void) => {
	return useSWRMutation(`${URLS.CANCEL_ADD_USER}`, useCancelAddFamilyUser, {
		onSuccess: () => {
			mutate(URLS.CUSTOMER_SUBSCRIPTIONS)
			if (onSuccessCallback) {
				onSuccessCallback()
			}
		},
		onError: (err) => console.error(err),
	})
}
