import useCustomerSubscription from './useCustomerSubscription'

export default (id?: string) => {
	const { data, isLoading, error } = useCustomerSubscription()
	const subscription = data?.find((d) => d.subscription.id === id)

	return {
		data: subscription,
		isLoading,
		error,
	}
}
