import { mutate } from 'swr'
import useSWRMutation from 'swr/mutation'
import { parseResponse } from '..'
import URLS from '../urls'

async function endSubscription(
	url: string,
	{ arg }: { arg: { reason: string } }
) {
	return await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(arg),
	}).then((response) => parseResponse(response))
}

export default (id?: string) => {
	return useSWRMutation(
		`${URLS.CUSTOMER_SUBSCRIPTIONS}/${id}/end-subscription`,
		endSubscription,
		{
			onSuccess: () => {
				mutate(URLS.CUSTOMER_SUBSCRIPTIONS)
			},
		}
	)
}
