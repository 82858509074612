import { OrganizationId } from '../types/organization'
import { ProductInfo, Subscription } from '../types/subscription'
import { UserState } from '../types/user'

export const getMediaDomainFromOrganization = (
	organization: OrganizationId
) => {
	switch (organization) {
		case 'Klfi': {
			return 'kauppalehti.fi'
		}
		case 'Talouselama': {
			return 'talouselama.fi'
		}
		case 'Arvopaperi': {
			return 'arvopaperi.fi'
		}
		case 'Mediuutiset': {
			return 'mediuutiset.fi'
		}
		case 'Tivi': {
			return 'tivi.fi'
		}
		case 'TekTal': {
			return 'tekniikkatalous.fi'
		}
		case 'Mikrobitti': {
			return 'mikrobitti.fi'
		}
		case 'UusiSuomi': {
			return 'uusisuomi.fi'
		}
		case 'Iltalehti': {
			return 'iltalehti.fi'
		}
		case 'AlmaTalentKirjat': {
			return 'shop.almatalent.fi'
		}
		case 'Pro': {
			return 'verkkokirjahylly.almatalent.fi'
		}
		default:
			return ''
	}
}

/**
 * Subscription is considered as ended if the subscription start date is the same as subscription end date
 * or if the current date is greater than the subscription end date.
 */
export const hasSubscriptionEnded = (subscription: Subscription) => {
	const { startDate, endDate } = subscription.subscription
	const _isFutureSubscriptionCancelled =
		isFutureSubscriptionCancelled(subscription)
	const subscriptionEndDate = endDate && new Date(endDate)
	const subscriptionStartDate = startDate && new Date(startDate)
	const currentDate = new Date()
	if (!subscriptionEndDate || !subscriptionStartDate) return false
	return (
		_isFutureSubscriptionCancelled ||
		(subscriptionEndDate && currentDate > subscriptionEndDate)
	)
}

export const isSubscriptionRecurring = (subscription: Subscription) => {
	if (isCirixTypeSubscription(subscription)) {
		return !subscription.subscription.endDate
	}
	return (
		subscription.subscription.type === 'Recurring' ||
		subscription.subscription.type === 'FixedEndDateRepeating'
	)
}

export const hasFamilySubscription = (subscription?: Subscription) =>
	subscription?.familyOrderDetails !== null

export const isNonMainFamilySubscription = (
	subscription?: Subscription,
	user?: UserState
) =>
	subscription?.familyOrderDetails !== null &&
	subscription?.familyOrderDetails?.users.find(
		(u) => u.emailAddress === user?.email && !u.mainUser
	)

/**
 * Checks if the subscription is ending within 1 month from current date.
 */
export const isSubscriptionEndingSoon = (subscription: Subscription) => {
	const { endDate } = subscription.subscription
	const subscriptionEndDate = endDate && new Date(endDate)
	const currentDate = new Date()
	const currentDatePlusOneMonth = new Date(
		new Date().setMonth(currentDate.getMonth() + 1)
	)
	return (
		!!subscriptionEndDate &&
		currentDate < subscriptionEndDate &&
		currentDatePlusOneMonth > subscriptionEndDate
	)
}

export const isSubscriptionStartingInFuture = (subscription: Subscription) => {
	const { startDate } = subscription.subscription
	const subscriptionStartDate = startDate && new Date(startDate)
	const currentDate = new Date()

	return subscriptionStartDate > currentDate
}

export const isCardExpiring = (subscription: Subscription) => {
	const { creditCardInfo } = subscription.subscription.billingInformation
	const _canUpdateCardIfRecurringFixedLengthType =
		canUpdateCardIfRecurringFixedLengthType(subscription)
	const expirationDate = creditCardInfo?.expirationDate
	const _nextBillingDate = nextBillingDate(subscription)
	const subscriptionNextBillingDate =
		_nextBillingDate && new Date(_nextBillingDate)
	const cardExpirationDate = expirationDate && new Date(expirationDate)
	return (
		!!_canUpdateCardIfRecurringFixedLengthType &&
		!!cardExpirationDate &&
		!subscription.subscription.endDate &&
		!!subscriptionNextBillingDate &&
		subscriptionNextBillingDate > cardExpirationDate
	)
}

export const isFreeSubscription = (subscription: Subscription) => {
	const {
		type,
		billingInformation: { paymentTerm },
	} = subscription.subscription
	return type === 'Free' || paymentTerm === 'FREE'
}

export const formatDate = (date?: string, addedDays?: number) => {
	if (!date) return '-'

	if (addedDays) {
		const currentDate = new Date(date)
		return new Date(
			currentDate.setDate(currentDate.getDate() + addedDays)
		).toLocaleDateString('fi-fi')
	}

	return new Date(date).toLocaleDateString('fi-fi')
}

/**
 * Tuju API sends the `endDate` as the date from which the subscription is invalid.
 * In the self-service, we want to show the `endDate` as the last date until which
 * the subscription is valid.
 */
export const getEndDate = (date?: string) => {
	if (!date) return '-'

	const apiEndDate = new Date(date)
	apiEndDate.setDate(apiEndDate.getDate() - 1)
	return apiEndDate.toLocaleString('fi-fi', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	})
}

export const getTranslatedSubscriptionType = (type?: string) => {
	if (type === 'Recurring' || type === 'FixedEndDateRepeating') return 'Jatkuva'

	return ''
}

export const getTranslatedProductType = (type?: string) => {
	if (type) {
		if (type.toLowerCase() === 'digi' || type.toLowerCase() === 'digitilaus')
			return 'Digitilaus'
	}

	return ''
}

export const isMaxUsersReached = (data?: Subscription) =>
	data?.familyOrderDetails &&
	data.familyOrderDetails.users.length <
		data.familyOrderDetails.maxNumberOfUsers

export const sortFamilyUsersMainToFirst = (data?: Subscription) =>
	[...(data?.familyOrderDetails?.users || [])].sort((a) =>
		a.mainUser ? -1 : 0
	)

export const activeFamilyUsers = (data?: Subscription) =>
	data?.familyOrderDetails?.users
		? data?.familyOrderDetails?.users.filter(
				(familyUser) => familyUser.active && !familyUser.mainUser
		  )
		: []

export const isUnionSubscription = (data?: Subscription) => {
	return data?.subscription.billingInformation.billingSystem === 'External'
}

export const getPaymentType = (data: Subscription) => {
	const billingSystem = data?.subscription?.billingInformation?.billingSystem
	const requestedPaymentType = data?.firstOrder?.requestedPaymentType
	const hasCreditCardInfo =
		data?.subscription?.billingInformation?.creditCardInfo
	if (billingSystem === 'MobilePay' || requestedPaymentType === 'MobilePay') {
		return 'MobilePay'
	} else if (
		data?.payments[0]?.paymentType === 'CreditCards' ||
		requestedPaymentType === 'CreditCards' ||
		hasCreditCardInfo
	) {
		return 'Maksukortti'
	} else if (
		billingSystem.includes('OnlineBanks') ||
		billingSystem === 'Paytrail' ||
		requestedPaymentType === 'OnlineBanks'
	) {
		return 'Verkkopankki'
	}
}

/**
 * Transforms the first letter of the subscription name to uppercase, and replaced Digi with Digitilaus
 */
export const getSubscriptionName = (name: string) => {
	const subscriptionNameIndex = name.search(/(([Dd])igi)|Plus|Sijoittaja|VIP/)
	const subscriptionMediaName = name
		.substring(
			0,
			subscriptionNameIndex !== -1 ? subscriptionNameIndex : undefined
		)
		.trim()
	const subscriptionName =
		!subscriptionNameIndex || subscriptionNameIndex === -1
			? undefined
			: name
					.substring(subscriptionNameIndex)
					.trim()
					.split(' ')
					.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
					.map((s) => s.replace(/^Digi$/, 'Digitilaus'))
					.join(' ')
	return { subscriptionMediaName, subscriptionName }
}

export const hasAccessToSubscriptionInformation = (
	data: Subscription,
	user?: UserState
) => {
	const _isFreeSubscription = isFreeSubscription(data)
	const _isNonMainFamilySubscription = isNonMainFamilySubscription(data, user)
	const _isUnionSubscription = isUnionSubscription(data)
	const _isCirixTypeSubscription = isCirixTypeSubscription(data)
	const _isFutureSubscriptionCancelled = isFutureSubscriptionCancelled(data)
	const isCorporateAccount = user?.accountType === 'corporate'

	const hasNoAccess =
		_isFreeSubscription ||
		_isNonMainFamilySubscription ||
		_isUnionSubscription ||
		isCorporateAccount ||
		_isCirixTypeSubscription ||
		_isFutureSubscriptionCancelled

	return !hasNoAccess
}

export const isSijoittaPakettiSubscription = (data?: Subscription) =>
	data?.products?.[0].name.includes('Sijoittajapaketti')

/**
 * The retirement/existence of a product can be determined by whether it has an end date
 * in it's product details. No end date (null) means the product is still available.
 */
export const isProductRetired = (data?: ProductInfo) => !!data?.endDate

export const nextBillingDate = (data?: Subscription) =>
	data?.subscription.billingInformation.lastBilledDate ||
	data?.subscription.billingInformation.billingStartDate

export const formatPrice = (price?: string) =>
	price?.replace('.', ',').replace(/0$/, ' €')

/**
 * Checks whether the provided subscription in the first paramater belongs to a product
 * that also belongs to at least one other active subscription.
 */
export const getResubscribedSubscription = (
	data: Subscription,
	activeSubscriptions?: Subscription[]
) => {
	const currentDataProductCodes = data.subscription.productInstances.map(
		(p) => p.code
	)
	return activeSubscriptions
		?.filter((as) => as.subscription.id !== data.subscription.id)
		.some((as) =>
			as.subscription.productInstances.some((p) =>
				currentDataProductCodes.includes(p.code)
			)
		)
}

export const shouldShowReorderButton = (
	hasEnded?: boolean,
	subscription?: Subscription,
	activeSubscriptions?: Subscription[]
) => {
	const _isFreeSubscription = subscription && isFreeSubscription(subscription)
	const _resubscribedSubscription =
		subscription &&
		getResubscribedSubscription(subscription, activeSubscriptions)
	const _isSubscriptionEndingSoon =
		subscription && isSubscriptionEndingSoon(subscription)
	const _isUnionSubscription = isUnionSubscription(subscription)
	const _isCirixTypeSubscription =
		subscription && isCirixTypeSubscription(subscription)
	const inActiveCombinations =
		(hasEnded && !_resubscribedSubscription) ||
		(!hasEnded && _isFreeSubscription) ||
		(!hasEnded && _isSubscriptionEndingSoon && !_isFreeSubscription)
	const endingResubscribedSubscription =
		_resubscribedSubscription && _isSubscriptionEndingSoon

	return (
		!endingResubscribedSubscription &&
		inActiveCombinations &&
		!_isUnionSubscription &&
		!_isCirixTypeSubscription
	)
}

export const isCirixTypeSubscription = (subscription: Subscription) =>
	subscription?.subscription?.billingInformation.billingSystem === 'Cirix' ||
	subscription?.firstOrder?.orderType === 'Cirix'

export const isFutureSubscriptionCancelled = (subscription: Subscription) => {
	const { startDate, endDate } = subscription.subscription
	const subscriptionEndDate = endDate && new Date(endDate)
	const subscriptionStartDate = startDate && new Date(startDate)
	if (!subscriptionEndDate || !subscriptionStartDate) return false
	return subscriptionStartDate.getTime() === subscriptionEndDate.getTime()
}

/**
 * If "RecurringFixedLength", then don't allow updating the card if subscription
 * end date is earlier than the recurring fixed length end date.
 */
export const canUpdateCardIfRecurringFixedLengthType = (
	subscription: Subscription
) => {
	const type = subscription?.subscription?.type
	const endDate = subscription?.subscription?.endDate
	const recurringFixedLengthEndDate =
		subscription?.subscription?.billingInformation?.recurringFixedLengthEndDate
	const subscriptionEndDate = endDate && new Date(endDate)
	const subscriptionRecurringFixedLengthEndDate =
		recurringFixedLengthEndDate && new Date(recurringFixedLengthEndDate)
	if (
		type === 'RecurringFixedLength' &&
		subscriptionEndDate &&
		subscriptionRecurringFixedLengthEndDate &&
		subscriptionEndDate < subscriptionRecurringFixedLengthEndDate
	) {
		return false
	}
	return true
}
