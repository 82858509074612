import React from 'react'
import styled from 'styled-components'
import {
	core,
	Heading,
	Input,
	RadioButton,
	RadioButtonLabel,
} from '@almamedia/talent-media-component-library'
import { useCancellationContext } from './CancellationLayout'
import copyTexts from '../../assets/copyTexts'

const Wrapper = styled.main`
	max-width: ${core.size.template['page-wrapper']};
	margin: 0 auto;
	background: ${core.color.brand.grey[95]};

	@media ${core.mediaquery.max.small} {
		max-width: 40.625rem;
	}
`

const ActionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 28rem;
	margin: 0 auto;
	padding: ${core.spacing['xx-large']} 0;
	gap: ${core.spacing.medium};

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing['x-large']} ${core.spacing.large};
	}
`

const RadioWrapper = styled.div`
	display: flex;

	> input[type='radio'] {
		flex-shrink: 0;
	}
`

export const OTHER = 'Muu syy'
const REASONS = [
	'Tilauksen hinta',
	'Ei aikaa lukea',
	'Ei tarvetta tällä hetkellä',
	'Halusin vain edullisen ensimmäisen tilausjakson',
	'Sisältö ei kiinnosta',
	'Tekniset ongelmat',
	OTHER,
]

const CancellationSurvey = () => {
	const {
		selectedButton,
		otherInputValue,
		showErrorMessage,
		setSelectedButton,
		setOtherInputValue,
	} = useCancellationContext()
	const isInvalid =
		selectedButton === '' ||
		(selectedButton === OTHER && otherInputValue === '')

	return (
		<Wrapper>
			<ActionsWrapper
				role='radiogroup'
				aria-errormessage='cancellation-layout-error'
				aria-labelledby='cancellation-survey-heading'
				aria-invalid={isInvalid && showErrorMessage}
			>
				<Heading.Subtitle
					size='x-small, @min.small=small'
					css={{ marginBottom: core.spacing['x-small'] }}
					id='cancellation-survey-heading'
				>
					{copyTexts.cancelFlow.survey['Jatkaaksesi valitse']}
				</Heading.Subtitle>
				{REASONS.map((reason, i) => (
					<RadioWrapper key={reason}>
						<RadioButton
							id={`${reason}_${i}`}
							value={reason}
							checked={selectedButton === reason}
							onChange={(e) => setSelectedButton(e.target.value)}
						/>
						<RadioButtonLabel htmlFor={`${reason}_${i}`}>
							{reason}
						</RadioButtonLabel>
					</RadioWrapper>
				))}
				{selectedButton === OTHER && (
					<Input
						value={otherInputValue}
						placeholder={OTHER}
						onChange={(e) => setOtherInputValue(e.target.value)}
					/>
				)}
			</ActionsWrapper>
		</Wrapper>
	)
}

export default CancellationSurvey
