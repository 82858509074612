import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const LocationChangeSideEffects = () => {
	const { pathname } = useLocation()

	/**
	 * Scroll page to top on every pathname change
	 */
	useEffect(() => {
		document.documentElement.scrollTo({
			top: 0,
			left: 0,
			behavior: 'auto',
		})
	}, [pathname])

	return null
}

export default React.memo(LocationChangeSideEffects)
