import { core, Heading } from '@almamedia/talent-media-component-library'
import styled from 'styled-components'

export const Wrapper = styled.div`
	padding-top: ${core.spacing['x-large']};
	padding-bottom: ${core.spacing['x-large']};
	display: grid;
	justify-content: center;

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing.large} 0 ${core.spacing['xx-large']};
	}
`

export const StyledHeadline = styled(Heading.Headline)`
	${core.type.alma.subtitle.heavy};
	margin-bottom: ${core.spacing.large};
	text-align: center;
	max-width: 35rem;
	white-space: pre-wrap;

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing.small};
	}
`

export const GreyBackground = styled.div`
	background-color: ${core.color.primary[95]};
	padding: 0 ${core.spacing.medium};

	@media ${core.mediaquery.max['small']} {
		padding: 0 ${core.spacing.large};
	}
`
