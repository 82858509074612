import useSWR from 'swr'
import { parseResponse } from '..'
import URLS from '../urls'
import { ProductInfo } from '../../types/subscription'

const fetcher = (url: string) => fetch(url).then((res) => parseResponse(res))

export default (productCode?: string) => {
	return useSWR<ProductInfo>(
		productCode ? `${URLS.PRODUCT_INFO}/${productCode}` : null,
		fetcher
	)
}
