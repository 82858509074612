import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { core, Heading, Icon } from '@almamedia/talent-media-component-library'
import { OsIllustration } from '../../assets/os-illustration'
import almaLoginUtils from '../../utils/loginUtils'
import { Email } from '../common'
import useUser from '../../api/user/useUser'
import { isUserLoggedIn } from '../../utils/userUtils'
import copyTexts from '../../assets/copyTexts'
import { waitForElmById } from '../../utils/DomUtils'
import sessionStorageUtils from '../../utils/sessionStorageUtils'
import GALinkEvent from '../common/GALinkEvent'
import Head from '../common/Head'

const CARD_MOBILE_MAX_WIDTH = '36rem'

const {
	getShowTunnusLoginPrompt,
	removeShowTunnusLoginPrompt,
	setPrepareToRemoveLoginPromptSS,
	getPrepareToRemoveLoginPromptSS,
	removePrepareToRemoveLoginPromptSS,
} = sessionStorageUtils

const FrontPageWrapper = styled.main`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-bottom: ${core.spacing['xxxx-large']};

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing['xx-large']};
	}
`

const IllustrationContent = styled.section`
	position: relative;
	z-index: ${core['z-index'].elevate};
	padding-top: ${core.spacing['xxx-large']};
	width: 100%;

	&:before {
		position: absolute;
		z-index: ${core['z-index'].deep};
		top: 0;
		left: 0;
		width: 100%;
		height: 65%;
		content: '';
		background: ${core.color.brand.grey[95]};
	}

	@media ${core.mediaquery.max['x-small']} {
		padding-top: ${core.spacing['xx-large']};
	}
`

const Illustration = styled(OsIllustration)`
	max-width: 40.625rem;

	@media ${core.mediaquery.max.small} {
		max-width: 33.125rem;
	}

	@media ${core.mediaquery.max['x-small']} {
		max-width: 20rem;
	}
`

const CardContent = styled.section`
	max-width: ${core.size.template['page-wrapper']};
	width: 100%;
	padding: 0 ${core.spacing.large};

	@media ${core.mediaquery.max.small} {
		max-width: ${CARD_MOBILE_MAX_WIDTH};
	}
	@media ${core.mediaquery.max['x-small']} {
		padding: 0 ${core.spacing.medium};
	}
`

const PageHeading = styled(Heading.Subtitle)`
	margin: 0 auto;
	max-width: 40.125rem;
	padding: 0 ${core.spacing.large} ${core.spacing.large} ${core.spacing.large};

	@media ${core.mediaquery.max.small} {
		max-width: 33.125rem;
	}

	@media ${core.mediaquery.max['x-small']} {
		max-width: 17.125rem;
	}
`

const Cards = styled.div<{ $addSpacing?: boolean }>`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: ${core.grid.gutter.medium};

	@media ${core.mediaquery.max.small} {
		grid-template-columns: repeat(2, 1fr);
		gap: ${core.grid.gutter.small};
	}

	${({ $addSpacing }) =>
		$addSpacing &&
		css`
			margin-top: ${core.spacing['xx-large']};

			@media ${core.mediaquery.max.small} {
				margin-top: ${core.spacing['x-large']};
			}

			@media ${core.mediaquery.max['x-small']} {
				margin-top: ${core.spacing.large};
			}
		`}
`

const ActionCard = styled(Link)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 ${core.spacing['xx-small']};
	height: 10rem;
	border: ${core.border['x-small']} solid ${core.color.brand.grey[60]};
	border-radius: ${core.outline['x-large']};
	text-decoration: none;

	:hover {
		border: ${core.border.small} solid ${core.color.brand.purple[60]};
		transition: box-shadow 0.3s ease-out;
	}

	:focus-visible {
		border: ${core.border.medium} solid ${core.color.primary[10]};
		outline: none;
	}

	@media ${core.mediaquery.max['x-small']} {
		height: 8.5rem;
	}
`

const CardTitle = styled.p`
	${core.type.alma.ui.medium};
	font-size: ${core.font.scale[2]};
	line-height: ${core['line-height'].small};
	color: ${core.color.primary[10]};
	margin: ${core.spacing.medium} 0 0 0;

	@media ${core.mediaquery.max['x-small']} {
		font-size: ${core.font.scale[0]};
		${core.type.alma.ui.normal};
	}
`

const ActionCardWithGA = ({ children, ...rest }: LinkProps) => (
	<GALinkEvent>
		<ActionCard {...rest}>{children}</ActionCard>
	</GALinkEvent>
)

const FrontPage = () => {
	const { data: user } = useUser()
	const loggedIn = isUserLoggedIn(user)
	const headingText = loggedIn
		? `${user?.firstname}, tervetuloa Oma asiointiin!`
		: 'Tervetuloa Oma asiointiin!'

	const shouldShowLoginPrompt = getShowTunnusLoginPrompt() === 'true'
	const loginPromptCallMade = getPrepareToRemoveLoginPromptSS() === 'true'

	if (shouldShowLoginPrompt && !loginPromptCallMade) {
		setPrepareToRemoveLoginPromptSS('true')
		/**
		 * This is an automated call which gets triggered if the user tried to access a protected route, or if a user
		 * has been redirected from the group user error page after a logout. This call has been placed inside a settimeout because
		 * immediately calling this after a logout causes the opening prompt to fail.
		 */
		setTimeout(() => {
			almaLoginUtils.openAlmaLogin({ source: 'oma-asiointi' })
		})
		/**
		 * This takes care of clearing the session storage variables if the Alma login opened as a modal
		 */
		if (document) {
			waitForElmById('alma-tunnus-button-login').then(() => {
				removeShowTunnusLoginPrompt()
				removePrepareToRemoveLoginPromptSS()
			})
		}
	}

	return (
		<FrontPageWrapper>
			<Head
				title='Etusivu - oma asiointi - Alma Media'
				description='Oma asiointi -sivuilla hoidat tärkeimmät Alma Talentin ja Iltalehti Plussan tilausten hallintaan liittyvät asiat silloin kun sinulle sopii.'
			/>
			<IllustrationContent>
				<PageHeading size='medium, @min.small=x-large, @min.medium=xx-large'>
					{headingText}
				</PageHeading>
				<Illustration />
			</IllustrationContent>
			<CardContent>
				{loggedIn && (
					<Email
						paddingMaxMedium={{
							top: 'core.spacing.xx-large',
							bottom: 'core.spacing.xx-large',
						}}
						paddingMaxSmall={{
							top: 'core.spacing.x-large',
							bottom: 'core.spacing.x-large',
						}}
						paddingMaxXSmall={{
							top: 'core.spacing.large',
							bottom: 'core.spacing.large',
						}}
					>
						{user?.email}
					</Email>
				)}
				<Cards $addSpacing={!loggedIn}>
					<ActionCardWithGA
						state={{ pageType: 'sectionfrontpage' }}
						to={copyTexts['Omat tiedot'].url}
					>
						<Icon.CoreUserDefault
							size='core.size.icon.large'
							color='core.alma.color.brand.purple'
						/>
						<CardTitle>{copyTexts['Omat tiedot'].text}</CardTitle>
					</ActionCardWithGA>
					<ActionCardWithGA
						state={{ pageType: 'sectionfrontpage' }}
						to={copyTexts.nav['Omat tilaukset'].url}
					>
						<Icon.CoreUserCheck
							size='core.size.icon.large'
							color='core.alma.color.brand.purple'
						/>
						<CardTitle>{copyTexts.nav['Omat tilaukset'].text}</CardTitle>
					</ActionCardWithGA>
					<ActionCardWithGA
						state={{ pageType: 'sectionfrontpage' }}
						to={copyTexts.nav['Osoitteen muutos'].url}
					>
						<Icon.CorePin
							size='core.size.icon.large'
							color='core.alma.color.brand.purple'
						/>
						<CardTitle>{copyTexts.nav['Osoitteen muutos'].text}</CardTitle>
					</ActionCardWithGA>
					<ActionCardWithGA
						state={{ pageType: 'sectionfrontpage' }}
						to={copyTexts.nav['Jakelun keskeytys'].url}
					>
						<Icon.CorePauseCircle
							size='core.size.icon.large'
							color='core.alma.color.brand.purple'
						/>
						<CardTitle>{copyTexts.nav['Jakelun keskeytys'].text}</CardTitle>
					</ActionCardWithGA>
					<ActionCardWithGA
						state={{ pageType: 'sectionfrontpage' }}
						to={copyTexts.nav['Usein kysyttyä'].url}
					>
						<Icon.CoreQuestionCircle
							size='core.size.icon.large'
							color='core.alma.color.brand.purple'
						/>
						<CardTitle>{copyTexts.nav['Usein kysyttyä'].text}</CardTitle>
					</ActionCardWithGA>
					<ActionCardWithGA
						state={{ pageType: 'sectionfrontpage' }}
						to={copyTexts.nav['Ilmoita jakeluongelmasta'].url}
					>
						<Icon.CoreMegaphone
							size='core.size.icon.large'
							color='core.alma.color.brand.purple'
						/>
						<CardTitle>
							{copyTexts.nav['Ilmoita jakeluongelmasta'].text}
						</CardTitle>
					</ActionCardWithGA>
				</Cards>
			</CardContent>
		</FrontPageWrapper>
	)
}

export default FrontPage
