import React, { useState } from 'react'
import {
	Outlet,
	useLocation,
	useOutletContext,
	useParams,
} from 'react-router-dom'
import styled from 'styled-components'
import {
	core,
	Heading,
	Icon,
	PrimaryButton,
	SecondaryButton,
} from '@almamedia/talent-media-component-library'
import useEndSubscription from '../../api/subscriptions/useEndSubscription'
import useSubscription from '../../api/subscriptions/useSubscription'
import { getMediaSlugs } from '../../utils/mediaUtils'
import { MediaSlugs } from '../../types/media'
import useUser from '../../api/user/useUser'
import TopBreadCrumbLink from '../common/TopBreadCrumbLink'
import { Subscription } from '../../types/subscription'
import copyTexts from '../../assets/copyTexts'
import {
	activeFamilyUsers,
	isSijoittaPakettiSubscription,
	hasSubscriptionEnded,
} from '../../utils/subscriptionUtils'
import { OrganizationId } from '../../types/organization'
import { getCancellationTag } from '../../utils/selligentUtils'
import GALinkEvent from '../common/GALinkEvent'
import { StyledLink } from '../common'
import Head from '../common/Head'
import { OTHER } from './CancellationSurvey'
import useReconsiderOrderedAnalytics from './useReconsiderOrderedAnalytics'
import { useApplicationContext } from '../../App'

const Wrapper = styled.main`
	max-width: ${core.size.template['page-wrapper']};
	margin: 0 auto ${core.spacing['xxxx-large']} auto;

	@media ${core.mediaquery.max.small} {
		max-width: 40.625rem;
	}

	@media ${core.mediaquery.max['x-small']} {
		margin: 0 auto ${core.spacing['xx-large']} auto;
	}
`

const BreadCrumbWrapper = styled.div`
	margin: ${core.spacing['x-large']} ${core.spacing.large} 0
		${core.spacing.large};

	a {
		margin: 0;
	}

	@media ${core.mediaquery.max['x-small']} {
		margin: ${core.spacing.large} ${core.spacing.medium} 0
			${core.spacing.medium};
	}
`

const StyledHeading = styled(Heading.Subtitle)`
	text-align: center;
	margin: 0 ${core.spacing.large};
	white-space: pre-wrap;
	padding: ${core.spacing['xx-large']} 0;

	@media ${core.mediaquery.max['x-small']} {
		margin: 0 ${core.spacing.medium};
		padding: ${core.spacing['x-large']} 0;
	}
`

const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 28rem;
	margin: 0 auto;
	gap: ${core.spacing.medium};
	padding-top: ${core.spacing['xx-large']};

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing['xx-large']} ${core.spacing.medium} 0
			${core.spacing.medium};
	}
`

const ErrorMessage = styled.div`
	${core.type.alma.ui.normal};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height'].medium};
	color: ${core.color.status.alert[30]};
	display: flex;
	align-items: center;

	> svg {
		flex-shrink: 0;
	}
`

const getHeadingText = (pathname: string, firstName?: string) => {
	if (pathname.includes('peruutus-kysely')) {
		return 'Auttaisitko meitä ymmärtämään, miksi \n haluat peruuttaa tilauksesi'
	}
	if (pathname.includes('peruutus-tarjous')) {
		return `${firstName}, ennen kun lähdet, kiinnostaisiko \n sinua tämä tarjous?`
	}
	if (pathname.includes('peruutus-ankkuri')) {
		return `${firstName}, älä menetä etuasi`
	}
	if (pathname.includes('peruutus-perhe')) {
		return `${firstName}, pysy tilaajana`
	}
	if (pathname.includes('peruutus-vahvistus')) {
		return undefined
	}
	return `Hei ${firstName}! \n Pysy tilaajana, niin et menetä näitä etuja.`
}

const getButtonUrl = (
	pathname: string,
	isILAndroidApp: boolean,
	id?: string,
	subscriptionData?: Subscription
) => {
	if (pathname.includes('peruutus-kysely')) {
		if (activeFamilyUsers(subscriptionData).length) {
			return `/tilaukset/${id}/peruutus-perhe`
		} else if (subscriptionData?.reconsiderPrice !== null && !isILAndroidApp) {
			return subscriptionData?.reconsiderPrice.isAnchor
				? `/tilaukset/${id}/peruutus-ankkuri`
				: `/tilaukset/${id}/peruutus-tarjous`
		}
		return `/tilaukset/${id}/peruutus-vahvistus`
	}
	if (
		pathname.includes('peruutus-tarjous') ||
		pathname.includes('peruutus-perhe')
	) {
		return `/tilaukset/${id}/peruutus-vahvistus`
	}
	if (pathname.includes('peruutus-vahvistus')) {
		return undefined
	}
	return `/tilaukset/${id}/peruutus-kysely`
}

type ContextType = {
	selectedButton: string
	setSelectedButton: React.Dispatch<React.SetStateAction<string>>
	otherInputValue: string
	setOtherInputValue: React.Dispatch<React.SetStateAction<string>>
	mediaSlugs: MediaSlugs
	cancelled: boolean
	organization: OrganizationId
	endDate?: string
	isSijoittaPakettiSubscription?: boolean
	showErrorMessage?: boolean
}

export function useCancellationContext() {
	return useOutletContext<ContextType>()
}

const CancellationLayout = () => {
	const [selectedButton, setSelectedButton] = useState('')
	const [otherInputValue, setOtherInputValue] = useState('')
	const [showErrorMessage, setShowErrorMessage] = useState(false)
	const [cancelled, setCancelled] = useState(false)
	useReconsiderOrderedAnalytics()

	const { data: user } = useUser()
	const { pathname } = useLocation()
	const { id } = useParams()
	const { isILAndroidApp } = useApplicationContext()

	const { data, isLoading: subscriptionIsLoading } = useSubscription(id)
	const mediaSlugs = getMediaSlugs(data?.subscription.organization)

	const { trigger, data: endSubscriptionData } = useEndSubscription(id)

	const headingText = getHeadingText(pathname, user?.firstname)
	const buttonLink = getButtonUrl(pathname, isILAndroidApp, id, data)
	const cancelOnClick =
		pathname.includes('peruutus-tarjous') ||
		pathname.includes('peruutus-ankkuri') ||
		pathname.includes('peruutus-perhe') ||
		(pathname.includes('peruutus-kysely') &&
			data?.reconsiderPrice === null &&
			!activeFamilyUsers(data).length)
	const isOnConfirmationPage = pathname.includes('peruutus-vahvistus')

	const subscriptionLink =
		data && hasSubscriptionEnded(data as Subscription)
			? `../paattyneet-tilaukset/${id}`
			: `../voimassa-olevat-tilaukset/${id}`

	const disableNavigationUntilCancelReasonIsChosen =
		pathname.includes('peruutus-kysely') &&
		(selectedButton === '' ||
			(selectedButton === OTHER && otherInputValue === ''))

	if (subscriptionIsLoading) return null

	return (
		<Wrapper>
			<Head title='Peruuta tilaus - oma asiointi - Alma Media' />
			{!isOnConfirmationPage && (
				<BreadCrumbWrapper>
					<TopBreadCrumbLink
						to={subscriptionLink}
						text={copyTexts.subscriptionManagementPage['Tilauksen hallinta']}
					/>
				</BreadCrumbWrapper>
			)}
			{headingText && (
				<StyledHeading size='medium, @min.small=x-large'>
					{headingText}
				</StyledHeading>
			)}
			<Outlet
				context={{
					selectedButton,
					setSelectedButton,
					otherInputValue,
					setOtherInputValue,
					mediaSlugs,
					cancelled,
					organization: data?.subscription.organization,
					endDate: endSubscriptionData?.endDate || data?.subscription.endDate,
					isSijoittaPakettiSubscription: isSijoittaPakettiSubscription(data),
					showErrorMessage,
				}}
			/>
			{buttonLink && (
				<ButtonsContainer>
					<GALinkEvent>
						<StyledLink to='voimassa-olevat-tilaukset'>
							<PrimaryButton forwardedAs='div'>
								{copyTexts.cancelFlow.layout['Pysy nykyisessä tilauksessa']}
							</PrimaryButton>
						</StyledLink>
					</GALinkEvent>
					{!disableNavigationUntilCancelReasonIsChosen ? (
						<GALinkEvent>
							<StyledLink
								to={buttonLink}
								state={{ prevPath: location.pathname }}
								onClick={() => {
									if (cancelOnClick) {
										setCancelled(true)

										const cancellationTag = getCancellationTag(
											mediaSlugs.siteShortSlug
										)
										if (cancellationTag) {
											window.BT?.addTag(cancellationTag, '1')
										}

										if (selectedButton !== 'Muu syy') {
											trigger({ reason: selectedButton })
										} else {
											trigger({ reason: otherInputValue })
										}
									}
								}}
							>
								<SecondaryButton forwardedAs='div'>
									{copyTexts.subscription['Peruuta tilaus']}
								</SecondaryButton>
							</StyledLink>
						</GALinkEvent>
					) : (
						<>
							<SecondaryButton onClick={() => setShowErrorMessage(true)}>
								{copyTexts.subscription['Peruuta tilaus']}
							</SecondaryButton>
							{showErrorMessage && (
								<ErrorMessage id='cancellation-layout-error'>
									<Icon.CoreExclamationCircle size='core.size.icon.medium' />
									<span css={{ marginLeft: core.spacing['xx-small'] }}>
										{copyTexts.cancelFlow.layout['Valitse peruuttamisen syy']}
									</span>
								</ErrorMessage>
							)}
						</>
					)}
				</ButtonsContainer>
			)}
		</Wrapper>
	)
}

export default CancellationLayout
