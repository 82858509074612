import React from 'react'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
	Badge,
	core,
	Card,
	Heading,
	Icon,
	PrimaryButton,
	useMediaMatcher,
	Link,
} from '@almamedia/talent-media-component-library'
import { Email } from '../common'
import {
	hasSubscriptionEnded,
	isSubscriptionEndingSoon,
	getSubscriptionName,
	isProductRetired,
	shouldShowReorderButton,
	isSubscriptionStartingInFuture,
} from '../../utils/subscriptionUtils'
import OrganizationLogo from '../common/OrganizationLogo'
import SubscriptionDataTable from './SubscriptionDataTable'
import TopBreadCrumbLink from '../common/TopBreadCrumbLink'
import copyTexts from '../../assets/copyTexts'
import { getMediaSlugs } from '../../utils/mediaUtils'
import useSubscription from '../../api/subscriptions/useSubscription'
import useUser from '../../api/user/useUser'
import GALinkEvent from '../common/GALinkEvent'
import useProductDetails from '../../api/products/useProductDetails'
import useActiveSubscriptions from '../../api/subscriptions/useActiveSubscriptions'
import Head from '../common/Head'
import { useApplicationContext } from '../../App'

type Status = 'Voimassa' | 'Päättymässä' | 'Päättynyt' | 'Tulossa'

const Wrapper = styled.div`
	max-width: ${core.size.template['page-wrapper']};
	padding: ${core.spacing['x-large']} ${core.spacing.large}
		${core.spacing['xxxx-large']};
	margin: 0 auto;

	@media ${core.mediaquery.max.small} {
		max-width: ${core.size.template['page-wrapper-mobile']};
	}

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing.large} 0 ${core.spacing['xx-large']};
	}
`

const SubscriptionCard = styled(Card)`
	box-sizing: content-box;
	padding: ${core.spacing['x-large']} ${core.spacing['x-large']}
		${core.spacing['xx-large']};
	display: flex;
	flex-direction: column-reverse;
	justify-content: center;
	gap: ${core.spacing.large};
	margin-bottom: ${core.spacing['xx-large']};

	@media ${core.mediaquery.max.small} {
		padding: ${core.spacing.large} ${core.spacing.large}
			${core.spacing['x-large']};
	}

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing.large} ${core.spacing.medium}
			${core.spacing['x-large']};
		margin-bottom: ${core.spacing['x-large']};
	}
`

const CardSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: ${core.spacing.large};
	justify-content: space-between;

	@media ${core.mediaquery.max.small} {
		flex-direction: column;
		align-items: stretch;
	}
`

const TitleContainer = styled.div`
	text-align: left;
	display: flex;
	flex-direction: column;
	gap: ${core.spacing['xx-small']};
`

const LogoTitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${core.spacing.large};
	align-items: center;

	@media ${core.mediaquery.max['x-small']} {
		gap: ${core.spacing.small};
	}
`

const LogoContainer = styled.div`
	height: ${core.size.target['xxx-large']};
	width: ${core.size.target['xxx-large']};
	background-color: ${core.color.primary[100]};
	display: flex;
	align-items: center;

	@media ${core.mediaquery.max['x-small']} {
		height: ${core.size.target['xx-large']};
		width: ${core.size.target['xx-large']};
	}
`

const SubscriptionType = styled.h4`
	${core.type.alma.ui.normal};
	color: ${core.color.primary[10]};
	margin: 0;
	line-height: ${core['line-height'].small};
	font-size: ${core.font.scale[2]};

	@media ${core.mediaquery.max['x-small']} {
		line-height: ${core['line-height'].medium};
		font-size: ${core.font.scale[1]};
	}
`

const getBackgroundColor = (status?: Status) => {
	switch (status) {
		case 'Päättymässä':
			return core.color.primary[10]
		case 'Tulossa':
			return core.color.primary[100]
		default:
			return core.color.primary[85]
	}
}

const StyledBadge = styled(Badge)<{
	status?: Status
}>`
	align-self: flex-start;
	${core.type.alma.ui.medium};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height']['none']};
	border: none;
	border-radius: ${core.shape.rounded};
	background-color: ${core.color.status.success[95]}; // active
	color: ${core.color.status.success[20]}; // active

	${({ status }) =>
		status !== 'Voimassa' &&
		css`
			background: ${getBackgroundColor(status)};
			color: ${status === 'Päättymässä'
				? core.color.primary[100] // ending
				: core.color.primary[10]}; // ended
		`}
`

const StyledLink = styled(Link)`
	align-self: baseline;
	> span {
		text-decoration: none;
	}
`

const Button = styled(PrimaryButton)`
	${core.type.alma.ui.medium};
	background: ${core.color.primary[10]};
	height: ${core.size.target.medium};
`

const getStatus = (
	hasEnded?: boolean,
	endingSoon?: boolean,
	startsInFuture?: boolean
): Status => {
	if (startsInFuture) return 'Tulossa'
	if (endingSoon) return 'Päättymässä'
	if (hasEnded) return 'Päättynyt'
	return 'Voimassa'
}

const SubscriptionManagementPage = ({ parentUrl }: { parentUrl: string }) => {
	const { id } = useParams()
	const { data } = useSubscription(id)
	const { data: productDetails } = useProductDetails(
		data?.subscription.productInstances?.[0].code
	)
	const { data: activeSubscriptions } = useActiveSubscriptions()
	const { data: user } = useUser()
	const { isILAndroidApp } = useApplicationContext()

	const hasEnded = data && hasSubscriptionEnded(data)
	const endingSoon = data && isSubscriptionEndingSoon(data)
	const startsInFuture = data && isSubscriptionStartingInFuture(data)
	const _isProductRetired = isProductRetired(productDetails)
	const _shouldshowReorderButton =
		shouldShowReorderButton(hasEnded, data, activeSubscriptions) &&
		!isILAndroidApp

	const status = getStatus(hasEnded, endingSoon, startsInFuture)

	const { subscriptionMediaName, subscriptionName } = getSubscriptionName(
		data?.products[0].name || ''
	)
	const organization = data?.subscription?.organization || 'Klfi'
	const mediaSlugs = getMediaSlugs(organization)

	const matchedQueries = useMediaMatcher([
		'core.mediaquery.max.x-small',
		'core.mediaquery.max.small',
	])

	return (
		<Wrapper>
			<Head title='Tilauksen tiedot - oma asiointi - Alma Media' />
			<TopBreadCrumbLink to={`/${parentUrl}`} text={'Omat tilaukset'} />
			<Heading.Subtitle
				level='h1'
				size='medium, @min.small=x-large'
				css={{ textAlign: 'center' }}
			>
				{copyTexts.subscriptionManagementPage['Tilauksen hallinta']}
			</Heading.Subtitle>
			<>
				<Email
					paddingMaxMedium={{
						top: 'core.spacing.medium',
						bottom: 'core.spacing.xx-large',
					}}
					paddingMaxSmall={{
						top: 'core.spacing.medium',
						bottom: 'core.spacing.xx-large',
					}}
					paddingMaxXSmall={{
						top: 'core.spacing.small',
						bottom: 'core.spacing.x-large',
					}}
				>
					{user?.email}
				</Email>
				<SubscriptionCard backgroundColor='core.color.brand.grey.95'>
					<CardSection>
						<LogoTitleContainer>
							<LogoContainer>
								<OrganizationLogo
									size='core.size.icon.x-large'
									{...(matchedQueries.has('core.mediaquery.max.x-small') && {
										size: 'core.size.icon.large',
									})}
									organization={organization}
								/>
							</LogoContainer>
							<TitleContainer>
								<Heading.Subtitle size='medium, @min.small=large'>
									{subscriptionMediaName}
								</Heading.Subtitle>
								<SubscriptionType>{subscriptionName}</SubscriptionType>
							</TitleContainer>
						</LogoTitleContainer>
						{_shouldshowReorderButton && !_isProductRetired && (
							<GALinkEvent>
								<StyledLink href={mediaSlugs?.campaignPaywall}>
									<Button tabIndex={-1}>Tilaa {subscriptionMediaName}</Button>
								</StyledLink>
							</GALinkEvent>
						)}
					</CardSection>
					<StyledBadge
						text={status}
						status={status}
						icon={
							status === 'Voimassa' ? (
								<Icon.CoreCheckCircle
									size='core.size.icon.medium'
									color='core.color.status.success.20'
								/>
							) : undefined
						}
					/>
				</SubscriptionCard>
				<SubscriptionDataTable />
				{
					// FIXME: Enable offer when we have real data available.
					//	<Offer />
				}
			</>
		</Wrapper>
	)
}

export default SubscriptionManagementPage
