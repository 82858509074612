import {
	Children,
	ReactElement,
	ReactNode,
	cloneElement,
	isValidElement,
	useCallback,
} from 'react'
import { isGAEnabled } from '../../utils/consentUtils'
import useConsent from '../../api/consent/useConsent'

const getDataLayer = () => window.dataLayer || []
const getChildTextProp = (child: ReactElement) =>
	child?.props?.children || child?.props?.label

const GALinkEvent = ({
	children,
	customData,
}: {
	children: ReactNode
	customData?: object
}) => {
	const { data: consent } = useConsent()

	const handleClick = (url: string, text?: string) => {
		if (isGAEnabled(consent)) {
			window.dataLayer = getDataLayer()
			window.dataLayer.push({
				event: 'link_click',
				link_text: text,
				link_url: url,
				...customData,
			})
		}
	}

	/**
	 * This handles finding the link text from children.
	 *
	 * As there might be nested ReactElements as children, this HoC component uses recursive pattern
	 * to find link text by looping through the child elements and eventually it finds child element which is type of
	 * string and then the loop stops and that string value is returned.
	 */
	const findLinkText = useCallback((children: ReactNode) => {
		let text = ''
		Children.forEach(children, (child) => {
			if (typeof child === 'string') {
				text = child
			}

			if (isValidElement(child)) {
				const textProp = getChildTextProp(child)

				if (textProp) {
					text = findLinkText(textProp)
				}
			}
		})

		return text
	}, [])
	const linkText = findLinkText(children)

	if (isValidElement(children)) {
		return cloneElement(children, {
			...children.props,
			onClick: () => {
				handleClick(children.props.to || children.props.href, linkText)

				if (children.props.onClick) children.props.onClick()
			},
		})
	}

	return null
}

export default GALinkEvent
