import useSWRMutation from 'swr/mutation'
import URLS from '../urls'
import { parseResponse } from '../'
import { mutate } from 'swr'

async function fetcher(url: string) {
	return await fetch(url, {
		method: 'POST',
	}).then((res) => parseResponse(res))
}

export default (id?: string) => {
	return useSWRMutation(
		`${URLS.CUSTOMER_SUBSCRIPTIONS}/${id}/make-reconsider-order`,
		fetcher,
		{
			onSuccess: () => {
				mutate(URLS.CUSTOMER_SUBSCRIPTIONS)
			},
		}
	)
}
