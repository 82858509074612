import React from 'react'
import {
	Logo,
	MediaLogo,
	Types,
} from '@almamedia/talent-media-component-library'
import { OrganizationId } from '../../types/organization'

const TALENT_MEDIAS = ['kl', 'te', 'ap', 'mu', 'mb', 'tt', 'tv', 'us'] as const
const NON_TALENT_MEDIAS = ['il', 'at-kirjat', 'pro'] as const

type TalentMedias = (typeof TALENT_MEDIAS)[number]
type NonTalentMedias = (typeof NON_TALENT_MEDIAS)[number]

type OrganizationLogoProps = {
	organization: OrganizationId
	size?: Types.Size
	useMainLogo?: boolean
}

const getOrganizationSlug = (organization: OrganizationId) => {
	switch (organization) {
		case 'Talouselama': {
			return 'te'
		}
		case 'Arvopaperi': {
			return 'ap'
		}
		case 'Mediuutiset': {
			return 'mu'
		}
		case 'TekTal': {
			return 'tt'
		}
		case 'Tivi': {
			return 'tv'
		}
		case 'Mikrobitti': {
			return 'mb'
		}
		case 'UusiSuomi': {
			return 'us'
		}
		case 'Iltalehti': {
			return 'il'
		}
		case 'AlmaTalentKirjat': {
			return 'at-kirjat'
		}
		case 'Pro': {
			return 'pro'
		}
		case 'Klfi':
		default:
			return 'kl'
	}
}

const NonTalentMediaLogo = ({
	media,
	size,
	mainLogo,
}: {
	media: NonTalentMedias
	size: Types.Size
	mainLogo?: boolean
}) => {
	switch (media) {
		case 'il':
			return mainLogo ? (
				<Logo.IlMain size={size} />
			) : (
				<Logo.IlMobile size={size} />
			)
		case 'at-kirjat':
			return mainLogo ? (
				<Logo.ATKirjatMain size={size} />
			) : (
				<Logo.ATKirjatMobile size={size} />
			)
		case 'pro':
			return <Logo.CoreAlmaTalentMain size={size} />
		default:
			return null
	}
}

const OrganizationLogo = ({
	organization,
	size = 'core.size.icon.large',
	useMainLogo = false,
}: OrganizationLogoProps) => {
	const mediaSlug = getOrganizationSlug(organization)
	if (TALENT_MEDIAS.includes(mediaSlug as TalentMedias)) {
		return (
			<MediaLogo
				media={mediaSlug as TalentMedias}
				size={size}
				isMobile={!useMainLogo}
			/>
		)
	}
	if (NON_TALENT_MEDIAS.includes(mediaSlug as NonTalentMedias)) {
		return (
			<NonTalentMediaLogo
				media={mediaSlug as NonTalentMedias}
				size={size}
				mainLogo={useMainLogo}
			/>
		)
	}
	return null
}

export default OrganizationLogo
