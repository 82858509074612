import {
	Paragraph,
	PrimaryButton,
	core,
} from '@almamedia/talent-media-component-library'
import React from 'react'
import { GreyBackground, StyledHeadline, Wrapper } from './common'
import { ButtonWrapper } from './GroupUserErrorPage'
import { StyledLink } from '../common/index'

const ErrorPage = () => {
	return (
		<>
			<GreyBackground>
				<Wrapper>
					<StyledHeadline level='h1'>
						Valitettavasti sivua ei löydy
					</StyledHeadline>
					<Paragraph
						css={{
							textAlign: 'center',
							maxWidth: '35rem',
							padding: 0,
							margin: 0,
						}}
					>
						Hakemaasi sivua ei löytynyt. Tarkistathan että osoite on kirjoitettu
						oikein. Sivu on myös voitu poistaa, siirtää tai nimetä uudelleen.
						Pääset siirtymään Oma asiointi -palvelun etusivulle klikkaamalla
						alla olevaa linkkiä.
					</Paragraph>
				</Wrapper>
			</GreyBackground>
			<ButtonWrapper>
				<StyledLink to='/'>
					<PrimaryButton
						forwardedAs='div'
						css={`
							@media ${core.mediaquery.min.small} {
								min-width: 35rem;
							}
						`}
					>
						Oma asionti - etusivulle
					</PrimaryButton>
				</StyledLink>
			</ButtonWrapper>
		</>
	)
}

export default React.memo(ErrorPage)
