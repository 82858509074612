import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import {
	core,
	Heading,
	Icon,
	Link as DSLink,
} from '@almamedia/talent-media-component-library'
import useSubscription from '../../api/subscriptions/useSubscription'
import DataColumns, { ColumnData } from '../common/DataColumns'
import copyTexts from '../../assets/copyTexts'
import { StyledLink } from '../common'
import {
	formatDate,
	getEndDate,
	hasSubscriptionEnded,
	isSubscriptionRecurring,
} from '../../utils/subscriptionUtils'
import GALinkEvent from '../common/GALinkEvent'

const Wrapper = styled.div`
	width: 100%;
	text-align: start;

	@media ${core.mediaquery.min.medium} {
		max-width: 48%;
	}
`

const CTAWrapper = styled.div<{ $noBottomBorder?: boolean }>`
	display: flex;
	align-items: center;
	padding: ${core.spacing.medium} 0;
	border-top: ${core.border['x-small']} solid black;
	border-bottom: ${core.border['x-small']} solid black;
	${({ $noBottomBorder }) => $noBottomBorder && 'border-bottom: 0'};
`

const StyledDSLink = styled(DSLink)`
	display: flex;
	align-items: center;
	margin-left: ${core.spacing.medium};
`

const SubscriptionAndBillingInfoLeft = () => {
	const { id } = useParams()
	const { data } = useSubscription(id)
	const subscriptionTypeName =
		data && isSubscriptionRecurring(data) ? 'Jatkuva' : 'Määräaikainen'
	const subscriptionHasEndDate = data?.subscription.endDate
	const _subscriptionHasEnded = data && hasSubscriptionEnded(data)

	const dataColumn: (ColumnData | null)[] = useMemo(
		() => [
			...[
				data
					? {
							label: copyTexts.subscription.dataColumn['Tilausmuoto'],
							value: subscriptionTypeName,
					  }
					: null,
			],
			...[
				data
					? {
							label: copyTexts.subscription.dataColumn['Tilausnumero'],
							value: data?.subscription?.agreementNumber,
					  }
					: null,
			],
			...[
				data
					? {
							label: copyTexts.subscription.dataColumn['Alkamispäivä'],
							value: formatDate(data?.subscription?.startDate),
					  }
					: null,
			],
			...[
				data
					? {
							label: copyTexts.subscription.dataColumn['Päättymispäivä'],
							value: getEndDate(data?.subscription?.endDate),
					  }
					: null,
			],
		],
		[data, subscriptionTypeName]
	)

	return (
		<Wrapper>
			<Heading.Subtitle level='h2' css={{ fontSize: core.font.scale[2] }}>
				{copyTexts.subscription['Tilauksen tiedot']}
			</Heading.Subtitle>
			<DataColumns
				data={dataColumn}
				withRowDivider
				withoutTopDivider
				withoutBottomDivider
				textColumnCss={{
					marginTop: core.spacing.medium,
					marginBottom: core.spacing.medium,
				}}
				css={{
					marginTop: core.spacing.large,
					marginBottom: core.spacing.large,
				}}
			/>
			{subscriptionTypeName === 'Jatkuva' &&
				!subscriptionHasEndDate &&
				!_subscriptionHasEnded && (
					<GALinkEvent>
						<StyledLink
							to={
								data?.subscription.organization === 'AlmaTalentKirjat'
									? `../tilaukset/${id}/peruutus-kysely`
									: `../tilaukset/${id}/peruutus`
							}
							$noOutlineOffset
						>
							<CTAWrapper id='first-link'>
								<Icon.CoreChevronRight
									size='core.size.icon.large'
									color='core.alma.color.brand.black'
								/>
								<StyledDSLink forwardedAs='div'>
									{copyTexts.subscription['Peruuta tilaus']}
								</StyledDSLink>
							</CTAWrapper>
						</StyledLink>
					</GALinkEvent>
				)}
		</Wrapper>
	)
}

export default SubscriptionAndBillingInfoLeft
