import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
	Dialogue,
	core,
	SecondaryButton,
	Icon,
	PrimaryButton,
	Input,
	Badge,
	coreAsValues,
} from '@almamedia/talent-media-component-library'
import DataColumns, { ColumnData } from '../common/DataColumns'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { isValidEmail } from '../../utils'
import copyTexts from '../../assets/copyTexts'
import useAddFamilyUser from '../../api/subscriptions/useAddFamilyUser'
import useDeleteFamilyUser from '../../api/subscriptions/useDeleteFamilyUser'
import useCancelAddFamilyUser from '../../api/subscriptions/useCancelAddFamilyUser'
import {
	isMaxUsersReached,
	sortFamilyUsersMainToFirst,
} from '../../utils/subscriptionUtils'
import trapFocus from '../../utils/trapFocus'
import useSubscription from '../../api/subscriptions/useSubscription'

const MODAL_ID = 'FamilySubscriptionModal'

const ButtonContainer = styled.div`
	padding-bottom: ${core.spacing['xx-large']};
`

const InputContainer = styled.div`
	display: flex;
	align-items: start;
	text-align: left;
	margin-top: ${core.spacing['x-large']};

	@media ${core.mediaquery.max['x-small']} {
		margin-top: ${core.spacing.large};
		flex-direction: column;
	}
`

const StyledInput = styled(Input)`
	flex-grow: 1;
	margin-right: ${core.spacing['x-small']};

	label {
		${core.type.alma.ui.semi}
	}

	@media ${core.mediaquery.max['x-small']} {
		width: 100%;
	}
`

const AddUserButton = styled(PrimaryButton)`
	margin-top: calc(
		${coreAsValues.spacing['xx-small']} +
			${coreAsValues['line-height'].medium}rem
	);

	@media ${core.mediaquery.max['x-small']} {
		width: 100%;
		margin-top: ${core.spacing.large};
	}
`

const DeleteUserButton = styled(SecondaryButton)`
	@media ${core.mediaquery.max['x-small']} {
		width: 100%;
		margin-bottom: 0;
		margin-top: ${core.spacing.large};
	}
`

const SaveModalButton = styled(PrimaryButton)`
	width: 100%;
	margin-top: ${core.spacing['x-large']};

	@media ${core.mediaquery.min.small} {
		margin-top: ${core.spacing['xx-large']};
	}
`

const ActivationBadge = styled(Badge)``

type Props = {
	familyModalDisplayed: boolean
	setFamilyModalDisplayed: (prop: boolean) => void
}

const FamilySubscriptionModal = ({
	familyModalDisplayed,
	setFamilyModalDisplayed,
}: Props) => {
	const [emailToAdd, setEmailToAdd] = useState('')
	const [processingFamilySubscriber, setProcessingFamilySubscriber] =
		useState(-1)
	const [errorMessage, setErrorMessage] = useState('')
	const [subscriptionHasBeenEdited, setSubscriptionHasBeenEdited] =
		useState(false)
	const customInputRef = useRef<HTMLInputElement>(null)
	const { id } = useParams()
	const { data } = useSubscription(id)
	const familyOrderUsers = sortFamilyUsersMainToFirst(data)
	const { trigger: triggerAddFamilyUser, isMutating: isMutatingAddFamilyUser } =
		useAddFamilyUser(
			() => {
				setEmailToAdd('')
				setSubscriptionHasBeenEdited(true)
			},
			(err) => {
				console.error(err)
				/**
				 * We can assume that if e.errorCode exists, it came from public api and
				 *it is a string. If error happened somwhere else than in public api, e.errorCode is undefined.
				 */
				const errorMessage =
					err.errorCode && copyTexts.subscription.errorMessages[err.errorCode]
						? copyTexts.subscription.errorMessages[err.errorCode]
						: copyTexts.subscription.errorMessages.General
				customInputRef?.current?.setCustomValidity(errorMessage)
				setErrorMessage(errorMessage)
			}
		)
	const { trigger: triggerCancelAddFamilyUser, isMutating: isMutatingCancel } =
		useCancelAddFamilyUser(() => setSubscriptionHasBeenEdited(true))
	const { trigger: triggerDeleteFamilyUser, isMutating: isMutatingDelete } =
		useDeleteFamilyUser(() => setSubscriptionHasBeenEdited(true))
	const isMutatingCancelOrDelete = isMutatingCancel || isMutatingDelete

	useEffect(() => {
		if (familyModalDisplayed) {
			document.body.style.overflow = 'hidden'
			trapFocus(`#${MODAL_ID}`, 1, true)
		}

		return () => {
			document.body.style.overflow = 'visible'
		}
	}, [
		familyModalDisplayed,
		subscriptionHasBeenEdited,
		!isValidEmail(emailToAdd) || isMutatingAddFamilyUser,
	])

	const handleDeleteUserButtonClick = useCallback(
		(index: number) => {
			setProcessingFamilySubscriber(index)
			if (familyOrderUsers[index]?.id) {
				triggerDeleteFamilyUser({
					subscriptionId: data?.subscription.id,
					accountId: familyOrderUsers[index]?.id,
				})
			} else {
				triggerCancelAddFamilyUser({
					orderId: familyOrderUsers[index]?.orderId,
				})
			}
		},
		[
			data,
			familyOrderUsers,
			triggerDeleteFamilyUser,
			triggerCancelAddFamilyUser,
		]
	)

	const handleAddFamilyUser = useCallback(() => {
		setErrorMessage('')
		customInputRef?.current?.setCustomValidity('')
		triggerAddFamilyUser({
			subscriptionId: id,
			emailAddress: emailToAdd,
		})
	}, [id, emailToAdd, triggerAddFamilyUser])

	const columnData: (ColumnData | null)[] = useMemo(
		() =>
			familyOrderUsers.map(
				({ id, emailAddress, active, mainUser }, i): ColumnData => ({
					label: `${copyTexts.subscription.Lukuoikeus} ${i + 1}/${
						data?.familyOrderDetails?.maxNumberOfUsers
					}`,
					value: emailAddress,
					action:
						mainUser === false ? (
							<DeleteUserButton
								title={`${copyTexts.subscription['poista lukuoikeus käyttäjältä']} emailAddress}`}
								icon={<Icon.CoreTrashDefault size='core.size.icon.medium' />}
								onClick={() => handleDeleteUserButtonClick(i)}
								loading={
									isMutatingCancelOrDelete && processingFamilySubscriber === i
								}
								disabled={
									isMutatingCancelOrDelete && processingFamilySubscriber === i
								}
							>
								{copyTexts.subscription.Poista}
							</DeleteUserButton>
						) : undefined,
					badge:
						active === false ? (
							<ActivationBadge
								text={`${copyTexts.subscription['Odottaa aktivointia']}`}
								icon={
									<Icon.CoreExclamationCircle size='core.size.icon.medium' />
								}
								css={{
									marginTop: `${core.spacing.small}`,
								}}
							/>
						) : undefined,
					key: id,
				})
			),
		[
			data,
			familyOrderUsers,
			processingFamilySubscriber,
			isMutatingCancelOrDelete,
			handleDeleteUserButtonClick,
		]
	)

	if (!familyModalDisplayed) return null

	return (
		<Dialogue
			open
			dismissOnClickOutside
			dismissOnScrollOutside={false}
			dismissOnResize={false}
			overlay
			type='dialogue'
			onClose={() => setFamilyModalDisplayed(false)}
			aria-labelledby='family-modal-heading'
			id={MODAL_ID}
		>
			<Dialogue.Content id='family-modal-heading' level='heading'>
				{copyTexts.subscription.Perhetilaus}
			</Dialogue.Content>
			<DataColumns
				data={columnData}
				withRowGap
				withRowDivider
				withoutTopDivider
				textColumnAsColumn
				textColumnCss={{
					textAlign: 'left',
				}}
				css={{
					marginTop: core.spacing.medium,
				}}
				rowCss={{
					paddingTop: core.spacing.medium,
					paddingBottom: core.spacing.medium,
				}}
			/>
			{isMaxUsersReached(data) && (
				<InputContainer>
					<StyledInput
						label={copyTexts.subscription['Lisää lukuoikeus']}
						value={emailToAdd}
						onChange={(e) => setEmailToAdd(e.target.value)}
						errorMessage={errorMessage}
						showErrorMessage={errorMessage !== ''}
						isValidationOn
						ref={customInputRef}
						instantValidationOnBlur={false}
					/>
					<AddUserButton
						onClick={handleAddFamilyUser}
						disabled={!isValidEmail(emailToAdd) || isMutatingAddFamilyUser}
						loading={isMutatingAddFamilyUser}
						icon={<Icon.CorePlusCircle size='core.size.icon.medium' />}
					>
						{copyTexts.subscription.Lisää}
					</AddUserButton>
				</InputContainer>
			)}
			<ButtonContainer>
				<SaveModalButton
					disabled={!subscriptionHasBeenEdited}
					onClick={() => setFamilyModalDisplayed(false)}
				>
					{copyTexts.subscription.Tallenna}
				</SaveModalButton>
			</ButtonContainer>
		</Dialogue>
	)
}

export default FamilySubscriptionModal
