import React, { useCallback, useEffect, useState } from 'react'
import { mutate } from 'swr'
import GLOBALSTORAGE from '../../api/globalstorage'
import { Consent as ConsentType } from '../../types/user'
import { checkConsentProperties } from '../../utils/consentUtils'
import useConsent from '../../api/consent/useConsent'

const Consent = () => {
	const [isConsentAvailable, setIsConsentAvailable] = useState(false)
	const { data: globalConsentData } = useConsent()

	const setConsentData = useCallback(
		(event: Event & { detail: ConsentType }) => {
			const consentData = event.detail
			if (checkConsentProperties(consentData)) {
				mutate(GLOBALSTORAGE.CONSENT, {
					...globalConsentData,
					...consentData,
					consentsLoaded: true,
				})
			} else {
				console.error('cmp properties missing')
			}

			if (!isConsentAvailable) {
				window.removeEventListener(
					'alma_cmp_v2_consents_available_repetitive',
					setConsentData as EventListener,
					false
				)

				window.addEventListener(
					'alma_cmp_v2_has_optedin',
					setConsentData as EventListener,
					false
				)
				setIsConsentAvailable(true)
			}
		},
		[isConsentAvailable, globalConsentData]
	)

	useEffect(() => {
		window.addEventListener(
			'alma_cmp_v2_consents_available_repetitive',
			setConsentData as EventListener,
			false
		)
	}, [setConsentData])

	return null
}

export default React.memo(Consent)
