import React, { memo } from 'react'
import styled, { CSSProp, css } from 'styled-components'
import { core } from '@almamedia/talent-media-component-library'

const Row = styled.div<{
	withVerticalRowSpacing?: boolean
}>`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${({ withVerticalRowSpacing }) =>
		withVerticalRowSpacing &&
		css`
			@media ${core.mediaquery.max['x-small']} {
				padding: ${core.spacing.medium} 0;
			}
		`}

	@media ${core.mediaquery.max['x-small']} {
		flex-direction: column;
	}
`
const TextColumn = styled.div<{
	withVerticalRowSpacing?: boolean
	asColumn?: boolean
	hasBadge?: boolean
}>`
	${core.type.alma.ui.normal};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height'].medium};
	color: ${core.color.primary[10]};
	display: flex;
	flex-direction: column;
	gap: ${core.spacing['xxx-small']};
	flex: 1;

	@media ${core.mediaquery.min.small} {
		${({ asColumn }) => !asColumn && `flex-direction: row;`}
		gap: ${({ asColumn }) =>
			asColumn ? core.spacing['xxx-small'] : core.spacing.medium}
			${({ withVerticalRowSpacing }) =>
			withVerticalRowSpacing &&
			`
            margin-top: ${core.spacing.medium};
            margin-bottom: ${core.spacing.medium};
        `};
	}

	${({ hasBadge }) => hasBadge && `margin-bottom: ${core.spacing.small};`}
`

const ContentColumn = styled.div`
	text-align: left;

	@media ${core.mediaquery.max['x-small']} {
		width: 100%;
	}
`
const Label = styled.span`
	${core.type.alma.ui.heavy};
	width: 100%;
	word-break: break-all;

	@media ${core.mediaquery.min.small} {
		width: 11rem;
		word-break: normal;
	}
`

const WordBreakedSpan = styled.span`
	word-break: break-word;

	@media ${core.mediaquery.min.small} {
		word-break: normal;
	}
`

const Wrapper = styled.div<{
	withRowGap?: boolean
	withRowDivider?: boolean
	withoutTopSpacing?: boolean
	withoutBottomSpacing?: boolean
	withoutTopDivider?: boolean
	withoutBottomDivider?: boolean
}>`
	display: flex;
	flex-direction: column;
	${({ withRowGap }) => withRowGap && `gap: ${core.spacing.small};`}

	${({
		withRowDivider,
		withoutTopSpacing,
		withoutBottomSpacing,
		withoutTopDivider,
		withoutBottomDivider,
	}) => css`
		> ${Row} {
			${withRowDivider &&
			`
                border-bottom: ${core.border['x-small']} solid ${core.color.primary[85]};
            `}
			${withRowDivider &&
			!withoutTopDivider &&
			css`
				&:first-child {
					border-top: ${core.border['x-small']} solid ${core.color.primary[85]};
				}
			`}
            ${withRowDivider &&
			withoutBottomDivider &&
			css`
				&:last-child {
					border-bottom: none;
				}
			`}
            ${withoutTopSpacing &&
			css`
				&:first-child ${TextColumn} {
					margin-top: 0;
				}
				@media ${core.mediaquery.max['x-small']} {
					&:first-child {
						padding-top: 0;
					}
				}
			`}
            ${withoutBottomSpacing &&
			css`
				&:last-child ${TextColumn} {
					margin-bottom: 0;
				}
				@media ${core.mediaquery.max['x-small']} {
					&:last-child {
						padding-bottom: 0;
					}
				}
			`}
		}
	`}
`

export type ColumnData = {
	label: string
	value?: string
	action?: JSX.Element
	badge?: JSX.Element
	key?: string
}

type DataColumnsProps = {
	data: (ColumnData | null)[]
	withVerticalRowSpacing?: boolean
	withRowGap?: boolean
	textColumnAsColumn?: boolean
	rowCss?: CSSProp
	contentColumnCss?: CSSProp
	textColumnCss?: CSSProp
	withRowDivider?: boolean
	withoutTopSpacing?: boolean
	withoutBottomSpacing?: boolean
	withoutTopDivider?: boolean
	withoutBottomDivider?: boolean
}

const DataColumns = ({
	data,
	withVerticalRowSpacing,
	withRowGap,
	textColumnAsColumn,
	rowCss,
	contentColumnCss,
	textColumnCss,
	withRowDivider,
	withoutTopSpacing,
	withoutBottomSpacing,
	withoutTopDivider,
	withoutBottomDivider,
	...rest
}: DataColumnsProps) => {
	return (
		<Wrapper
			withRowGap={withRowGap}
			withRowDivider={withRowDivider}
			withoutTopSpacing={withoutTopSpacing}
			withoutBottomSpacing={withoutBottomSpacing}
			withoutTopDivider={withoutTopDivider}
			withoutBottomDivider={withoutBottomDivider}
			{...rest}
		>
			{data.filter(Boolean).map((d) => (
				<Row
					key={d?.key || d?.label}
					withVerticalRowSpacing={withVerticalRowSpacing}
					css={rowCss}
				>
					<ContentColumn css={contentColumnCss}>
						<TextColumn
							withVerticalRowSpacing={withVerticalRowSpacing}
							css={textColumnCss}
							asColumn={textColumnAsColumn}
							hasBadge={!!d?.badge}
						>
							<Label>{d?.label}</Label>
							<WordBreakedSpan>{d?.value}</WordBreakedSpan>
						</TextColumn>
						{d?.badge}
					</ContentColumn>
					{d?.action}
				</Row>
			))}
		</Wrapper>
	)
}

export default memo(DataColumns)
