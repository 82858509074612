import React from 'react'
import { useParams } from 'react-router-dom'
import { activeFamilyUsers } from '../../utils/subscriptionUtils'
import useSubscription from '../../api/subscriptions/useSubscription'
import copyTexts from '../../assets/copyTexts'
import CancellationCard, { Email } from './CancellationCard'

const CancellationFamilyReminder = () => {
	const { id } = useParams()
	const { data: subscriptionData } = useSubscription(id)
	const familyUsers = activeFamilyUsers(subscriptionData)

	return (
		<CancellationCard
			titleText={
				familyUsers.length === 1
					? copyTexts.cancelFlow.familyCard[
							'tilauksesi päättyy myös tältä henkilöltä:'
					  ]
					: copyTexts.cancelFlow.familyCard[
							'tilauksesi päättyy myös näiltä henkilöiltä:'
					  ]
			}
			bulletSection={
				<>
					{familyUsers.map((familyUser) => (
						<Email key={`${familyUser.id || familyUser.orderId}-email`}>
							{familyUser.emailAddress}
						</Email>
					))}
				</>
			}
			bodyText={
				copyTexts.cancelFlow.familyCard['valitse "Pysy nykyisessä tilauksessa']
			}
		/>
	)
}

export default CancellationFamilyReminder
