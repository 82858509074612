import {
	core,
	Link as DsLink,
	Heading,
} from '@almamedia/talent-media-component-library'
import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
import styled from 'styled-components'
import useUser from '../../api/user/useUser'
import copyTexts from '../../assets/copyTexts'
import { Email } from '../common'
import TopBreadCrumbLink from '../common/TopBreadCrumbLink'
import GALinkEvent from '../common/GALinkEvent'
import { useApplicationContext } from '../../App'

const Wrapper = styled.div`
	max-width: ${core.size.template['page-wrapper']};
	padding: ${core.spacing['x-large']} ${core.spacing.large}
		${core.spacing['xxxx-large']};
	margin: 0 auto;

	@media ${core.mediaquery.max.small} {
		max-width: ${core.size.template['page-wrapper-mobile']};
	}

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing.large} 0 ${core.spacing['xx-large']};
	}
`

const HeadingSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media ${core.mediaquery.max['x-small']} {
		padding: 0 ${core.spacing.medium};
	}
`

const StyledHeadline = styled(Heading.Subtitle)`
	margin-bottom: ${core.spacing.medium};
	text-align: center;

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing.small};
	}
`

const StyledEmail = styled(Email)`
	margin-bottom: ${core.spacing.large};
`

const Terms = styled(DsLink)`
	display: inline-block;
	margin-bottom: ${core.spacing['xxx-large']};

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing['x-large']};
	}
`

const Navigation = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: center;
	gap: ${core.spacing.small};
	border-bottom: ${core.border['x-small']} solid ${core.color.primary[10]};
	margin-bottom: ${core.spacing['xx-large']};

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing['x-large']};
	}
`
const NavigationItem = styled.li`
	@media ${core.mediaquery.max['x-small']} {
		flex: 1;
		display: flex;
		> a {
			flex: 1;
			justify-content: center;
		}
	}

	> a {
		color: ${core.color.primary[10]};
		${core.type.alma.ui.medium};
		text-decoration: none;
		font-size: ${core.font.scale[3]};
		height: ${core.size.target['xx-large']};
		display: inline-flex;
		align-items: center;
		padding: 0 ${core.spacing['x-small']};
		border-bottom: ${core.border.medium} solid transparent;
		text-align: center;

		@media ${core.mediaquery.max['x-small']} {
			font-size: ${core.font.scale[1]};
			height: ${core.size.target.medium};
		}

		&.active {
			border-bottom-color: ${core.color.primary[10]};
		}
	}
`

const NavigationLink = styled(NavLink)`
	:focus-visible {
		outline: ${core.outline.small} solid ${core.color.primary[10]};
		border-radius: ${core.shape.rounded};
	}
`

const SubscriptionsLayout = () => {
	const { data: user } = useUser()

	return (
		<Wrapper>
			<TopBreadCrumbLink />
			<HeadingSection>
				{user && (
					<>
						<StyledHeadline size='medium, @min.small=x-large' level='h2'>
							{user.firstname}, tässä näet omat tilauksesi
						</StyledHeadline>
						<StyledEmail>{user.email}</StyledEmail>
					</>
				)}
				<GALinkEvent>
					<Terms
						href={copyTexts['Käyttö- ja sopimusehdot'].url}
						borderType='light'
						borderColor='core.color.primary.10'
					>
						{copyTexts['Käyttö- ja sopimusehdot'].text}
					</Terms>
				</GALinkEvent>
			</HeadingSection>
			<Navigation>
				<NavigationItem>
					<GALinkEvent>
						<NavigationLink to='voimassa-olevat-tilaukset'>
							{copyTexts.subscription['Voimassa olevat']}
						</NavigationLink>
					</GALinkEvent>
				</NavigationItem>
				<NavigationItem>
					<GALinkEvent>
						<NavigationLink to='paattyneet-tilaukset'>
							{copyTexts.subscription['Päättyneet']}
						</NavigationLink>
					</GALinkEvent>
				</NavigationItem>
			</Navigation>
			<Outlet context={useApplicationContext()} />
		</Wrapper>
	)
}

export default SubscriptionsLayout
