const setItem = (key: string, value: string) => {
	try {
		return (
			typeof sessionStorage !== 'undefined' &&
			sessionStorage.setItem(key, value)
		)
	} catch (_) {
		return null
	}
}
const getItem = (key: string) => {
	try {
		return typeof sessionStorage !== 'undefined'
			? sessionStorage.getItem(key)
			: undefined
	} catch (_) {
		return null
	}
}

const removeItem = (key: string) => {
	try {
		return (
			typeof sessionStorage !== 'undefined' && sessionStorage.removeItem(key)
		)
	} catch (_) {
		return null
	}
}

export default {
	/**
	 * Sets, gets and removes a flag that decides whether the tunnus login should be prompted from the
	 * frontpage. Used when automated login prompt is used when user is a group user, or user is trying to
	 * access a restricted route.
	 */
	setShowTunnusLoginPrompt: (value: string) =>
		setItem('showTunnusLoginPrompt', value),
	getShowTunnusLoginPrompt: () => getItem('showTunnusLoginPrompt'),
	removeShowTunnusLoginPrompt: () => removeItem('showTunnusLoginPrompt'),
	/**
	 * Sets, gets and removes a flag that indicated whether the `showTunnusLoginPrompt` key should be removed
	 * from the session storage. This is set just before the login is triggered. The appropriate places uses
	 * this flag to remove the aforementioned key, so that the user does not get stuck in an endless login
	 * loop.
	 */
	setPrepareToRemoveLoginPromptSS: (value: string) =>
		setItem('prepareToRemoveLoginPromptSS', value),
	getPrepareToRemoveLoginPromptSS: () =>
		getItem('prepareToRemoveLoginPromptSS'),
	removePrepareToRemoveLoginPromptSS: () =>
		removeItem('prepareToRemoveLoginPromptSS'),
	/**
	 * Sets, gets and removes the return url after successfully logging in through tunnus.
	 */
	setTunnusReturnUrl: (value: string) => setItem('tunnusReturnUrl', value),
	getTunnusReturnUrl: () => getItem('tunnusReturnUrl'),
	removeTunnusReturnUrl: () => removeItem('tunnusReturnUrl'),
	/**
	 * Sets, gets and removes the info needed for verifying whether the card update happened
	 * successfully.
	 */
	setCardUpdateInfo: (value: string) => setItem('cardUpdateInfo', value),
	getCardUpdateInfo: () => getItem('cardUpdateInfo'),
	removeCardUpdateInfo: () => removeItem('cardUpdateInfo'),
	/**
	 * Sets, gets and removes the redirect url, if any. Redirect urls are set when the user needs to be redirected at different
	 * pre-determined pages due to certain conditions. For example, if the user is not logged in, they are always redirected
	 * to the front page regardless of the route they want to visit. Similarly the user is redirected to the Group user error page
	 * if they are logged in with a group account. These values are used to determine when to send the digital data without sending
	 * duplicates or intermediate route data.
	 */
	setRedirectUrl: (value: string) => setItem('redirectUrl', value),
	getRedirectUrl: () => getItem('redirectUrl'),
	removeRedirectUrl: () => removeItem('redirectUrl'),
}
