import React from 'react'

const ILPlusLogo = () => (
	<svg
		viewBox='0 0 79 33'
		fill='none'
		style={{ display: 'inline', verticalAlign: 'bottom', height: '1rem' }}
	>
		<path className='st1' fill='#dc0000' d='M0 0H79V32.6304H0V0Z'></path>
		<path
			className='st0'
			d='M7.14453 7.03442V25.7609H12.3242V20.5812H13.4199C14.914 20.5812 17.0058 20.5812 18.8486 19.5851C20.7909 18.5392 22.1854 16.6467 22.1854 13.7331C22.1854 11.2678 21.1894 9.27562 19.1474 8.13012C17.205 7.03442 14.914 7.03442 13.4697 7.03442H7.14453ZM12.3242 10.8196H13.4199C14.0673 10.8196 14.9638 10.8196 15.6611 11.2678C16.3583 11.716 16.8066 12.6125 16.8066 13.8078C16.8066 15.0031 16.3583 15.8996 15.6611 16.3478C14.9638 16.7961 14.0673 16.7961 13.4199 16.7961H12.3242V10.8196Z'
			fill='white'
		></path>
		<path
			className='st0'
			d='M25.4136 7.03442V25.7609H37.2172V21.7765H30.5932V7.03442H25.4136Z'
			fill='white'
		></path>
		<path
			className='st0'
			d='M39.6214 7.03442V18.091C39.6214 19.7345 39.6214 21.8263 41.1156 23.6691C42.4852 25.3624 44.5272 26.1593 47.5154 26.1593C50.2547 26.1593 52.4959 25.4869 53.9651 23.6691C55.4592 21.8263 55.4592 19.6847 55.4592 18.091V7.03442H50.9768V18.8879C50.9768 20.7804 50.4788 22.3742 47.889 22.3742C45.2991 22.3742 44.8011 20.7804 44.8011 18.8879V7.03442H39.6214Z'
			fill='white'
		></path>
		<path
			className='st0'
			d='M71.5933 7.58227C71.5933 7.58227 69.4019 6.83521 66.5631 6.83521C61.8815 6.83521 59.0426 8.82738 59.0426 12.4133C59.0426 18.4147 67.0611 17.8918 67.0611 20.8302C67.0611 21.8263 66.1646 22.3742 64.6207 22.3742C62.4293 22.3742 59.3414 21.2785 59.3414 21.2785H59.1422V25.1134C59.1422 25.1134 61.3336 25.9601 64.546 25.9601C69.6011 25.9601 72.4898 23.8683 72.4898 20.0832C72.4898 13.758 64.4713 14.5051 64.4713 11.7658C64.4713 10.969 65.1187 10.4211 66.6627 10.4211C68.7047 10.4211 71.3941 11.3674 71.3941 11.3674H71.5933V7.58227Z'
			fill='white'
		></path>
	</svg>
)

export default ILPlusLogo
