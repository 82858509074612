import React from 'react'
import styled from 'styled-components'
import {
	core,
	spacer,
	Card,
	Icon,
	Heading,
	PrimaryButton,
} from '@almamedia/talent-media-component-library'
import { useCancellationContext } from './CancellationLayout'
import { formatDate, getEndDate } from '../../utils/subscriptionUtils'
import copyTexts from '../../assets/copyTexts'
import GALinkEvent from '../common/GALinkEvent'
import { StyledLink } from '../common'

const Wrapper = styled(Card)`
	max-width: 40.625rem;
	margin: 0 auto;
	padding: ${core.spacing['xxxx-large']} ${core.spacing['x-large']} 0
		${core.spacing['x-large']};

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing['xx-large']} ${core.spacing.medium} 0
			${core.spacing.medium};
	}
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${core.color.brand.grey[95]};
	padding: ${core.spacing['xxx-large']} 0;

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing['xx-large']} ${core.spacing.large};
	}

	> * {
		max-width: 28rem;
	}
`

const Text = styled.p`
	${core.type.alma.ui.normal};
	margin: 0;
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[1]};
	line-height: ${core['line-height'].medium};
	text-align: center;
`

const StyledButton = styled(PrimaryButton)`
	width: 100%;
`

const CancellationConfirmation = () => {
	const { cancelled, endDate, mediaSlugs } = useCancellationContext()

	if (cancelled && !endDate) return null
	const date = cancelled ? getEndDate(endDate || '') : formatDate(endDate || '')

	return (
		<Wrapper>
			<ContentWrapper>
				<Icon.CoreCheckCircle
					size='core.size.icon.x-large'
					color='core.alma.color.brand.purple'
					className={spacer('bottom', 'medium')}
				/>
				<Heading.Subtitle size='medium' className={spacer('bottom', 'x-small')}>
					{cancelled
						? copyTexts.cancelFlow.confirmation['Tilauksesi on päättynyt']
						: copyTexts.cancelFlow.confirmation['Kiitos tilauksesta!']}
				</Heading.Subtitle>
				<Text className={spacer('bottom', 'x-large')}>
					{cancelled
						? `Tilauksesi on voimassa ${date} asti. Toivottavasti näemme sinut pian taas uudelleen!`
						: `Uusi tilauksesi alkaa ${date}. Voit siis jatkaa ${mediaSlugs.siteCase} lukemista tuttuun tapaan.`}
				</Text>
				<GALinkEvent>
					<StyledLink to='/voimassa-olevat-tilaukset'>
						<StyledButton forwardedAs='div'>
							{
								copyTexts.cancelFlow.confirmation[
									'Siirry Omat tilaukset -sivulle'
								]
							}
						</StyledButton>
					</StyledLink>
				</GALinkEvent>
			</ContentWrapper>
		</Wrapper>
	)
}

export default CancellationConfirmation
