import React from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import {
	core,
	Heading,
	FlexGrid,
	Divider,
	Icon,
} from '@almamedia/talent-media-component-library'
import SmallTeaser from '../common/SmallTeaser'
import { useCancellationContext } from './CancellationLayout'
import { getMediaToken } from '../../utils/mediaUtils'
import copyTexts from '../../assets/copyTexts'
import useGetRecommendationArticles from '../../api/recommendations/useGetRecommendationArticles'
import OrganizationLogo from '../common/OrganizationLogo'
import useProductDetails from '../../api/products/useProductDetails'
import useSubscription from '../../api/subscriptions/useSubscription'
import { useParams } from 'react-router-dom'
import { ArticleTeaser, FeedListTeaserData } from '../../types/teaser'

const Wrapper = styled.main`
	max-width: ${core.size.template['page-wrapper']};
	margin: 0 auto;

	@media ${core.mediaquery.max.small} {
		max-width: 40.625rem;
	}
`

const TeaserWrapper = styled.div`
	background: ${core.color.brand.grey[95]};
	padding: ${core.spacing['xx-large']};
	margin: 0 ${core.spacing['x-large']};

	@media ${core.mediaquery.max.small} {
		padding: ${core.spacing['x-large']} ${core.spacing.large}
			${core.spacing['xx-large']} ${core.spacing.large};
	}

	@media ${core.mediaquery.max['x-small']} {
		padding: ${core.spacing['x-large']} ${core.spacing.medium}
			${core.spacing['xx-large']} ${core.spacing.medium};
		margin: 0;
	}
`

const LogoContainer = styled.div`
	margin-bottom: ${core.spacing.large};

	svg {
		padding: ${core.spacing['xxx-small']} 0;
	}

	@media ${core.mediaquery.max['x-small']} {
		margin-bottom: ${core.spacing.medium};

		svg {
			padding: ${core.spacing['xx-small']} 0;
		}
	}
`

const StyledHeading = styled(Heading.Subtitle)`
	text-align: center;
	margin: 0 ${core.spacing.large};
	padding-bottom: ${core.spacing['x-large']};

	@media ${core.mediaquery.max.small} {
		padding-bottom: ${core.spacing.large};
	}

	@media ${core.mediaquery.max['x-small']} {
		margin: 0 ${core.spacing.medium};
	}
`

const StyledDivider = styled(Divider)`
	@media ${core.mediaquery.min.medium} {
		display: none;
	}
`

const IncludedWrapper = styled.div`
	max-width: 28rem;
	margin-left: auto;
	margin-right: auto;
	margin-top: ${core.spacing['xx-large']};

	@media ${core.mediaquery.max['x-small']} {
		margin-top: ${core.spacing['x-large']};
	}
`

const DataColumns = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: ${core.spacing.medium};

	@media ${core.mediaquery.max['x-small']} {
		grid-template-columns: repeat(12, auto);
		justify-content: center;
	}
`

const Data = styled.div`
	display: flex;
	gap: ${core.spacing['x-small']};

	@media ${core.mediaquery.max['x-small']} {
		grid-column: 1 / -1;
		justify-content: start;
	}
`

const DataName = styled.span`
	${core.type.alma.ui.medium};
	color: ${core.color.primary[10]};
	font-size: ${core.font.scale[0]};
	line-height: ${core['line-height'].medium};
`

const ILFonts = styled.div<{ isIL: boolean }>`
	${(isIL) =>
		isIL &&
		css`
			@font-face {
				font-family: 'Bernino Sans Narrow';
				font-weight: 600;
				src: url('https://assets.ilcdn.fi/BerninoSans-Web-NarrowSemibold.woff2')
						format('woff2'),
					url('https://assets.ilcdn.fi/BerninoSans-Web-NarrowSemibold.woff')
						format('woff');
				font-display: fallback;
			}
			& * {
				font-family: 'Bernino Sans Narrow';
				font-weight: 600;
				text-transform: none;
			}
		`}
`

const Benefits = () => {
	const { id } = useParams()
	const { data: subscriptions } = useSubscription(id)
	const { data, isLoading } = useProductDetails(
		subscriptions?.subscription.productInstances[0].code
	)

	if (isLoading) {
		return null
	}

	const bulletPoints = data?.properties?.productContentDescriptionExtra

	if (bulletPoints && bulletPoints.length > 0) {
		return (
			<IncludedWrapper>
				<StyledHeading size='medium'>
					{copyTexts.cancelFlow.benefits['Nykyiseen tilaukseesi kuuluu']}
				</StyledHeading>
				<DataColumns>
					{bulletPoints.map((point) => (
						<Data key={point}>
							<Icon.CoreCheck
								color='alma.color.brand.purple'
								size='core.size.icon.large'
								css={{ minWidth: core.size.icon.large }}
							/>
							<DataName>{point}</DataName>
						</Data>
					))}
				</DataColumns>
			</IncludedWrapper>
		)
	}
	return null
}

const CancellationBenefits = () => {
	const { mediaSlugs, organization, isSijoittaPakettiSubscription } =
		useCancellationContext()
	const { data, isLoading } = useGetRecommendationArticles(
		mediaSlugs.siteShortSlug
	)

	if (isLoading) return null
	return (
		<Wrapper>
			{data && !isSijoittaPakettiSubscription && (
				<TeaserWrapper>
					<LogoContainer>
						<OrganizationLogo
							organization={organization}
							useMainLogo
							size='core.size.icon.xx-large'
						/>
					</LogoContainer>
					<StyledHeading size='medium'>
						{mediaSlugs.siteShortSlug === 'il'
							? copyTexts.cancelFlow.benefits['Plus-jutut vain tilaajille']
							: copyTexts.cancelFlow.benefits['Tähtijutut vain tilaajille']}
					</StyledHeading>
					<ThemeProvider
						theme={(theme: { foundation: { foundation: Object } }) => ({
							...theme,
							foundation: {
								...theme.foundation,
								media: getMediaToken(mediaSlugs.siteShortSlug),
							},
						})}
					>
						<ILFonts isIL={mediaSlugs.siteShortSlug === 'il'}>
							<FlexGrid>
								{data.map((item) => (
									<React.Fragment
										key={
											(item as ArticleTeaser).uuid ||
											(item as FeedListTeaserData).id
										}
									>
										<SmallTeaser
											teaser={item}
											siteName={mediaSlugs.siteNameSlug}
										/>
										<StyledDivider
											color='core.color.primary.60'
											thickness='core.size.line.thin'
											spacing={{
												sides: ['top', 'bottom'],
												size: 'core.spacing.large',
											}}
										/>
									</React.Fragment>
								))}
							</FlexGrid>
						</ILFonts>
					</ThemeProvider>
				</TeaserWrapper>
			)}
			<Benefits />
		</Wrapper>
	)
}

export default CancellationBenefits
