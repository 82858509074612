import React, { useState } from 'react'
import { Outlet, useOutletContext, useSearchParams } from 'react-router-dom'
import AlmaLogin from './components/login/AlmaLogin'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import {
	core,
	TokensFromDS,
	fontfaceCore,
	alma,
	fontfaceKL,
	fontfaceAP,
	fontfaceTE,
	fontfaceTT,
	fontfaceMB,
	fontfaceMU,
	fontfaceTV,
	fontfaceUS,
} from '@almamedia/talent-media-component-library'
import { SWRConfig } from 'swr'

import Header from './components/header/Header'
import AlmaFooter from './components/footer/AlmaFooter'
import ToastNotification from './components/notification/ToastNotification'
import { isUserLoggedIn } from './utils/userUtils'
import useUser from './api/user/useUser'
import DigitalDataSender from './components/DigitalDataSender'
import SelfServiceFooter from './components/footer/SelfServiceFooter'
import LocationChangeSideEffects from './utils/LocationChangeSideEffects'
import LoadingOverlay from './components/common/LoadingOverlay'
import { requestCounter } from './api/middleware'
import PopupNotification from './components/notification/PopupNotification'
import Selligent from './components/selligent/Selligent'
import Consent from './components/consent/Consent'

const GlobalStyle = createGlobalStyle`
    #root {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    ${fontfaceCore}
	${fontfaceKL}
	${fontfaceAP}
	${fontfaceTE}
	${fontfaceTT}
	${fontfaceMB}
	${fontfaceMU}
	${fontfaceTV}
	${fontfaceUS}
    body {
        margin: 0;
        padding: 0;
    }

    .alma-footer {
      background: none !important;
      margin: 0 auto !important;
      max-width: ${core.size.template['page-wrapper']};
    }

	.alma-footer {
    	background: none !important;
    	margin: 0 auto !important;
    	max-width: ${core.size.template['page-wrapper']};
  	}

    * {
        box-sizing: border-box;
    }
`

const Content = styled.div<{ $loading: boolean }>`
	flex-grow: 1;
	position: relative;
	${({ $loading }) => $loading && 'min-height: calc(100vh - 4.887rem)'};
`

type ContextType = {
	isILAndroidApp: boolean
}

export function useApplicationContext() {
	return useOutletContext<ContextType>()
}

const App = () => {
	const [isILAndroidApp, setIsILAndroidApp] = useState(false)
	const [requestCount, setRequestCount] = useState(0)
	const { data: user } = useUser()

	const [searchParams] = useSearchParams()
	const origin = searchParams.get('origin')
	if (origin === 'il-android' && !isILAndroidApp) {
		setIsILAndroidApp(true)
	}

	const loggedIn = isUserLoggedIn(user)
	const isLoading = requestCount > 0

	return (
		<SWRConfig
			value={{
				revalidateIfStale: false,
				revalidateOnFocus: false,
				revalidateOnReconnect: false,
				shouldRetryOnError: false,
				use: [requestCounter(setRequestCount)],
			}}
		>
			<ThemeProvider
				theme={{
					foundation: {
						core,
						media: alma,
					},
				}}
			>
				<TokensFromDS />
				<GlobalStyle />
				<LocationChangeSideEffects />
				<LoadingOverlay loading={isLoading} />
				<Header />
				<Content $loading={isLoading}>
					{loggedIn && <ToastNotification />}
					<Outlet
						context={{
							isILAndroidApp: isILAndroidApp,
						}}
					/>
				</Content>
				<SelfServiceFooter />
				<AlmaFooter />
				<AlmaLogin />
				<DigitalDataSender />
				<PopupNotification />
				<Consent />
				<Selligent />
			</ThemeProvider>
		</SWRConfig>
	)
}

export default App
