import useSWR from 'swr'
import { parseResponse } from '..'
import URLS from '../urls'
import { IltalehtiArticleTeaser } from '../../types/teaser'
import config from 'config'

const fetcher = async (url: string) =>
	fetch(url)
		.then((response) => parseResponse(response))
		.then((res) =>
			res.response.map((item: IltalehtiArticleTeaser) => ({
				uuid: item.article_id,
				leadImageUuid: item.main_image_urls?.size612,
				title: item.title,
				titleSlug: 'a',
				primaryCategory: {
					name: item.category.description,
				},
			}))
		)

export default (limit = 4) => {
	return useSWR(
		`${config.ilApiDomain}${URLS.IL_PLUS_MOST_READ_ARTICLES}?limit=${limit}&functional_keywords[]=plus_article`,
		fetcher
	)
}
