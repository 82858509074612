import * as React from 'react'

type Props = {
	children: React.ReactNode
	fallback?: React.ReactNode
}

type State = {
	hasError?: boolean
}

export default class ErrorBoundary extends React.PureComponent<Props, State> {
	state: State = {}

	componentDidCatch(error: Error, info: any) {
		this.setState({ hasError: true })
		console.error('[ErrorBoundary]', error, info)
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.children !== prevProps.children && this.state.hasError) {
			this.setState({ hasError: false })
		}
	}

	render() {
		return this.state.hasError
			? this.props.fallback || null
			: this.props.children
	}
}
