import useSWR from 'swr'
import { parseResponse } from '..'
import { ApiListData, FeedListData } from '../../types/teaser'
import config from 'config'

const { getTalentFeedsDomain } = config

const fetcher = (url: string) => fetch(url).then((res) => parseResponse(res))

export default (mediaSlug: string) => {
	if (mediaSlug !== 'il') {
		// Iltalehti doesn't have endpoints where these articles could be fetched from so we use KL feed API instead.
		const url = getTalentFeedsDomain(mediaSlug)

		return useSWR<ApiListData | FeedListData>(url, fetcher)
	}
}
