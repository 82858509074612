import { Subscription } from '../../types/subscription'
import { UserState } from '../../types/user'
import {
	hasSubscriptionEnded,
	isFreeSubscription,
	isSubscriptionEndingSoon,
	isSubscriptionRecurring,
	isSubscriptionStartingInFuture,
	isUnionSubscription,
} from '../../utils/subscriptionUtils'
import useCustomerSubscription from './useCustomerSubscription'

/**
 * Sorting order:
 *  1. Active subscriptions
 *  2. Future subscriptions
 *  3. Ending subscriptions
 */
const convertStatusToComparisonTable = (s: Subscription) => [
	true,
	isSubscriptionStartingInFuture(s),
	isSubscriptionEndingSoon(s),
]
const sortByStatus = (a: Subscription, b: Subscription) => {
	const aIndex = convertStatusToComparisonTable(a).lastIndexOf(true)
	const bIndex = convertStatusToComparisonTable(b).lastIndexOf(true)

	if (aIndex > bIndex) return 1
	if (aIndex === bIndex) return 0
	return -1
}

/**
 * Sorting order:
 *  1. Recurring subscriptions
 *  2. Fixed term subscriptions
 *  3. Free trial subscriptions
 *  4. Union subscriptions
 *  5. Corporate subscriptions
 */
const convertTypeToComparisonTable = (s: Subscription, u?: UserState) => [
	isSubscriptionRecurring(s),
	!isSubscriptionRecurring(s),
	isFreeSubscription(s),
	isUnionSubscription(s),
	u?.accountType === 'corporate',
]
const sortByType = (a: Subscription, b: Subscription, user?: UserState) => {
	const aIndex = convertTypeToComparisonTable(a, user).lastIndexOf(true)
	const bIndex = convertTypeToComparisonTable(b, user).lastIndexOf(true)

	if (aIndex > bIndex) return 1
	if (aIndex === bIndex) return 0
	return -1
}

export default (user?: UserState, sortSubscriptions?: boolean) => {
	const { data, isLoading, error } = useCustomerSubscription()
	const activeSubscriptions = data?.filter(
		(d: Subscription) => !hasSubscriptionEnded(d)
	)

	const sortedSubscriptions =
		sortSubscriptions && activeSubscriptions
			? activeSubscriptions
					.sort((a, b) => sortByType(a, b, user))
					.sort((a, b) => sortByStatus(a, b))
			: activeSubscriptions
	return { data: sortedSubscriptions, isLoading, error }
}
