import { useEffect, useRef } from 'react'
import { mutate } from 'swr'
import useUser from '../../api/user/useUser'
import { isKLWGroupUser, isUserLoggedIn } from '../../utils/userUtils'
import {
	getCurrentActiveExposedFields,
	getCurrentActiveOffers,
	sendChangeEventSelligent,
} from '../../utils/selligentUtils'
import { insertFetchedHtml } from '../../utils/DomUtils'
import { useLocation } from 'react-router-dom'
import useConsent from '../../api/consent/useConsent'
import { isSelligentEnabled } from '../../utils/consentUtils'
import GLOBALSTORAGE from '../../api/globalstorage'

const Selligent = () => {
	const { pathname } = useLocation()
	const { data: user } = useUser()
	const { data: consent } = useConsent()
	const hasContentRendered = useRef(false)
	const prevPath = useRef(pathname)

	const selligentIsEnabled =
		isUserLoggedIn(user) &&
		!isKLWGroupUser(user) &&
		isSelligentEnabled(consent) &&
		Boolean(window.digitalData?.user)

	useEffect(() => {
		if (selligentIsEnabled && prevPath.current !== pathname) {
			sendChangeEventSelligent()
			prevPath.current = pathname
		}
	}, [selligentIsEnabled, pathname])

	useEffect(() => {
		if (selligentIsEnabled) {
			const waitForSelligent = (maxRetries = 5) => {
				if (!window.BT?.selligentData && maxRetries === 0) {
					console.error('Something went wrong while loading the script!')
					return
				}

				try {
					if (window?.BT?.selligentData) {
						mutate(GLOBALSTORAGE.SELLIGENT, {
							...window.BT?.selligentData,
							exposedFields: getCurrentActiveExposedFields(),
							offers: getCurrentActiveOffers(),
						})
					} else {
						setTimeout(waitForSelligent, 500, maxRetries--)
					}
				} catch (error) {
					console.error(error)
				}
			}

			if (isSelligentEnabled(consent)) {
				waitForSelligent()
			}
		}
	}, [selligentIsEnabled, consent])

	if (selligentIsEnabled) {
		const scriptTag = `
            <script>
                try {
                    var wa = document.createElement("script");
                    wa.src = "https://targetemsecure.blob.core.windows.net/10ca1cfd-e73d-42c7-b8ee-da03a3355c3f/10ca1cfde73d42c7b8eeda03a3355c3f_1.js";
                    wa.type = "text/javascript";
                    document.head.appendChild(wa);
                    wa.bt_queue = [];
                    wa.afterInit = function()
                        {
                            wa.bt_queue.push({
                                customIdentifier: window.digitalData.user[0].profile[0].profileInfo.almaIDHash,
                                isEvent: false,
                                isTargeting: true,
                                async: true,
                                finishedCallback: (data) => {
                                    window.BT.selligentData = data;
                                },
                                tagValues: [],
                                exposedFields: [],
                            });
                        }
                    window.wa = wa;
                    window.selligent = wa;
                } catch (error) {
                    console.log(error)
                }
            </script>
        `
		insertFetchedHtml(scriptTag, hasContentRendered)
	}

	return null
}

export default Selligent
